import { Dialog, Card, FormControl } from "@mui/material"

import ProgressBar from "./ProgressBar"

import "../styles/modal.scss"
import "../styles/widget.scss"
import "../styles/certificate.scss"

import { SettingSwitch } from "@mobilemind/common/src/components/SettingSwitch"
import { updateCertificateSettings } from "../features/createLearningPath/activeLearningPathSlice"
import { useAppDispatch } from "store/hooks"
import moment from "moment"
import { LearningPathCertificatePreview } from "@mobilemind/common/src/components/LearningPathCertificatePreview"

function ModalLearningPathPreview(props) {
  const {
    session,
    onClose,
    open,
    categories,
    pathType,
    learningPath,
    type,
    user,
    partnerImage,
  } = props

  let imageSrc
  if (learningPath.image) {
    if (learningPath.image.attributes) {
      imageSrc =
        process.env.REACT_APP_API_URL + learningPath.image.attributes.uri.url
    } else {
      imageSrc = learningPath.image
    }
  }

  let pathCategories = [
    ...new Set(
      learningPath.courses.map((course) =>
        categories.data.find(
          (cat) =>
            course.relationships.field_category.data &&
            cat.id === course.relationships.field_category.data.id
        )
      )
    ),
  ]

  let hasLogo = session.group.field_org_logo && session.group.field_org_logo[0]

  if (session.isPartner) {
    hasLogo = partnerImage
  }

  const dispatch = useAppDispatch()
  if (type === "certificate") {
    return (
      <Dialog
        className="modal certificate-modal learning-path-certificate"
        onClose={() => onClose()}
        open={open}
      >
        <p>
          Here's what this Learning Path's certificate will look like.
          Certificates are awarded to users who complete this Learning Path.
        </p>
        <LearningPathCertificatePreview
          completedDate={moment().format("MMMM D, YYYY")}
          pathName={learningPath.name}
          imageSrc={imageSrc}
          isPartner={session.isPartner}
          partnerImage={partnerImage}
          pathCategories={pathCategories
            .map((cat) => {
              if (cat) {
                return {
                  name: cat?.attributes.name,
                  imageSrc:
                    process.env.REACT_APP_API_URL +
                    cat?.image?.attributes.uri.url,
                  id: cat?.id,
                  timeSpent: 0,
                }
              }
              return null
            })
            .filter((cat) => cat)}
          teacherName={
            user.attributes.field_first_name +
            " " +
            user.attributes.field_last_name
          }
          estimatedTime={learningPath.estimatedTime}
          group={session.group}
          certificateSettings={learningPath.certificateSettings}
        />

        <FormControl sx={{ marginTop: 3 }}>
          <SettingSwitch
            checked={learningPath.certificateSettings.pdCredit}
            label="Show PD Credit"
            onChange={(checked) => {
              dispatch(
                updateCertificateSettings({
                  field: "pdCredit",
                  value: checked,
                })
              )
            }}
          />
          <SettingSwitch
            checked={learningPath.certificateSettings.categories}
            label="Show Categories"
            onChange={(checked) => {
              dispatch(
                updateCertificateSettings({
                  field: "categories",
                  value: checked,
                })
              )
            }}
          />

          <SettingSwitch
            checked={learningPath.certificateSettings.signature}
            label={"Show Signature"}
            helperText={
              <>
                Org Admins can set Signature Name, Title, and Style in
                <strong> Organization Settings.</strong>
              </>
            }
            onChange={(checked) => {
              dispatch(
                updateCertificateSettings({
                  field: "signature",
                  value: checked,
                })
              )
            }}
          />

          <SettingSwitch
            disabled={!hasLogo}
            checked={learningPath.certificateSettings.orgLogo}
            label={
              session.isPartner ? "Show Partner Logo" : "Show Organization Logo"
            }
            helperText={
              <>
                If turned on, {session.group.label[0].value}'s logo will replace
                the MobileMind logo.{" "}
                {!session.isPartner && (
                  <>
                    Org Admins can upload a logo in
                    <strong> Organization Settings.</strong>
                  </>
                )}
              </>
            }
            onChange={(checked) => {
              dispatch(
                updateCertificateSettings({
                  field: "orgLogo",
                  value: checked,
                })
              )
            }}
          />
        </FormControl>
      </Dialog>
    )
  }

  return (
    <Dialog
      className="modal learning-path-preview"
      onClose={() => onClose()}
      open={open}
    >
      <p>
        Here's what your Learning Path will look like in the{" "}
        <strong>Learn</strong> app:
      </p>

      <Card className="widget learningPath inProgress">
        <div className="topContent">
          <div className="widgetLabel">
            {pathType ? pathType.attributes.name : "Develop a Skill"}
          </div>
          <div
            className="pathImage"
            style={{ backgroundImage: 'url("' + imageSrc + '")' }}
          />
        </div>
        <div className="bottomContent">
          <h2>{learningPath.name}</h2>
          <p>{learningPath.body}</p>

          <div className="pathStatus">
            0 / {learningPath.courses.length} Courses Completed
            <ProgressBar width={0} />
          </div>
        </div>
      </Card>
    </Dialog>
  )
}

export default ModalLearningPathPreview
