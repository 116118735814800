import { Box, FormControl, TextField } from "@mui/material"

import "../marketplace/marketplace.scss"
import { Field, useFormikContext } from "formik"
import ButtonSmall from "components/ButtonSmall"
import SignatureFontSelectModal from "features/orgSettings/SignatureFontSelectionModal"

import classNames from "classnames"
import IconCertificate from "../../img/certificate-color.svg"
import { useState } from "react"

export const PartnerCertificatesForm = () => {
  const { values, setFieldValue } = useFormikContext()

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { field_signature_font, field_signature_title, field_signature_name } =
    values

  const [isSignatureFontModalOpen, setIsSignatureFontModalOpen] =
    useState(false)

  return (
    <>
      <FormControl margin="normal">
        <header style={styles.header}>
          <img
            src={IconCertificate}
            alt="Certificate"
            style={styles.headerIcon}
          />
          Certificate Signature
        </header>
        <p style={styles.helperText}>
          This signature can appear on <strong>Learning Path</strong>{" "}
          certificates.
        </p>

        <strong>Name</strong>
        <Box sx={{ marginBottom: 4, marginTop: 2 }}>
          <Field
            as={TextField}
            sx={{
              width: 350,
            }}
            name="field_signature_name"
          />
        </Box>

        <strong>Title</strong>
        <Box sx={{ marginBottom: 4, marginTop: 2 }}>
          <Field
            as={TextField}
            sx={{
              width: 350,
            }}
            name="field_signature_title"
          />
        </Box>

        <strong>Style</strong>

        <Box sx={{ alignItems: "center", display: "flex", marginTop: 2 }}>
          <div className="active-signature">
            <strong
              className={classNames(
                "signature-text",
                field_signature_font ?? "Just-Another-Hand"
              )}
            >
              {field_signature_name?.length > 0
                ? field_signature_name
                : "Signature Name"}
            </strong>
            <span
              style={{
                textTransform: "uppercase",
                fontSize: 10,
                display: "block",
                marginTop: 3,
              }}
            >
              {field_signature_title?.length > 0
                ? field_signature_title
                : "Signature Title"}
            </span>
          </div>

          <div style={{ marginLeft: 35 }}>
            <ButtonSmall
              onClick={() => {
                setIsSignatureFontModalOpen(true)
              }}
            >
              <span className="icon quill-pen" />
              Choose New Style
            </ButtonSmall>
          </div>
        </Box>
      </FormControl>

      <SignatureFontSelectModal
        field_signature_font={field_signature_font}
        field_signature_title={field_signature_title}
        field_signature_name={field_signature_name}
        open={isSignatureFontModalOpen}
        onClose={(font: string) => {
          setIsSignatureFontModalOpen(false)

          if (font && typeof font === "string") {
            setFieldValue("field_signature_font", font)
          }
        }}
      />
    </>
  )
}

const styles = {
  formRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: 20,
  },
  fieldLabelSet: {
    display: "flex",
    flexDirection: "column" as "column",
    fontSize: 14,
    flex: 1,
  },
  formColumn: {
    display: "flex",
    flexDirection: "column" as "column",
    marginBottom: 20,
  },
  helperText: {
    fontSize: 12,
    opacity: 0.6,
    maxWidth: 485,
  },
  contactField: {
    marginTop: 10,
    width: 400,
  },
  header: {
    fontSize: 18,
    fontWeight: "bold",
    display: "flex",
    marginTop: 10,
    alignItems: "center",
  },

  headerIcon: {
    width: 25,
    height: 25,
    marginRight: 10,
  },
}
