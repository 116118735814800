import { GenieRewriteAllowedField } from "@mobilemind/genie-api/src/types"
import { Container, styled, useMediaQuery, useTheme } from "@mui/material"
import { useAsyncEffect } from "@react-hook/async"
import usePrevious from "@react-hook/previous"
import { createNextState, current } from "@reduxjs/toolkit"
import { useFormikContext } from "formik"
import { FocusEvent, HTMLAttributes, useEffect, useRef } from "react"
import { useAppDispatch, useAppSelector } from "store/hooks"

import {
  selectGenieWishesRemaining,
  selectHasGenieAccess,
  selectHasGenieWishAccess,
} from "store/selectors"

import {
  PopperType,
  activateCourseGenie,
  generateCourse,
  resetCourseGenie,
  resumeCourse,
  selectCourseGenieActive,
  selectCourseGeniePopper,
  showCourseGenieError,
  showPopper,
} from "../store/courseGenieSlice"

import { CourseFormValues, GenieElementProps } from "../types"
import { getYouTubeVideo } from "../utility"
import { GenieContext } from "./GenieContext"
import { GenieLampButton } from "./GenieLampButton"
import { GenieLoading } from "./GenieLoading"
import { GENIE_POPPER_WIDTH, GeniePopper } from "./GeniePopper"
import { GeniePopperFieldContent } from "./GeniePopperFieldContent"
import { isUndefined } from "lodash"

export const GenieProvider = styled((props: HTMLAttributes<HTMLDivElement>) => {
  const { children, ...otherProps } = props
  const { values, setValues } = useFormikContext<CourseFormValues>()

  const dispatch = useAppDispatch()

  const hasGenieAccess = useAppSelector(selectHasGenieAccess)
  const hasGenieWishAccess = useAppSelector(selectHasGenieWishAccess)
  const genieWishesRemaining = useAppSelector(selectGenieWishesRemaining)

  // Genie is enabled if org has it enabled or org is in trial period or this course
  // was previously created with genie
  const genieEnabled =
    hasGenieAccess ||
    (hasGenieWishAccess &&
      (genieWishesRemaining > 0 || values.field_created_with_genie))

  const genieActive = useAppSelector(selectCourseGenieActive)
  const popper = useAppSelector(selectCourseGeniePopper)

  // Fetch the video item and generate/resume course when the
  const { value: videoItem = null } = useAsyncEffect(async () => {
    if (!genieEnabled) {
      dispatch(resetCourseGenie())
      return null
    }
    const video = await getYouTubeVideo(values.field_video_link)
    // If the YouTube video can't be fetched, disable the course genie.
    if (!video) {
      dispatch(resetCourseGenie())
      return null
    }
    // If this is the first invocation of the hook...
    if (isUndefined(prevVideoItem)) {
      // Generate or resume the course, depending on the form values.
      if (!values.id) {
        if (values.field_created_with_genie) {
          dispatch(activateCourseGenie())
          try {
            // Generate the course when the form is mounted
            const nextValues = await createNextState(values, async (draft) => {
              await dispatch(
                generateCourse({
                  video,
                  draft,
                  commit: (draft) => setValues(current(draft)),
                })
              ).unwrap()
            })
            setValues(nextValues)

            // Show the helper popper when generate course is complete
            dispatch(showPopper({ type: PopperType.Helper }))
          } catch (err) {
            dispatch(showCourseGenieError())
            dispatch(resetCourseGenie())
          }
        }
      } else {
        if (values.field_created_with_genie) {
          dispatch(activateCourseGenie())
          try {
            await dispatch(resumeCourse({ values, video })).unwrap()
          } catch (err) {
            dispatch(showCourseGenieError())
            dispatch(resetCourseGenie())
          }
        }
      }
    }
    return video
  }, [values.field_video_link])
  const prevVideoItem = usePrevious(videoItem)

  // Resume/restart the course when the video link changes
  useAsyncEffect(async () => {
    if (
      genieEnabled &&
      genieActive &&
      videoItem &&
      prevVideoItem &&
      videoItem !== prevVideoItem
    ) {
      dispatch(resumeCourse({ values, video: videoItem }))
    }
  }, [videoItem])

  useEffect(() => {
    // Reset the switch to `false` when leaving the component
    return () => {
      dispatch(resetCourseGenie())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // The element that currently has focus
  const fieldRef = useRef<Element | null>(null)

  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"))

  if (!genieEnabled) {
    return (
      <div {...otherProps}>
        <Container fixed={true} maxWidth="md">
          {props.children}
        </Container>
      </div>
    )
  }

  const getGenieElementProps = (
    field: GenieRewriteAllowedField,
    name: string
  ): GenieElementProps | undefined => {
    if (genieActive) {
      return {
        onFocus: (event: FocusEvent) => {
          fieldRef.current = event.currentTarget
          dispatch(showPopper({ type: PopperType.Field, field, name }))
        },
      }
    }
  }

  return (
    <GenieContext.Provider
      value={{
        genieEnabled,
        getGenieElementProps,
        videoItem,
      }}
    >
      <div {...otherProps}>
        <Container fixed={true} maxWidth="md">
          {props.children}
        </Container>
      </div>
      {genieActive && (
        <>
          <GenieLoading />
          <GeniePopper
            open={popper?.type === PopperType.Field}
            anchorEl={fieldRef.current ?? undefined}
            placement={isLargeScreen ? "right" : "bottom-end"}
          >
            {popper?.type === PopperType.Field && (
              <GeniePopperFieldContent {...popper} />
            )}
          </GeniePopper>
          <GenieLampButton />
        </>
      )}
    </GenieContext.Provider>
  )
})(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    display: "grid",
    // Bias taking spacing away from the left side if the screen as the screen shrinks towards content width + popper width
    // Set the center content column to be the width of the maxWidth set on the Container component above.
    gridTemplateColumns: `1fr ${theme.breakpoints.values["md"]}px minmax(${GENIE_POPPER_WIDTH}, 1fr)`,
    gridTemplateAreas: '". content ."',
    "&>.MuiContainer-root": {
      gridArea: "content",
    },
  },
}))
