import { Box, Typography } from "@mui/material"
import { connect } from "react-redux"

import { useAppSelector } from "store/hooks"
import he from "he"
import moment from "moment"

import CategoryIcon from "components/CategoryIcon"
import ClockWhite from "../../../img/clock-white.svg"

import classNames from "classnames"
import CalendarWhite from "../../../img/calendar-white.svg"
import Values from "values.js"
import theme from "@mobilemind/common/src/theme/theme"

const mapStateToProps = ({ session, entityList }, ownProps) => {
  return {
    rubrics: entityList.orgRubrics,
    session,
  }
}

function EventCertificatePreview(props) {
  const event = props.activeEvent
  const allCategories = useAppSelector((state) => state.categories.data)

  const eventCategory = allCategories.find(
    (cat) => cat.id === event.category?.id
  )

  const eventLabel = props.isConference ? "Conference" : "Event"
  const eventImageSrc = event.eventImage?.file
    ? process.env.REACT_APP_API_URL + event.eventImage?.file.attributes.uri.url
    : event.eventImage.image

  let backgroundStyle = eventImageSrc
    ? {
        backgroundImage: `url(${eventImageSrc})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }
    : {}

  const {
    field_signature_name,
    field_signature_title,
    field_signature_font,
    field_logo_path,
  } = props.session.group

  const logoPath =
    field_logo_path && field_logo_path[0] && field_logo_path[0].value
      ? process.env.REACT_APP_API_URL + field_logo_path[0].value
      : null

  const fontName =
    field_signature_font[0] && field_signature_font[0].value
      ? field_signature_font[0].value
      : "Mrs-Saint-Delafield"

  const signatureName = field_signature_name[0] && field_signature_name[0].value
  const signatureTitle =
    field_signature_title[0] && field_signature_title[0].value

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: 450,
        width: 500,
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.18)",
        borderRadius: "12px",
        position: "relative",
        overflow: "hidden",
        backgroundColor: "white",
        paddingBlock: 5,
      }}
    >
      <div className="certificate-background" style={backgroundStyle} />

      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Typography
          style={{
            fontSize: 28,
            fontWeight: "bold",
          }}
        >
          Learner Name Here
        </Typography>
        <Typography
          style={{
            fontSize: 14,
            textTransform: "uppercase",
          }}
        >
          Attended an Event
        </Typography>
      </Box>

      <Box
        sx={(theme) => {
          const primary = new Values(theme.palette.primary.main)

          return {
            backgroundImage: `url(${eventImageSrc})`,
            backgroundColor: props.isConference
              ? primary.shade(30).hexString()
              : theme.palette.primary.main,
            width: 350,
            height: 200,
            borderRadius: "12px",
            position: "relative",
            zIndex: 9,
            backgroundSize: "cover",
            boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.18)",
          }
        }}
      >
        {!eventImageSrc && (
          <Box
            sx={{
              position: "absolute",
              left: 0,
              right: 0,
              top: "calc(50% - 60px)",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img src={CalendarWhite} style={{ width: 100 }} alt="Event" />
          </Box>
        )}

        <Box
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: -15,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "inline-flex",
              alignItems: "center",
              borderRadius: "24px",
              backgroundColor: "black",
              padding: 1,
              paddingInline: 3,
              color: "white",
              fontSize: 14,
            }}
          >
            {eventCategory && <CategoryIcon category={eventCategory} />}

            {event.category?.attributes?.name ? (
              <>{event.category.attributes.name}</>
            ) : (
              <>Event Category</>
            )}

            {event.certificateSettings.pdCredit && (
              <Box
                className="fadeIn"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: 12,
                }}
              >
                <img
                  src={ClockWhite}
                  style={{ width: 15, marginLeft: 15, marginRight: 5 }}
                  alt="Clock"
                />
                {event.pdCredit.hours}h {event.pdCredit.minutes}m
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "90%",
        }}
      >
        <Typography
          style={{
            fontSize: 20,
            lineHeight: "24px",
            marginTop: 10,
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {event.name ? (
            <>{he.decode(event.name)}</>
          ) : (
            <>{eventLabel} Name Here</>
          )}
        </Typography>
        <Typography
          style={{
            fontSize: 14,
            textTransform: "uppercase",
          }}
        >
          {moment(event.startDate).format("MMMM D, YYYY")}
        </Typography>
        <footer
          style={{
            marginTop: 20,
            marginBottom: -5,
            display: "flex",
            alignItems: event.certificateSettings.signature
              ? "center"
              : "space-between",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Box
            style={{
              flex: event.certificateSettings.signature ? 1 : "inherit",
              display: "flex",
              alignItems: "center",
            }}
          >
            {event.certificateSettings.orgLogo ? (
              <img
                src={logoPath}
                style={{
                  borderRadius: "6px",
                  overflow: "hidden",
                  height: 60,
                  flexShrink: 0,
                  marginTop: -5,
                }}
                alt={props.session.group.label[0].value}
              />
            ) : (
              <img
                src={theme.mixins.images.logo}
                style={{
                  width: 125,
                  flexShrink: 0,
                  marginTop: -5,
                }}
                alt="MobileMind"
              />
            )}
          </Box>
          {event.certificateSettings.signature && (
            <Box className="fadeIn" style={{ marginLeft: 15 }}>
              <Typography
                style={{ fontSize: 20 }}
                className={classNames(
                  "signature-text Mrs-Saint-Delafield",
                  fontName
                )}
              >
                {signatureName}
              </Typography>
              <Typography
                sx={{
                  textTransform: "uppercase",
                  fontSize: 8,
                  textAlign: "right",
                }}
              >
                {signatureTitle}
              </Typography>
            </Box>
          )}
        </footer>
      </Box>
    </Box>
  )
}

export default connect(mapStateToProps)(EventCertificatePreview)
