import moment from "moment"
import classNames from "classnames"

import { convertMinutesToHoursMinutes } from "../functions/index"
import theme from "../theme/theme"
import TimeCapsule from "./TimeCapsule"
import { Box, Typography } from "@mui/material"
import { CertificateEntityLearningPath } from "../types/jsonapi"

type PreviewProps = {
  teacherName: string
  pathName: string
  estimatedTime: number
  imageSrc: string
  isSharePrint?: boolean
  // eslint-disable-next-line
  isOrgLearningPath?: boolean
  pathCategories: {
    id: number | string
    imageSrc: string
    name: string
    timeSpent: number
  }[]
  // eslint-disable-next-line
  group: any
  // eslint-disable-next-line
  userData?: any
  // eslint-disable-next-line
  user?: any
  completedDate?: string
  isPartner?: boolean
  partnerImage?: string
  certificate?: CertificateEntityLearningPath
  // eslint-disable-next-line
  partnerSignatureInfo?: any
  certificateSettings: {
    signature: boolean
    orgLogo: boolean
    pdCredit: boolean
    categories: boolean
  }
}

export function LearningPathCertificatePreview(props: PreviewProps) {
  const {
    teacherName,
    pathName,
    certificateSettings,
    estimatedTime,
    pathCategories,
    group,
    isSharePrint,
    imageSrc,
    isOrgLearningPath,
    isPartner,
    partnerImage,
    certificate,
    partnerSignatureInfo,
  } = props

  const pdCreditHours = convertMinutesToHoursMinutes(estimatedTime).hours
  const pdCreditMinutes = convertMinutesToHoursMinutes(estimatedTime).minutes

  const {
    field_signature_name,
    field_signature_title,
    field_signature_font,
    field_logo_path,
  } = group

  const completedDate =
    certificate?.attributes.field_earned ?? props.completedDate

  let logoPath =
    field_logo_path && field_logo_path[0] && field_logo_path[0].value
      ? process.env.REACT_APP_API_URL + field_logo_path[0].value
      : null

  if (certificate && certificate.attributes.field_logo_path) {
    logoPath =
      process.env.REACT_APP_API_URL + certificate.attributes.field_logo_path
  } else if (isPartner || (partnerImage && partnerImage.includes("sites"))) {
    logoPath = partnerImage
  }

  let fontName =
    field_signature_font[0] && field_signature_font[0].value
      ? field_signature_font[0].value
      : "Mrs-Saint-Delafield"

  let signatureName = field_signature_name[0] && field_signature_name[0].value
  let signatureTitle =
    field_signature_title[0] && field_signature_title[0].value

  if (partnerSignatureInfo) {
    signatureName = partnerSignatureInfo.partner_signature_name
    signatureTitle = partnerSignatureInfo.partner_signature_title
    fontName =
      partnerSignatureInfo.partner_signature_font ?? "Mrs-Saint-Delafield"
  }

  let showPDCredit = certificateSettings.pdCredit && estimatedTime > 0
  if (
    !isOrgLearningPath &&
    !isPartner &&
    !window.location.href.includes("create/learning-paths")
  ) {
    showPDCredit = true
  }

  return (
    <div
      className="certificateLearningPath"
      style={{
        position: "relative",
        overflow: "hidden",
        borderRadius: 12,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundImage: `url(${imageSrc})`,
      }}
    >
      <div
        className="inner"
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.85)",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h2
            style={{
              fontWeight: "auto",
              height: "auto !important",
              marginBottom: 25,
            }}
          >
            <span
              style={{
                fontStyle: "normal",
              }}
            >
              This certifies that on{" "}
              {moment(completedDate).format("MMMM D, YYYY")}
            </span>
            <span
              style={{
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: 16,
                textTransform: "capitalize",
              }}
            >
              {teacherName.toLowerCase()}
            </span>
            <span style={{ fontWeight: "normal" }}>
              successfully completed all the required courses for the Learning
              Path
            </span>
          </h2>
          <span className="title">{pathName}</span>
          {((!isOrgLearningPath &&
            !isPartner &&
            !window.location.href.includes("create/learning-paths")) ||
            certificateSettings.categories) && (
            <div className="flexRow categories">
              {pathCategories.map((category, index) => {
                return (
                  <Box
                    key={category.id}
                    sx={{
                      padding: 0.5,
                      display: "flex",
                      alignItems: "center",
                      marginLeft: index && isSharePrint ? 2 : 0,
                    }}
                  >
                    <Box
                      sx={{
                        marginBottom: 1,
                        padding: 0.5,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        className={"category-icon"}
                        style={{
                          flexShrink: 0,
                          backgroundImage: "url(" + category.imageSrc + ")",
                        }}
                      />
                      {isSharePrint && (
                        <>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: 16,
                              paddingLeft: 2,
                              paddingRight: 4,
                            }}
                          >
                            {category.name}
                          </Typography>
                          <TimeCapsule
                            hours={
                              convertMinutesToHoursMinutes(category.timeSpent)
                                .hours
                            }
                            minutes={
                              convertMinutesToHoursMinutes(category.timeSpent)
                                .minutes
                            }
                          />
                        </>
                      )}
                    </Box>
                  </Box>
                )
              })}
            </div>
          )}{" "}
        </Box>

        {showPDCredit && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: 3,
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                marginRight: 2,
                textTransform: "uppercase",
                fontSize: 12,
                fontWeight: "bold",
              }}
            >
              Total Time Spent
            </Typography>

            <TimeCapsule hours={pdCreditHours} minutes={pdCreditMinutes} />
          </Box>
        )}

        <footer
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: certificateSettings.signature
              ? "space-between"
              : "center",
            width: "100%",
            marginTop: 0,
            padding: 0,
          }}
        >
          <Box
            style={{
              flex: certificateSettings.signature ? 1 : "inherit",
              display: "flex",
              alignItems: "center",
            }}
          >
            {certificateSettings.orgLogo ? (
              <img
                src={logoPath}
                style={{
                  borderRadius: "10px",
                  height: 60,
                  flexShrink: 0,
                }}
                alt={group.label[0].value}
              />
            ) : (
              <img
                src={theme.mixins.images.logo}
                style={{
                  width: 125,
                  flexShrink: 0,
                }}
                alt="MobileMind"
              />
            )}
          </Box>

          {certificateSettings.signature && (
            <Box className="fadeIn" style={{ marginLeft: 15 }}>
              <Typography
                style={{ fontSize: 20 }}
                className={classNames(
                  "signature-text Mrs-Saint-Delafield",
                  fontName
                )}
              >
                {signatureName}
              </Typography>
              <Typography
                sx={{
                  textTransform: "uppercase",
                  fontSize: 8,
                  textAlign: "right",
                }}
              >
                {signatureTitle}
              </Typography>
            </Box>
          )}
        </footer>
      </div>
    </div>
  )
}
