import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import parseDataUrl from "parse-data-url"

import {
  addPartnerToOrg,
  removePartnerFromOrg,
} from "../store/reducers/marketplace"
import { getSubGroups } from "store/reducers/session"

export function removePartnerFromGroups({ partner, subGroupsWithPartner }) {
  return async (dispatch, getState) => {
    const { session } = getState()
    const { token } = session

    try {
      dispatch(removePartnerFromOrg(partner))

      let i = 0

      while (i < subGroupsWithPartner.length) {
        const currentGroup = subGroupsWithPartner[i]
        let partnerData = currentGroup.field_partner.data.map((existing) => {
          return {
            id: existing.id,
            type: "group--partner",
          }
        })

        partnerData = partnerData.filter((p) => p.id !== partner.uuid)

        const body = JSON.stringify({
          data: {
            id: currentGroup.id,
            type: "group_content--organization-subgroup-group",
            relationships: {
              field_partner: {
                data: partnerData,
              },
            },
          },
        })
        await fetchWrapper.patch(
          "/api/group/group/" + currentGroup.id,
          token,
          body
        )

        i++
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function removePartnerFromOrganization({ partner }) {
  return async (dispatch, getState) => {
    const { session } = getState()
    const { token, partnershipUUIDs } = session
    let orgId, url, type

    // If they're acting as a true collection admin
    if (session.isCollectionAdmin && !session.activeMembership.data) {
      orgId = session.userCollection[0].uuid[0].value
      url = "/api/group/collection/" + orgId
      type = "collection"
    } else {
      orgId = session.group.uuid[0].value
      url = "/api/group/organization/" + orgId
      type = "group--organization"
    }

    const partnerData = partnershipUUIDs
      .map((id) => {
        return {
          id,
          type: "group--partner",
        }
      })
      .filter((p) => p.id !== partner.uuid)

    try {
      const body = JSON.stringify({
        data: {
          id: orgId,
          type,
          relationships: {
            field_partner: {
              data: partnerData,
            },
          },
        },
      })

      await fetchWrapper.patch(url, token, body)
      dispatch(removePartnerFromOrg(partner))

      return dispatch({
        type: "session/removePartnership",
        payload: partner.uuid,
      })
    } catch (err) {
      console.log(err)
    }
  }
}

export function addPartnerToOrganization({
  selectedGroups,
  partner,
  deliverAll,
}) {
  return async (dispatch, getState) => {
    const { session } = getState()
    const { token, partnershipUUIDs } = session

    let orgId, url, type

    if (session.isCollectionAdmin && !session.activeMembership.data) {
      orgId = session.userCollection[0].uuid[0].value
      url = "/api/group/collection/" + orgId
      type = "collection"
    } else {
      orgId = session.group.uuid[0].value
      url = "/api/group/organization/" + orgId
      type = "group--organization"
    }

    try {
      if (deliverAll) {
        const partnerData = partnershipUUIDs.map((id) => {
          return {
            id,
            type: "group--partner",
          }
        })

        partnerData.push({
          type: "group--partner",
          id: partner.uuid,
        })

        const body = JSON.stringify({
          data: {
            id: orgId,
            type,
            relationships: {
              field_partner: {
                data: partnerData,
              },
            },
          },
        })

        let response = await fetchWrapper.patch(url, token, body)
        if (response.ok) {
          await response.json()
          return dispatch({
            type: "session/addPartnership",
            payload: partnershipUUIDs.concat([partner.uuid]),
          })
        }
      }

      // Otherwise stick it on the selected groups
      else {
        selectedGroups.forEach(async (group) => {
          const partnerData =
            group.field_partner.data &&
            group.field_partner.data.map((existing) => {
              return {
                id: existing.id,
                type: "group--partner",
              }
            })

          const alreadyAdded = partnerData.find((p) => p.id === partner.uuid)

          if (!alreadyAdded) {
            partnerData.push({
              type: "group--partner",
              id: partner.uuid,
            })

            const body = JSON.stringify({
              data: {
                id: group.id,
                type: "group_content--organization-subgroup-group",
                relationships: {
                  field_partner: {
                    data: partnerData,
                  },
                },
              },
            })

            await fetchWrapper.patch(
              "/api/group/group/" + group.id,
              token,
              body
            )
          }
        })
      }

      await dispatch(addPartnerToOrg(partner))
      dispatch(getSubGroups())

      return true
    } catch (err) {
      console.log(err)
    }
  }
}

export function saveCollectionSettings(values) {
  return async (dispatch, getState) => {
    const { session } = getState()
    const { token } = session
    const collectionUUID = session.userCollection[0].uuid[0].value
    const { field_email_subject, field_email_body } = values

    try {
      let body = {
        data: {
          id: collectionUUID,
          type: "collection",
          attributes: {
            field_email_subject,
            field_email_body: {
              value: field_email_body,
            },
          },
        },
      }

      await fetchWrapper.patch(
        "/api/group/collection/" + collectionUUID,
        token,
        JSON.stringify(body)
      )
      setTimeout(() => {
        dispatch({
          type: "snackbar/openSnackbar",
          payload: { message: "Settings saved succesfully." },
        })

        setTimeout(() => {
          dispatch({
            type: "snackbar/closeSnackbar",
          })
        }, 2000)
      }, 750)

      return true
    } catch (err) {
      console.log(err)
    }
  }
}

export function savePartnerSettings(values) {
  return async (dispatch, getState) => {
    const { session } = getState()
    const { token } = session
    const partnerUUID = session.group.uuid[0].value
    try {
      let body = {
        data: {
          id: partnerUUID,
          type: "group--partner",
          attributes: {
            label: values.name,
            field_free_or_paid: values.partner_free_or_paid,
            field_signature_font: values.field_signature_font ?? "",
            field_signature_name: values.field_signature_name ?? "",
            field_signature_title: values.field_signature_title ?? "",
            field_partner_approval_required: values.partner_approval_required,
            field_partner_contact: {
              value: values.partner_contact,
            },
            field_partner_desc: {
              value: values.partner_description,
            },
            field_promo_video: values.partner_promo_video,
            field_topics: values.partner_topic_list.split(","),
          },
          relationships: {
            field_partner_prev: {
              data: [],
            },
          },
        },
      }

      // Initial Patch
      await fetchWrapper.patch(
        "/api/group/partner/" + partnerUUID,
        token,
        JSON.stringify(body)
      )

      // Next upload Marketplace Image (if) changed
      if (values.partner_image.includes("data:image")) {
        await fetch(
          process.env.REACT_APP_API_URL +
            "/api/group/partner/" +
            partnerUUID +
            "/field_partner_image",
          generateImageUploadOptions({
            token,
            values,
            image: values.partner_image,
          })
        )
      }

      // Figure out which images already have IDs and set them aside
      const existingPreviewImages = values.partner_preview
        .filter((existing) => existing.uuid)
        .map((image) => {
          return {
            type: "file--image",
            id: image.uuid,
          }
        })

      body.data.relationships = {
        field_partner_prev: {
          data: existingPreviewImages,
        },
      }

      const newPreviewImages = values.partner_preview.filter(
        (existing) => !existing.uuid
      )

      await fetchWrapper.patch(
        "/api/group/partner/" + partnerUUID,
        token,
        JSON.stringify(body)
      )

      let i = 0
      // go through new ones to upload to stick them on
      while (i < newPreviewImages.length) {
        const image = newPreviewImages[i]

        await fetch(
          process.env.REACT_APP_API_URL +
            "/api/group/partner/" +
            partnerUUID +
            "/field_partner_prev",
          generateImageUploadOptions({ token, values, image: image.uri })
        )

        i++
      }
      dispatch({
        type: "snackbar/openSnackbar",
        payload: { message: "Settings saved succesfully." },
      })

      setTimeout(() => {
        dispatch({
          type: "snackbar/closeSnackbar",
        })
      }, 2000)
      return true
    } catch (err) {
      console.log(err)
    }
  }
}

function generateImageUploadOptions({ token, values, image }) {
  const imageString = image.uri ?? image
  const extension = "." + imageString.split("image/")[1].split(";")[0]
  const filename = "Partner_Image_" + values.name.replace(" ", "_") + extension

  return {
    credentials: "include",
    method: "POST",
    headers: new Headers({
      Accept: "application/vnd.api+json",
      "Content-Type": "application/octet-stream",
      "X-CSRF-Token": token,
      // Not working? try this line that uses `he`
      // "Content-Disposition": 'file; filename="' + he.encode(filename) + '"',
      "Content-Disposition": 'file; filename="' + filename + '"',
    }),
    body: parseDataUrl(image).toBuffer(),
  }
}
