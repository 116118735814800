import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import he from "he"
import parseDataUrl from "parse-data-url"
import moment from "moment"
import qs from "qs"

export function getMandatedTraining(filters) {
  return async (dispatch, getState) => {
    const { session } = getState()
    const orgId = session.group.id[0].value

    let query = {
      sort_by: filters.sortBy,
      sort_order: filters.sortOrder,
      search: filters.searchQuery,
    }

    if (!filters.status) {
      query.field_draft_value = 1
    }
    if (filters.status === 1) {
      query.field_archive_value = 0
      query.field_draft_value = 0
    }
    if (filters.subGroup !== "any") {
      query.field_subgroup_target_id = filters.subGroup
    }
    if (filters.subGroup === "all") {
      query.field_subgroup_target_id = "0"
    }
    if (filters.jobTitle !== "any") {
      query.field_job_title_target_id = filters.jobTitle
    }

    if (filters.status === "archived") {
      query.field_archive_value = 1
    }

    if (filters.subGroup !== "any") {
      query.field_subgroup_target_id = filters.subGroup
    }

    if (
      !filters.isCompliance &&
      !session.isOrgAdmin &&
      !session.orgRoles.toString().includes("creator") &&
      (session.groupRoles.includes("group-admin") ||
        session.groupRoles.includes("group-creator"))
    ) {
      const subGroupId = session.subgroups.data[0].drupal_internal__id
      query.field_subgroup_target_id = subGroupId
    }

    try {
      const content = []
      const url = "/api/renew-course-ser/rcs_list/" + orgId + "/all"
      const response = await fetchWrapper.get(url + "?" + qs.stringify(query))
      if (response.ok) {
        const data = await response.json()
        if (!data.rows.content) {
          data.rows.forEach((row) => {
            row.image = row.field_image
            content.push(row)
          })
        }

        dispatch({
          type: "entityList/getMandatedTraining",
          payload: content,
          meta: {
            pages: data.pager.total_pages,
            totalRecords: Number(data.pager.total_items),
          },
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
}

function generateMandatedTrainingBody({ activeTraining, session, isDraft }) {
  const isDrafter =
    session.orgRoles &&
    !session.orgRoles.includes("organization-admin") &&
    (session.orgRoles.includes("organization-drafter") ||
      session.groupRoles.includes("group-drafter"))
  const { groupRoles } = session
  const isGroupAdmin =
    !session.orgRoles.includes("organization-admin") &&
    (groupRoles.includes("group-admin") ||
      groupRoles.includes("group-creator") ||
      groupRoles.includes("group-drafter"))

  const courseData = activeTraining.courses.map((course) => {
    return {
      type: "course_entity--course_entity",
      id: course.id,
    }
  })

  const body = {
    data: {
      type: "renew_course_ser--renew_course_ser",
      attributes: {
        title: activeTraining.name,
        field_no_prior_credit: activeTraining.field_no_prior_credit,
        field_description: activeTraining.body,
        field_archive: activeTraining.archived,
        field_video: activeTraining.videoLink,
        field_open_date: moment(activeTraining.openDate).format("YYYY-MM-DD"),
        field_deadline_date: moment(activeTraining.deadlineDate).format(
          "YYYY-MM-DD"
        ),
      },
      relationships: {
        field_courses: {
          data: courseData,
        },
      },
    },
  }

  // Set subgroup owner if user is group level
  if (activeTraining.subGroupOwner !== "org" && isGroupAdmin) {
    body.data.relationships.field_subgroup_owner = {
      data: {
        type: "group--group",
        id: activeTraining.subGroupOwner
          ? activeTraining.subGroupOwner
          : session.subgroups.data[0].id,
      },
    }
  }
  // Only include the org if they are not a MM administrator
  if (!session.roles.find((role) => role.target_id === "administrator")) {
    if (session.group.type && session.group.type[0].target_id === "partner") {
      body.data.relationships.field_partner = {
        data: [
          {
            type: "group--partner",
            id: session.group.uuid[0].value,
          },
        ],
      }
    } else {
      body.data.relationships.field_organization = {
        data: [
          {
            type: "group--organization",
            id: session.group.uuid[0].value,
          },
        ],
      }
    }
  }

  if (activeTraining.id) {
    body.data.id = activeTraining.id
    if (!isDrafter) {
      body.data.attributes.field_draft = isDraft ? true : false
    }
  } else {
    // If this is a brand new RCS, we need to keep it as a draft for now
    body.data.attributes.field_draft = true
  }

  if (activeTraining.subGroups.length) {
    body.data.relationships.field_subgroup = {
      data: [],
    }
    activeTraining.subGroups.forEach((group) => {
      body.data.relationships.field_subgroup.data.push({
        type: "group--group",
        id: group.id,
      })
    })
  } else if (isGroupAdmin && session.subgroups.data.length === 1) {
    body.data.relationships.field_subgroup = {
      data: [
        {
          type: "group--group",
          id: session.subgroups.data[0].id,
        },
      ],
    }
  } else {
    body.data.relationships.field_subgroup = {
      data: null,
    }
  }
  if (activeTraining.jobTitles.length) {
    body.data.relationships.field_job_title = {
      data: [],
    }
    activeTraining.jobTitles.forEach((jobTitle) => {
      body.data.relationships.field_job_title.data.push({
        type: "taxonomy_term--job_titles",
        id: jobTitle.id,
      })
    })
  } else {
    body.data.relationships.field_job_title = {
      data: null,
    }
  }

  return body
}

export function saveMandatedTraining(
  activeTraining,
  isDraft,
  hasSameCourses,
  priorCreditSwitchedToFalse
) {
  return async (dispatch, getState) => {
    const { session } = getState()

    try {
      let response
      const body = generateMandatedTrainingBody({
        activeTraining,
        session,
        isDraft,
      })
      if (activeTraining.id) {
        response = await fetchWrapper.patch(
          "/api/renew_course_ser/renew_course_ser/" + activeTraining.id,
          session.token,
          JSON.stringify(body)
        )
      } else {
        response = await fetchWrapper.post(
          "/api/renew_course_ser/renew_course_ser/",
          session.token,
          JSON.stringify(body)
        )
      }
      if (response.ok) {
        const data = await response.json()
        const createdTraining = data.data
        createdTraining.image = activeTraining.image
        const entityId = activeTraining.id
          ? activeTraining.id
          : createdTraining.id
        const promises = []
        activeTraining.courses.forEach((course) => {
          promises.push(
            dispatch(
              saveMandatedTrainingParagraph({
                course,
                createdTraining,
                renewParagraph: course.renewParagraph,
              })
            )
          )
        })
        Promise.allSettled(promises).then(async (response) => {
          const field_renew_course = {
            data: response.map((data) => {
              return {
                type: "paragraph--renewab",
                id: data.value.data.id,
                meta: {
                  target_revision_id:
                    data.value.data.attributes.drupal_internal__revision_id,
                  drupal_internal__target_id:
                    data.value.data.attributes.drupal_internal__id,
                },
              }
            }),
          }
          const coursePatch = {
            data: {
              id: entityId,
              type: "renew_course_ser--renew_course_ser",
              relationships: {
                field_renew_course,
              },
            },
          }
          // Stick the renew paragraphs on the RCS
          response = await fetchWrapper.patch(
            "/api/renew_course_ser/renew_course_ser/" + entityId,
            session.token,
            JSON.stringify(coursePatch)
          )

          dispatch({
            type: "activeMandatedTrainingSlice/startNewMT",
          })

          // Handle image upload for the Mandated Training series
          if (activeTraining.filename) {
            const options = {
              credentials: "include",
              method: "POST",
              headers: new Headers({
                Accept: "application/vnd.api+json",
                "Content-Type": "application/octet-stream",
                "X-CSRF-Token": session.token,
                "Content-Disposition":
                  'file; filename="' + he.encode(activeTraining.filename) + '"',
              }),
              body: parseDataUrl(activeTraining.image).toBuffer(),
            }
            await fetch(
              process.env.REACT_APP_API_URL +
                "/api/renew_course_ser/renew_course_ser/" +
                entityId +
                "/field_image",
              options
            )
          }

          // Finally we need to set field_draft to false if this was indeed a publish
          if (!isDraft && !activeTraining.id) {
            const draftPatch = {
              data: {
                id: entityId,
                type: "renew_course_ser--renew_course_ser",
                attributes: {
                  field_draft: false,
                },
              },
            }

            response = await fetchWrapper.patch(
              "/api/renew_course_ser/renew_course_ser/" + entityId,
              session.token,
              JSON.stringify(draftPatch)
            )

            dispatch({
              type: "entityList/publishMandatedTraining",
              payload: createdTraining,
              meta: {
                image: activeTraining.image,
              },
            })
          }

          if (activeTraining.id) {
            if (!hasSameCourses || priorCreditSwitchedToFalse) {
              // Queue item will be built after courses are changed and RCS is saved.
              // Queue will be run by hitting this endpoint
              await fetch(
                process.env.REACT_APP_API_URL +
                  "/api/rcs-update/" +
                  activeTraining.id,
                {
                  credentials: "include",
                  method: "PATCH",
                  body: JSON.stringify({ data: "data" }),
                  headers: new Headers({
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-CSRF-Token": session.token,
                  }),
                }
              )
            }
          }
          return data.data
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function saveMandatedTrainingParagraph(args) {
  return async (dispatch, getState) => {
    const { course, createdTraining, renewParagraph } = args
    const { session } = getState()
    try {
      const paragraphBody = {
        data: {
          type: "paragraph--renewab",
          attributes: {
            parent_id: createdTraining.attributes.drupal_internal__id,
            parent_type: "renew_course_ser",
            parent_field_name: "field_renew_course",
            field_retake_cadence: course.retakeCadence,
          },
          relationships: {
            field_course: {
              data: {
                type: "course_entity--course_entity",
                id: course.id,
              },
            },
          },
        },
      }

      let response
      if (renewParagraph && renewParagraph.id) {
        paragraphBody.data.id = renewParagraph.id
        response = await fetchWrapper.patch(
          "/api/paragraph/renewab/" + renewParagraph.id,
          session.token,
          JSON.stringify(paragraphBody)
        )
      } else {
        response = await fetchWrapper.post(
          "/api/paragraph/renewab/",
          session.token,
          JSON.stringify(paragraphBody)
        )
      }

      if (response.ok) {
        const data = await response.json()
        return data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function deleteMandatedTraining({ uuid }) {
  return async (dispatch, getState) => {
    const { session } = getState()
    try {
      let response = await fetchWrapper.remove(
        "/api/renew_course_ser/renew_course_ser/" + uuid,
        session.token
      )
      if (response.ok) {
        dispatch({
          type: "entityList/deleteMandatedTraining",
          payload: uuid,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
}
