import { Dialog, Button } from "@mui/material"
import "../styles/modal.scss"

function ModalJoinCode(props) {
  const { onClose, joinCode, open } = props

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog className="modal modal-join-code" onClose={handleClose} open={open}>
      <p style={{ marginTop: 45 }}>{joinCode}</p>
      <footer>
        <Button onClick={handleClose} className="button small cancel">
          Close
        </Button>
      </footer>
    </Dialog>
  )
}

export default ModalJoinCode
