import { Link } from "react-router-dom"

import { LoadingButton } from "@mui/lab"
import { Button, Dialog } from "@mui/material"
import { useAsync } from "@react-hook/async"
import "../styles/modal.scss"

type ModalDeleteProps = {
  onClose: () => void
  open: boolean
  type: string
  deleteItem: () => Promise<void> | void
  path?: string
}

function ModalDelete(props: ModalDeleteProps) {
  const { onClose, open, type, deleteItem, path } = props

  const handleClose = () => {
    onClose()
  }

  const [{ status }, confirm] = useAsync(async () => await deleteItem())

  return (
    <Dialog
      className="modal"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <p>Are you sure you want to delete this {type}?</p>
      <footer>
        <Button
          onClick={handleClose}
          className="button small cancel"
          disabled={status === "loading"}
        >
          Cancel
        </Button>
        {path ? (
          // Combining the Link and Button components can lead to unexpected
          // behavior, like the route being changed before the action is completed
          // @ts-ignore
          <Link to={path}>
            <Button onClick={confirm} className="button small delete">
              Yes, Delete {type}
            </Button>
          </Link>
        ) : (
          <LoadingButton
            onClick={confirm}
            className="button small delete"
            loading={status === "loading"}
            sx={{
              // Necessary to override the color of the delete button
              // when loading is true
              "&&.MuiLoadingButton-loading": {
                color: "transparent!important",
              },
            }}
          >
            Yes, Delete {type}
          </LoadingButton>
        )}
      </footer>
    </Dialog>
  )
}

export default ModalDelete
