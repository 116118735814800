import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import { createAsyncThunk } from "@reduxjs/toolkit"
import moment from "moment"
import parseDataUrl from "parse-data-url"

export const updateOrgSettings = createAsyncThunk(
  "session/updateOrgSettings",
  async (args, thunkAPI) => {
    const { session } = thunkAPI.getState()
    const org = session.group
    const { values } = args

    const {
      field_teacher_leaderboard_visibi,
      field_rewind_begin,
      field_rewind_end,
      field_open_time,
      field_close_time,
      field_knowbe4_api_key,
      field_org_time_zone,
      field_disable_observation,
      field_custom_mail,
      field_email_subject,
      field_email_body,
      field_signature_font,
      field_signature_name,
      field_signature_title,
      field_logo_path,
    } = values

    if (
      field_logo_path &&
      !field_logo_path.attributes &&
      !field_logo_path.includes("/sites/default")
    ) {
      const imageExtension = field_logo_path.split(";")[0].split("/")[1]
      const imageFilename =
        org.id[0].value +
        "." +
        moment().format("MM-DD-YYYY-h-mm") +
        "." +
        imageExtension +
        '"'

      let options = {
        credentials: "include",
        method: "POST",
        headers: new Headers({
          Accept: "application/vnd.api+json",
          "Content-Type": "application/octet-stream",
          "X-CSRF-Token": session.token,
          "Content-Disposition": 'file; filename="' + imageFilename,
        }),
        body: parseDataUrl(field_logo_path).toBuffer(),
      }

      await fetch(
        process.env.REACT_APP_API_URL +
          "/api/group/organization/" +
          org.uuid[0].value +
          "/field_org_logo",
        options
      )
    }

    let body = {
      data: {
        type: "group--organization",
        id: org.uuid[0].value,
        attributes: {
          field_teacher_leaderboard_visibi,
          field_knowbe4_api_key,
          field_signature_font,
          field_signature_name,
          field_signature_title,
          field_org_time_zone,
          field_disable_observation,
          field_custom_mail,
          field_email_subject,
          field_email_body,
          field_rewind_begin: moment(field_rewind_begin).format("YYYY-MM-DD"),
          field_rewind_end: moment(field_rewind_end).format("YYYY-MM-DD"),
          field_open_time: moment(field_open_time).format("h:mma"),
          field_close_time: moment(field_close_time).format("h:mma"),
        },
      },
    }

    if (!field_logo_path) {
      body.data.relationships = {
        field_org_logo: {
          data: null,
        },
      }
    }

    let response = await fetchWrapper.patch(
      "/api/group/organization/" + org.uuid[0].value,
      session.token,
      JSON.stringify(body)
    )

    let data = await response.json()
    return data
  }
)

export const removeKB4APIKey = createAsyncThunk(
  "session/removeKB4APIKey",
  async (args, thunkAPI) => {
    const { session } = thunkAPI.getState()
    const org = session.group

    const body = {
      data: {
        type: "group--organization",
        id: org.uuid[0].value,
        attributes: {
          field_knowbe4_api_key: null,
        },
      },
    }

    let response = await fetchWrapper.patch(
      "/api/group/organization/" + org.uuid[0].value,
      session.token,
      JSON.stringify(body)
    )

    let data = await response.json()

    return data
  }
)
