import TimeCapsule from "@mobilemind/common/src/components/TimeCapsule"
import moment from "moment"
import { useEffect, useState, useRef } from "react"

import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import { useAppSelector } from "store/hooks"

import ReviewerQuote from "@mobilemind/common/src/components/ReviewerQuote"
import SanitizedHTML from "@mobilemind/common/src/components/SanitizedHTML"
import UserLabel from "@mobilemind/common/src/components/UserLabel"
import RatingStar from "@mobilemind/common/src/icons/RatingStar"
import { Box, Button, ButtonBase, Stack, Tab, TextField } from "@mui/material"
import classNames from "classnames"
import CategorySelect from "@mobilemind/common/src/components/CategorySelect"
import { TabsCapsule } from "@mobilemind/common/src/components/tabsCapsule/TabsCapsule"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"

import {
  addRequestTimeBlock,
  approveDeclinePreApprovalStep,
  approveExternalRequest,
  declineExternalRequest,
  deleteRequestTimeBlock,
  getSingleExternalRequest,
  reopenExternalRequest,
  setCurrentAttachment,
  updateRequestTimeBlock,
} from "../../../store/reducers/externalPD"

import he from "he"
import DeleteButton from "../../../components/DeleteButton"

import { convertMinutesToHoursMinutes } from "@mobilemind/common/src/functions"
import Plus from "@mobilemind/common/src/icons/Plus"
import ButtonSmall from "../../../components/ButtonSmall"
import { useAppDispatch } from "../../../store/hooks"
import Loading from "@mobilemind/common/src/components/Loading"
import { DescriptionWithReadMore } from "./DescriptionWithReadMore"

interface CardProps {
  animationDelay?: number
  event?: any
  activeItem?: string
  onClick?: (event: any) => void
  preview?: boolean
}

type SingleRequestProps = {
  pre_app_form_name: string
  pre_appr_form_data: {
    questions?: {
      id: string
      bundle: string
      field_question_name: string
      field_required: string
      user_response?: string
      field_answer_op?: string
    }[]
    user_appr_steps: any[]
  }
}

type UploadedFile = {
  file: File
  base64: string | ArrayBuffer
} | null

const RequestCard = ({
  animationDelay,
  event,
  activeItem,
  onClick,
  preview,
}: CardProps) => {
  const dispatch = useAppDispatch()
  const categories = useAppSelector((state) => state.categories)
  const session = useAppSelector((state) => state.session)
  const [confirmDecline, setConfirmDecline] = useState(false)

  const [reviewerAttachment, setReviewerAttachment] =
    useState<UploadedFile>(null)

  const [isLoading, setIsLoading] = useState(false)
  const [singleRequestResult, setSingleRequestResult] =
    useState<SingleRequestProps | null>(null)

  const isDeclined = event.field_status_value === "declined"
  const isPending = event.field_status_value === "pending"
  const isAccepted = event.field_status_value === "accepted"
  const isAwaitingPreApproval = event.field_status_value === "awaiting_pre_appr"
  const isPreApprovalGranted = event.field_status_value === "pre_appr_granted"
  const isPreApprovalDeclined =
    event.field_status_value === "pre_approval_declined"

  const [comments, setComments] = useState("")
  const [isEditing, setIsEditing] = useState(false)
  const [currentTab, setCurrentTab] = useState(0)
  const { timeBlocks } = event

  const preApprovalSteps =
    singleRequestResult?.pre_appr_form_data?.user_appr_steps ?? []

  let furthestStep = 0

  preApprovalSteps.forEach((step: any, index: number) => {
    if (step.status === "approved") {
      furthestStep++
    }
  })

  const [currentApprovalStep, setCurrentApprovalStep] = useState(0)

  useEffect(() => {
    if (activeItem === event.id) {
      if (!singleRequestResult && !isLoading) {
        setIsLoading(true)

        dispatch(
          getSingleExternalRequest({
            event_id: event.usr_event_data_id,
            uid: event.field_user_target_id,
          })
        ).then((result: any) => {
          setSingleRequestResult(result.payload)

          if (result.payload.pre_appr_form_data?.user_appr_steps) {
            // Find the first step that is pending
            const firstPendingStepIndex =
              result.payload.pre_appr_form_data?.user_appr_steps.findIndex(
                (step: any) => {
                  return step.status === "pending"
                }
              )
            const firstDeclinedStepIndex =
              result.payload.pre_appr_form_data?.user_appr_steps.findIndex(
                (step: any) => {
                  return step.status === "denied"
                }
              )

            // Figure out if we need to start on the current pending step, the declined step, or the last step
            let activeApprovalStep = 0
            if (event.field_status_value === "awaiting_pre_appr") {
              activeApprovalStep = firstPendingStepIndex
            } else if (event.field_status_value === "pre_approval_declined") {
              activeApprovalStep = firstDeclinedStepIndex
            } else if (
              event.field_status_value === "pre_appr_granted" ||
              event.field_status_value === "accepted" ||
              isPending
            ) {
              activeApprovalStep =
                result.payload.pre_appr_form_data?.user_appr_steps.length - 1
            }

            // Set current tab to Credit Approval if we are totally done with pre-approval
            if (
              event.field_status_value === "pending" ||
              event.field_status_value === "accepted" ||
              event.field_status_value === "declined"
            ) {
              setCurrentTab(1)
            }
            setCurrentApprovalStep(activeApprovalStep)
          }

          setIsLoading(false)
        })
      }
    } else {
      setSingleRequestResult(null)
      setIsLoading(false)
    }
  }, [
    activeItem,
    event.id,
    event.field_status_value,
    event.field_user_target_id,
    isLoading,
    isPending,
    event.usr_event_data_id,
    singleRequestResult,
    dispatch,
  ])

  let totalMinutes = 0
  timeBlocks.forEach((block: any) => (totalMinutes += block.minutes))
  timeBlocks.forEach((block: any) => (totalMinutes += block.hours * 60))

  const hours = convertMinutesToHoursMinutes(totalMinutes).hours
  const minutes = convertMinutesToHoursMinutes(totalMinutes).minutes

  const existingCategoryIds: string[] = timeBlocks.map(
    (block: any) => block.categoryId
  )

  const user = event.user_data[0]
  const timestamp =
    isPending || isAwaitingPreApproval
      ? moment.unix(event.changed).format("YYYY-MM-DDTHH:mm:ss")
      : event.field_reviewed_date_value

  function updateTimeBlocks(timeBlock: any) {
    dispatch(updateRequestTimeBlock({ eventId: event.id, timeBlock }))
  }

  function deleteTimeBlock(timeBlock: any) {
    dispatch(deleteRequestTimeBlock({ eventId: event.id, timeBlock }))
  }

  function addTimeBlock() {
    let newCategory = categories.topCategories.find(
      (category: any) => !existingCategoryIds.includes(category.id)
    )
    dispatch(
      addRequestTimeBlock({
        eventId: event.id,
        newBlock: {
          categoryId: newCategory && newCategory.id,
          hours: 1,
          minutes: 0,
          id: "new-" + event.timeBlocks.length,
        },
      })
    )
  }

  const isPreApprovedRequest =
    singleRequestResult &&
    singleRequestResult.pre_appr_form_data?.questions?.length

  let preApprovalLabelColor = "#C9F03D"

  if (isPreApprovalGranted) {
    preApprovalLabelColor = "rgb(36, 220, 145)"
  }
  if (isPreApprovalDeclined) {
    preApprovalLabelColor = "rgb(235, 82, 82)"
  }

  let statusLabel =
    event.field_status_value === "declined" ? "Declined" : "Approved"

  if (
    event.field_status_value === "awaiting_pre_appr" ||
    event.field_status_value === "pending"
  ) {
    statusLabel = "Requested"
  }

  const currentStep = preApprovalSteps && preApprovalSteps[currentApprovalStep]
  const isPotentialApprover =
    session.orgRoles.includes("organization-admin") || currentStep?.is_approver

  const showUnassignedMessage =
    event.field_status_value !== "pending" &&
    event.field_status_value !== "declined" &&
    event.field_status_value !== "accepted"

  const isOrgReviewer =
    !session.orgRoles.includes("organization-admin") &&
    session.orgRoles.includes("organization-reviewer")
  const allowOrgReviewerActions = isOrgReviewer && !showUnassignedMessage

  const reviewerAttachmentFile = useRef(null)

  async function getReviewerAttachmentBase64(uploaded: any) {
    let reader = new FileReader()
    reader.readAsDataURL(uploaded)

    // Once we're done, get the file and name it
    reader.onloadend = async (event) => {
      setReviewerAttachment({
        file: uploaded,
        base64: event?.target?.result ?? "",
      })
    }
  }

  return (
    <li
      className="request-list-item"
      style={{ animationDelay: animationDelay + "s" }}
    >
      <Accordion expanded={activeItem === event.id}>
        <AccordionSummary onClick={onClick}>
          <div className="flexRow">
            <div style={{ width: 275 }}>
              {event.user_data[0]?.user_first_name &&
                event.user_data[0]?.user_last_name && (
                  <UserLabel
                    firstName={he.decode(event.user_data[0].user_first_name)}
                    lastName={he.decode(event.user_data[0].user_last_name)}
                    imagePath={event.user_data[0].user_pic}
                    subgroups={event.user_data[0].user_subgroup}
                  />
                )}
            </div>
            <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
              <div style={{ marginLeft: 10 }}>
                <h3 style={{ fontSize: 16 }}>
                  {event.field_rec_ext_event_target_id && (
                    <span
                      style={{
                        width: 15,
                        height: 15,
                        marginRight: 5,
                        position: "relative",
                        top: 1,
                      }}
                      className="icon recommended"
                    />
                  )}
                  {event.title}
                </h3>
                <span className="timestamp">
                  {moment(event.field_start_date_value).format("MMMM Do, YYYY")}
                </span>
              </div>
            </div>

            {!isAwaitingPreApproval &&
              !isPreApprovalGranted &&
              !isPreApprovalDeclined && (
                <TimeCapsule hours={hours} minutes={minutes} />
              )}

            {(isAwaitingPreApproval ||
              isPreApprovalGranted ||
              isPreApprovalDeclined) && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 1.5,
                  paddingInline: 3,
                  backgroundColor: preApprovalLabelColor,
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  fontSize: 12,
                  borderRadius: 2,
                  marginRight: 1,
                  color: isPreApprovalDeclined ? "white" : "black",
                }}
              >
                {isAwaitingPreApproval && <>Awaiting Pre-Approval</>}
                {isPreApprovalGranted && <>Pre-Approval Granted</>}
                {isPreApprovalDeclined && <>Pre-Approval Declined</>}
              </Box>
            )}

            {isPending && <span className="icon status-icon raised-hand" />}
            {isDeclined && <span className="icon status-icon declined" />}
            {isAccepted && <span className="icon status-icon reviewed" />}
            {isAwaitingPreApproval && (
              <span className="icon status-icon pre-approval" />
            )}
            {!isPreApprovalGranted && !isPreApprovalDeclined && (
              <span
                style={{
                  display: "block",
                  fontWeight: "bold",
                  fontSize: 14,
                  color: isDeclined ? "#EB5252" : "black",
                }}
              >
                {statusLabel} {moment(timestamp).fromNow()}
              </span>
            )}
          </div>
        </AccordionSummary>

        <AccordionDetails
          sx={{
            padding: 0,
            paddingTop: 0,
          }}
        >
          {event.description__value && (
            <Box
              sx={{
                borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
                padding: 4,
              }}
            >
              <section>
                <header>Description</header>

                <DescriptionWithReadMore
                  description={event.description__value}
                />
              </section>
            </Box>
          )}

          <Box
            sx={{
              padding: 4,
              display: "flex",
              justifyContent: "center",
            }}
          >
            {!isLoading && isPreApprovedRequest && (
              <TabsCapsule
                value={currentTab}
                onChange={(event, newTab) => setCurrentTab(newTab)}
              >
                <Tab value={0} label={"Pre-Approval"} />
                <Tab
                  value={1}
                  disabled={!isPending && !isDeclined && !isAccepted}
                  label={"Credit Approval"}
                />
              </TabsCapsule>
            )}
          </Box>

          {isLoading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: 4,
              }}
            >
              <Loading message="Getting request..." />
            </Box>
          )}

          {!isLoading && (
            <>
              {/** Pre-Approval Info */}
              {currentTab === 0 && isPreApprovedRequest && (
                <Box>
                  <Box sx={{ paddingInline: 4 }}>
                    {singleRequestResult?.pre_app_form_name && (
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: 10,
                          marginTop: -5,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            background: "#f0f0f0",
                            padding: 2,
                            paddingBlock: 1,
                            borderRadius: 3,
                          }}
                        >
                          <span
                            style={{ width: 15, height: 15, marginRight: 5 }}
                            className="icon pre-approval-form"
                          ></span>
                          <strong style={{ fontSize: 14 }}>
                            {singleRequestResult?.pre_app_form_name}
                          </strong>
                        </Box>
                      </Box>
                    )}
                    <header>Learner Responses</header>

                    {singleRequestResult &&
                      singleRequestResult.pre_appr_form_data.questions?.map(
                        (question, index) => {
                          return (
                            <section
                              style={{ marginBlock: 15 }}
                              key={index}
                              className="pre-approval-item"
                            >
                              <strong style={{ fontSize: 18 }}>
                                {question.field_question_name}
                              </strong>

                              {question.bundle === "open_ended_questions" && (
                                <p style={{ marginTop: 5 }}>
                                  {question.user_response}
                                </p>
                              )}

                              {question.bundle === "fixed_choice_questions" && (
                                <ul style={{ marginTop: 5, paddingLeft: 15 }}>
                                  {question.user_response
                                    ?.split(",")
                                    .map((res) => {
                                      return (
                                        <li
                                          style={{
                                            listStyleType: "disc",
                                            fontSize: 14,
                                          }}
                                        >
                                          {res}
                                        </li>
                                      )
                                    })}
                                </ul>
                              )}

                              {question.bundle === "date_question" &&
                                question.user_response && (
                                  <p style={{ marginTop: 5 }}>
                                    {moment(question.user_response).format(
                                      "MMMM Do, YYYY"
                                    )}
                                  </p>
                                )}
                            </section>
                          )
                        }
                      )}
                  </Box>

                  <Box
                    sx={{
                      borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                      padding: 4,
                    }}
                  >
                    <Box
                      sx={{
                        marginBottom: 3,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <strong>
                        Approval Step {currentApprovalStep + 1}:{" "}
                        {preApprovalSteps[currentApprovalStep]?.step_name}
                      </strong>
                    </Box>

                    {preApprovalSteps.length > 0 && (
                      <Box
                        className="approval-stepper"
                        style={{
                          margin: "0 auto",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Stepper activeStep={currentApprovalStep}>
                          {preApprovalSteps.map(
                            (step: any, stepIndex: number) => {
                              return (
                                <Step key={2}>
                                  <ButtonBase
                                    disabled={stepIndex > furthestStep}
                                    onClick={() => {
                                      setCurrentApprovalStep(stepIndex)
                                    }}
                                    sx={(theme) => ({
                                      width: 35,
                                      height: 35,
                                      borderRadius: 2,
                                      backgroundColor:
                                        step.status === "denied"
                                          ? "#F56B6B !important"
                                          : theme.palette.secondary.main +
                                            " !important",
                                      opacity:
                                        stepIndex > furthestStep ? 0.5 : 1,
                                      border:
                                        currentApprovalStep === stepIndex
                                          ? "2px solid #000"
                                          : "none",
                                    })}
                                  >
                                    {stepIndex > furthestStep - 1 &&
                                      step.status === "pending" && (
                                        <strong>{stepIndex + 1}</strong>
                                      )}
                                    {step.status === "approved" && (
                                      <span
                                        style={{ width: 15, height: 15 }}
                                        className="icon check"
                                      />
                                    )}
                                    {step.status === "denied" && (
                                      <span
                                        style={{ width: 20, height: 20 }}
                                        className="icon denied"
                                      />
                                    )}
                                  </ButtonBase>
                                </Step>
                              )
                            }
                          )}
                        </Stepper>
                      </Box>
                    )}

                    <Box sx={{ marginTop: 2 }}>
                      {preApprovalSteps.length > 0 &&
                        preApprovalSteps.map((step: any, stepIndex: number) => {
                          if (currentApprovalStep === stepIndex) {
                            return (
                              <Box key={stepIndex}>
                                {step.step_instructions &&
                                  step.status === "pending" && (
                                    <>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          marginBottom: -2,
                                        }}
                                      >
                                        <>
                                          <strong>Approval Instructions</strong>
                                          <Box
                                            sx={{
                                              paddingBlock: 1,
                                              paddingInline: 2,
                                              borderRadius: 2,
                                              backgroundColor: "#ebebeb",
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <strong style={{ fontSize: 12 }}>
                                              Approve or Decline by{" "}
                                              {moment(
                                                step.deadline.split("T")[0]
                                              ).format("MMMM Do, YYYY")}
                                            </strong>
                                          </Box>
                                        </>
                                      </Box>

                                      <SanitizedHTML
                                        html={step.step_instructions}
                                      />
                                    </>
                                  )}

                                {(step.status === "approved" ||
                                  step.status === "denied") && (
                                  <>
                                    <strong
                                      style={{ fontSize: 12, opacity: 0.8 }}
                                    >
                                      {step.status === "approved" ? (
                                        <>Approved</>
                                      ) : (
                                        <>Declined</>
                                      )}{" "}
                                      {moment(step.decision_date).fromNow()}
                                    </strong>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                      }}
                                    >
                                      <Stack
                                        direction="row"
                                        sx={{
                                          flexShrink: 0,
                                          alignItems: "center",
                                          marginBottom: "15px!important",
                                          marginLeft: "0!important",
                                          paddingRight: 6,
                                          marginTop: 2,
                                        }}
                                        spacing={1}
                                      >
                                        <Box style={{ marginRight: -25 }}>
                                          <UserLabel
                                            firstName={he.decode(
                                              step.appr_first_name
                                            )}
                                            lastName={he.decode(
                                              step.appr_last_name
                                            )}
                                            imagePath={
                                              process.env.REACT_APP_API_URL +
                                              step.approver_picture
                                            }
                                            subgroups={step.approver_subgroup.join(
                                              ", "
                                            )}
                                          />
                                        </Box>
                                        <Box
                                          className="approval-notes"
                                          sx={{
                                            padding: 2,
                                            borderRadius: 0.8,
                                            marginTop: 1,
                                            backgroundColor:
                                              "rgb(189, 245, 222)",
                                          }}
                                        >
                                          <div className="triangle" />
                                          <em style={{ fontSize: 14 }}>
                                            {step.notes}
                                          </em>
                                        </Box>
                                      </Stack>
                                    </Box>
                                  </>
                                )}
                              </Box>
                            )
                          }
                          return null
                        })}
                    </Box>

                    {!isPotentialApprover && showUnassignedMessage && (
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          fontSize: 14,
                          opacity: 0.6,
                        }}
                      >
                        <strong>
                          You aren't assigned to review this step.
                        </strong>
                      </Box>
                    )}

                    {isPotentialApprover &&
                      preApprovalSteps[currentApprovalStep]?.status ===
                        "pending" && (
                        <footer className="flexRow">
                          {!confirmDecline ? (
                            <>
                              <TextField
                                variant="standard"
                                style={{ width: "100%" }}
                                value={comments}
                                placeholder={"Your comments (optional)"}
                                onChange={(event) => {
                                  setComments(event.target.value)
                                }}
                              />

                              <Button
                                className="button secondary large"
                                onClick={() => {
                                  setConfirmDecline(true)
                                }}
                              >
                                Decline
                              </Button>

                              <ButtonLarge
                                onClick={() => {
                                  dispatch(
                                    approveDeclinePreApprovalStep({
                                      approvalStep:
                                        preApprovalSteps[currentApprovalStep],
                                      event,
                                      comments,
                                      isApprove: true,
                                    })
                                  )
                                  setComments("")
                                  setIsEditing(false)
                                  onClick && onClick({})
                                }}
                              >
                                Approve
                              </ButtonLarge>
                            </>
                          ) : (
                            <>
                              <TextField
                                variant="standard"
                                style={{ width: "100%" }}
                                value={comments}
                                placeholder={
                                  "Enter your reason for declining this request"
                                }
                                onChange={(event) => {
                                  setComments(event.target.value)
                                }}
                              />
                              <Button
                                className="button secondary large"
                                onClick={() => {
                                  setConfirmDecline(false)
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                className={classNames(
                                  "button secondary large delete",
                                  !comments && "disabled"
                                )}
                                onClick={() => {
                                  dispatch(
                                    approveDeclinePreApprovalStep({
                                      approvalStep:
                                        preApprovalSteps[currentApprovalStep],
                                      event,
                                      comments,
                                      isApprove: false,
                                    })
                                  )
                                  setConfirmDecline(false)
                                  setComments("")
                                  setIsEditing(false)
                                }}
                              >
                                Decline
                              </Button>
                            </>
                          )}
                        </footer>
                      )}
                  </Box>
                </Box>
              )}

              {/** Credit Approval */}
              {(currentTab === 1 || !isPreApprovedRequest) && (
                <Box
                  sx={{ marginTop: isPreApprovedRequest ? 3 : -5, padding: 4 }}
                >
                  <div className="request-content">
                    <div className="column">
                      <section
                        style={{ position: "relative" }}
                        onClick={() => isPending && setIsEditing(true)}
                      >
                        <header>PD Categories</header>
                        <ul
                          style={{ marginBottom: 0, marginTop: 10 }}
                          className={classNames(
                            "timeBlockList",
                            !isPending && "reviewed",
                            isEditing && "isEditing"
                          )}
                        >
                          <span
                            className="icon edit"
                            style={{
                              width: 25,
                              height: 25,
                              position: "absolute",
                              top: 40,
                              right: 10,
                            }}
                          />

                          {timeBlocks.map((timeBlock: any, index: number) => {
                            const blockCategory = categories.data.find(
                              (cat: any) => cat.id === timeBlock.categoryId
                            )

                            return (
                              <li
                                key={index}
                                className={classNames(
                                  "flexRow",
                                  !index && "first"
                                )}
                                style={{ marginTop: 10 }}
                              >
                                <TextField
                                  className="timeBlocks"
                                  variant="standard"
                                  type="number"
                                  label={!index && "Hours"}
                                  value={timeBlock.hours}
                                  onChange={(event) => {
                                    const hours =
                                      event.target.value.length < 3 &&
                                      Number(event.target.value) < 60 &&
                                      Number(event.target.value) >= 0
                                        ? Number(event.target.value)
                                        : Number(timeBlock.hours)

                                    const updatedBlock = {
                                      hours,
                                      minutes: Number(timeBlock.minutes),
                                      categoryId: timeBlock.categoryId,
                                      id: timeBlock.id,
                                    }
                                    updateTimeBlocks({
                                      index,
                                      value: updatedBlock,
                                    })
                                  }}
                                />

                                <TextField
                                  className="timeBlocks"
                                  variant="standard"
                                  type="number"
                                  label={!index && "Minutes"}
                                  value={timeBlock.minutes}
                                  onChange={(event) => {
                                    const minutes =
                                      event.target.value.length < 3 &&
                                      Number(event.target.value) < 60 &&
                                      Number(event.target.value) >= 0
                                        ? Number(event.target.value)
                                        : Number(timeBlock.minutes)

                                    const updatedBlock = {
                                      hours: Number(timeBlock.hours),
                                      minutes,
                                      categoryId: timeBlock.categoryId,
                                      id: timeBlock.id,
                                    }
                                    updateTimeBlocks({
                                      index,
                                      value: updatedBlock,
                                    })
                                  }}
                                />

                                <CategorySelect
                                  currentValue={blockCategory ?? null}
                                  onChange={(selected: any) => {
                                    const updatedBlock = {
                                      hours: Number(timeBlock.hours),
                                      minutes: Number(timeBlock.minutes),
                                      categoryId: selected && selected.id,
                                    }
                                    updateTimeBlocks({
                                      index,
                                      value: updatedBlock,
                                    })
                                  }}
                                  categories={categories}
                                  label={!index ? "Category" : ""}
                                  allowedLevels={3}
                                />

                                {timeBlocks.length > 1 && (
                                  <DeleteButton
                                    onClick={() => {
                                      deleteTimeBlock(timeBlock)
                                    }}
                                  />
                                )}
                              </li>
                            )
                          })}
                          {existingCategoryIds.length <
                            categories.topCategories.length && (
                            <li
                              style={{ listStyleType: "none", marginTop: 10 }}
                            >
                              <ButtonSmall
                                onClick={() => {
                                  addTimeBlock()
                                }}
                              >
                                <Plus />
                                <span style={{ paddingLeft: 5 }}>
                                  Add Time Category
                                </span>
                              </ButtonSmall>
                            </li>
                          )}
                        </ul>
                      </section>

                      <section className="ratingContainer">
                        <header>{user.user_first_name}'s Rating</header>
                        <div className="flexRow">
                          {[1, 2, 3, 4, 5].map((value) => {
                            return (
                              <RatingStar
                                key={value}
                                selected={event.field_rating_value >= value}
                              />
                            )
                          })}
                        </div>
                      </section>

                      {event.field_learner_comment_value && (
                        <section>
                          <header>Comments</header>
                          <div className="flexRow">
                            <SanitizedHTML
                              html={event.field_learner_comment_value}
                            />
                          </div>
                        </section>
                      )}
                    </div>

                    <div className="column">
                      {event.description__value && (
                        <section>
                          <header>Description</header>
                          <SanitizedHTML html={event.description__value} />
                        </section>
                      )}

                      {event.attachments.length > 0 && (
                        <section>
                          <header>{user.user_first_name}'s Attachments</header>
                          <ul className="pd-attachment-list">
                            {event.attachments.map((file: any) => {
                              const itemContent = (
                                <>
                                  {file.extension !== "png" &&
                                  file.extension !== "jpg" &&
                                  file.extension !== "jpeg" ? (
                                    <span
                                      className={classNames(
                                        "icon",
                                        file.extension
                                      )}
                                    />
                                  ) : (
                                    <span
                                      className={classNames("icon image")}
                                      style={{
                                        backgroundImage:
                                          "url(" + file.file + ")",
                                      }}
                                    />
                                  )}
                                  {decodeURIComponent(
                                    he.decode(file.filename)
                                  ).replaceAll("&#x202F;", " ")}
                                </>
                              )

                              return (
                                <li key={file.filename}>
                                  {file.extension !== "png" &&
                                  file.extension !== "jpg" &&
                                  file.extension !== "jpeg" ? (
                                    <Button>
                                      <a
                                        href={file.file}
                                        target={"_blank"}
                                        rel="noreferrer"
                                      >
                                        {itemContent}
                                      </a>
                                    </Button>
                                  ) : (
                                    <Button
                                      onClick={() => {
                                        dispatch(setCurrentAttachment(file))
                                      }}
                                    >
                                      {itemContent}
                                    </Button>
                                  )}
                                </li>
                              )
                            })}
                          </ul>
                        </section>
                      )}

                      <header>Reviewer Attachments</header>
                      <input
                        type="file"
                        id="file"
                        ref={reviewerAttachmentFile}
                        style={{ display: "none" }}
                        onChange={(event) => {
                          event.target.files &&
                            getReviewerAttachmentBase64(event.target.files[0])
                        }}
                      />
                      {!reviewerAttachment?.file &&
                        event.rev_attachments_url?.length > 0 &&
                        event.rev_attachments_url.map((url: string) => {
                          const filename = url.replace(/^.*[\\/]/, "")
                          const extension = filename.split(".").pop()
                          return (
                            <ul className="pd-attachment-list">
                              <li>
                                <a
                                  style={{ color: "black" }}
                                  href={url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      flex: 1,
                                      fontSize: 14,
                                      color: "black",
                                    }}
                                  >
                                    {!filename.includes("png") &&
                                    !filename.includes("jpg") &&
                                    !filename.includes("jpeg") ? (
                                      <span
                                        className={classNames(
                                          "icon",
                                          extension
                                        )}
                                      />
                                    ) : (
                                      <span
                                        className={classNames("icon image")}
                                        style={{
                                          backgroundImage: "url(" + url + ")",
                                        }}
                                      />
                                    )}
                                    {decodeURIComponent(
                                      he.decode(filename)
                                    ).replaceAll("&#x202F;", " ")}
                                  </Box>
                                </a>
                              </li>
                            </ul>
                          )
                        })}
                      {reviewerAttachment?.file && (
                        <ul className="pd-attachment-list">
                          <li>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                flex: 1,
                              }}
                            >
                              {!reviewerAttachment?.file.type.includes("png") &&
                              !reviewerAttachment?.file.type.includes("jpg") &&
                              !reviewerAttachment?.file.type.includes(
                                "jpeg"
                              ) ? (
                                <span
                                  className={classNames(
                                    "icon",
                                    reviewerAttachment?.file.type
                                      .replace("image/", "")
                                      .replace("application/", "")
                                  )}
                                />
                              ) : (
                                <span
                                  className={classNames("icon image")}
                                  style={{
                                    backgroundImage:
                                      "url(" + reviewerAttachment?.base64 + ")",
                                  }}
                                />
                              )}
                              {reviewerAttachment?.file.name}
                            </Box>
                            <DeleteButton
                              onClick={() => {
                                setReviewerAttachment(null)
                              }}
                            />
                          </li>
                        </ul>
                      )}
                      {event.field_status_value === "pending" &&
                        !reviewerAttachment?.file && (
                          <Button
                            style={{ marginLeft: 0, marginTop: 5 }}
                            className="button small"
                            onClick={() => {
                              // @ts-ignore
                              reviewerAttachmentFile?.current?.click()
                            }}
                          >
                            <span className="icon attach-file" />
                            Attach File
                          </Button>
                        )}
                    </div>
                  </div>

                  {event.reviewer && event.field_rev_comment_value && (
                    <div className="reviewer-comments">
                      <ReviewerQuote
                        groupLabel={session.group.label[0].value}
                        imageSrc={event.reviewer.reviewer_pic}
                        firstName={event.reviewer.reviewer_first_name}
                        lastName={event.reviewer.reviewer_last_name}
                        text={event.field_rev_comment_value}
                      />
                    </div>
                  )}

                  {!isPending && !isAwaitingPreApproval && (
                    <footer
                      className="flexRow"
                      style={{ marginTop: 0, justifyContent: "flex-end" }}
                    >
                      <Button
                        className="button secondary large"
                        onClick={() => {
                          dispatch(reopenExternalRequest({ event }))
                        }}
                      >
                        <span className="icon resubmit" />
                        Reopen Request
                      </Button>
                    </footer>
                  )}

                  {!preview &&
                    isPending &&
                    (!isOrgReviewer || allowOrgReviewerActions) && (
                      <footer
                        className="flexRow"
                        style={{ justifyContent: "flex-end" }}
                      >
                        {!confirmDecline ? (
                          <>
                            <TextField
                              variant="standard"
                              style={{ width: "100%" }}
                              value={comments}
                              placeholder={"Your comments (optional)"}
                              onChange={(event) => {
                                setComments(event.target.value)
                              }}
                            />
                            <Button
                              className="button secondary large"
                              onClick={() => {
                                setConfirmDecline(true)
                              }}
                            >
                              Decline
                            </Button>

                            <ButtonLarge
                              onClick={() => {
                                dispatch(
                                  approveExternalRequest({
                                    event,
                                    timeBlocks,
                                    comments,
                                    reviewerAttachment,
                                  })
                                )
                                setComments("")
                                setIsEditing(false)
                              }}
                            >
                              Approve
                            </ButtonLarge>
                          </>
                        ) : (
                          <>
                            <TextField
                              variant="standard"
                              style={{ width: "100%" }}
                              value={comments}
                              placeholder={
                                "Enter your reason for declining this request"
                              }
                              onChange={(event) => {
                                setComments(event.target.value)
                              }}
                            />
                            <Button
                              className="button secondary large"
                              onClick={() => {
                                setConfirmDecline(false)
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              className={classNames(
                                "button secondary large delete",
                                !comments && "disabled"
                              )}
                              onClick={() => {
                                dispatch(
                                  declineExternalRequest({
                                    event,
                                    comments,
                                    reviewerAttachment,
                                  })
                                )
                                setConfirmDecline(false)
                                setComments("")
                                setIsEditing(false)
                              }}
                            >
                              Decline
                            </Button>
                          </>
                        )}
                      </footer>
                    )}

                  {isOrgReviewer && showUnassignedMessage && (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        fontSize: 14,
                        opacity: 0.6,
                      }}
                    >
                      <strong>
                        You aren't assigned to review this request.
                      </strong>
                    </Box>
                  )}
                </Box>
              )}
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </li>
  )
}

export default RequestCard
