import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"

import TimeCapsule from "../../components/TimeCapsule"

import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"
import classNames from "classnames"

import CertificateMenu from "./certificate/CertificateMenu"

import Loading from "../../components/Loading"
import { LearningPath } from "../../types/explore"
import BackpackProps from "../../types/backpack"

import { SlideItem } from "./ContentRow"

interface RowProps {
  data: LearningPath[]
  backpack: BackpackProps
  goTo?: (location: string) => void
  increaseLPPage: (arg: number) => void
  setLPCarousel: (arg: number) => void
  userId?: number
  // eslint-disable-next-line
  session: any
}

const LPRow = (props: RowProps) => {
  const {
    setLPCarousel,
    increaseLPPage,
    goTo,
    backpack,
    userId,
    session,
    data,
  } = props

  const [chunkSize, setChunkSize] = useState(5)

  const dispatch = useDispatch()

  function onResize() {
    if (window.innerWidth < 1275) {
      setChunkSize(3)
    } else if (window.innerWidth < 1600) {
      setChunkSize(4)
    } else {
      setChunkSize(5)
    }
  }

  useEffect(() => {
    onResize()
    window.addEventListener("resize", onResize)
    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [])

  const chunkedData = []

  for (let i = 0; i < data.length; i += chunkSize) {
    const chunk = data.slice(i, i + chunkSize)
    chunkedData.push(chunk)
  }

  function nextSlideChanged(event: SlideItem) {
    dispatch(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setLPCarousel(event.item > backpack.learningPaths.activeIndex ? 1 : -1)
    )
  }

  function nextSlide(event: SlideItem) {
    if (event.itemsInSlide - 2 === event.item) {
      if (
        backpack.learningPaths.currentPage <
        backpack.learningPaths.totalPages - 1
      ) {
        dispatch(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          increaseLPPage(event.item)
        )
      }
    }
  }

  const items = chunkedData.map((chunk: LearningPath[], index: number) => {
    return (
      <div key={index} className="chunk" style={{ flexWrap: "wrap" }}>
        {chunk.map((learningPath: LearningPath, index: number) => {
          const totalMinutes = Number(
            learningPath.field_user_lp_total_time_spent
          )
          const hours =
            totalMinutes >= 60
              ? Math.floor(totalMinutes / 60).toLocaleString()
              : 0
          const minutes = totalMinutes % 60

          return (
            <div key={index} className="item learning-path-item">
              <div
                onClick={() => {
                  if (goTo) {
                    goTo("/learning-path/" + learningPath.field_lp_id)
                  }
                }}
                style={{ position: "relative" }}
              >
                <div
                  className="lp-background"
                  style={{
                    backgroundImage:
                      "url(" + learningPath.field_lp_image_uri + ")",
                  }}
                >
                  <div className="gradient" />
                  <strong className="title">{learningPath.name}</strong>
                </div>
                <div className="capsule-wrapper">
                  <TimeCapsule hours={Number(hours)} minutes={minutes} />
                </div>
              </div>

              <footer style={{ marginTop: 20 }}>
                <CertificateMenu
                  label={""}
                  session={session}
                  user={session.user}
                  userId={userId}
                  learningPath={learningPath}
                  userData={backpack.userData}
                />
              </footer>
            </div>
          )
        })}
      </div>
    )
  })

  return (
    <div
      className={classNames(
        "inner inner-carousel",
        !backpack.learningPaths.activeIndex && "disablePrev",
        chunkedData.length - 1 === backpack.learningPaths.activeIndex &&
          "disableNext"
      )}
    >
      {backpack.learningPaths.isFetching && <Loading />}

      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <AliceCarousel
          infinite={true}
          activeIndex={backpack.learningPaths.activeIndex}
          disableDotsControls={chunkedData.length === 1}
          paddingRight={25}
          paddingLeft={25}
          autoWidth={true}
          items={items}
          onSlideChange={(event) => nextSlide(event)}
          onSlideChanged={(event) => nextSlideChanged(event)}
        />
      }
    </div>
  )
}

export default LPRow
