import { Switch, Route } from "react-router-dom"
import { useAppSelector } from "store/hooks"

import Unauthorized from "@mobilemind/common/src/components/Unauthorized"

import ComplianceLayout from "../features/compliance/ComplianceLayout"
import LeaderboardsLayout from "../features/leaderboards/LeaderboardsLayout"
import TeacherAccessLayout from "../features/teacherAccess/TeacherAccessLayout"
import { AnalyticsLayout } from "../features/analytics/AnalyticsLayout"
import { FeatureName } from "store/reducers/session"

export const ReportsSwitch = () => {
  const session = useAppSelector((state) => state.session)

  const { orgRoles, groupRoles, canReport, enabledAddOns, isOrgAdmin } = session

  const canViewAnalytics =
    (isOrgAdmin ||
      groupRoles.includes("group-observation_admin") ||
      orgRoles.includes("organization-observation_admin")) &&
    enabledAddOns.includes(FeatureName.Observations)

  const canViewCompliance =
    canReport &&
    (enabledAddOns.includes(FeatureName.Events) ||
      enabledAddOns.includes(FeatureName.OnDemand))

  return (
    <Switch>
      <Route
        exact
        path="/reports/leaderboards"
        component={canReport ? LeaderboardsLayout : Unauthorized}
      />
      <Route
        exact
        path="/reports/progress-tracking"
        component={canViewCompliance ? ComplianceLayout : Unauthorized}
      />
      <Route
        exact
        path="/reports/learner-access"
        component={canReport ? TeacherAccessLayout : Unauthorized}
      />
      <Route
        exact
        path="/reports/analytics"
        component={canViewAnalytics ? AnalyticsLayout : Unauthorized}
      />
    </Switch>
  )
}
