import Loading from "@mobilemind/common/src/components/Loading"
import { Checkbox, FormControlLabel } from "@mui/material"
import classNames from "classnames"
import CourseTooltip from "../../components/CourseTooltip"

import CategorySelect from "@mobilemind/common/src/components/CategorySelect"

import { CategoryTaxonomy } from "@mobilemind/common/src/types/taxonomy/category"
import { Button, Dialog } from "@mui/material"
import { saveCourse } from "actions/courses"
import { Field, Formik, useFormikContext } from "formik"
import { useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { RootState } from "../../store/types"
import "../../styles/modal.scss"
import { CourseFormValues } from "./types"

export type ModalCourseShareFormValues = {
  isCommunity: boolean
  isAgree: boolean
  category: CategoryTaxonomy | null
  needsReplacementCategory: boolean
  credit: boolean
}

type ModalCourseShareProps = {
  open: boolean
  onClose: () => void
}

export const ModalCourseShare = (props: ModalCourseShareProps) => {
  const history = useHistory()

  const { open, onClose } = props

  const dispatch = useAppDispatch()
  const categories = useAppSelector((state: RootState) => state.categories)
  const session = useAppSelector((state: RootState) => state.session)

  const [isSharing, setIsSharing] = useState(false)

  const {
    isSubmitting,
    setSubmitting,
    values: activeCourse,
  } = useFormikContext<CourseFormValues>()

  const initialValues = useMemo<ModalCourseShareFormValues>(() => {
    const needsReplacementCategory = activeCourse.field_category
      ? !!activeCourse.field_category.relationships.field_organization.data
      : false

    return {
      isCommunity: true,
      isAgree: false,
      category: null,
      needsReplacementCategory,
      credit: true,
    }
  }, [activeCourse.field_category])

  const publish = async () => {
    setSubmitting(true)
    await dispatch(saveCourse({ activeCourse, isDraft: false }))
    setSubmitting(false)
    // Close dialog before navigating
    onClose()
    history.push("/create/courses")
  }

  const confirm = async (values: ModalCourseShareFormValues) => {
    setSubmitting(true)
    setIsSharing(true)
    // Save the course
    await dispatch(
      saveCourse({
        activeCourse,
        isDraft: false,
        isCopy: false,
        fromTemplate: false,
        isSharing: false,
        shareSettings: values,
      })
    )
    // Save the template
    const response = await dispatch(
      saveCourse({
        activeCourse,
        isDraft: false,
        isCopy: false,
        fromTemplate: false,
        isSharing: true,
        shareSettings: values,
      })
    )
    setSubmitting(false)
    setIsSharing(false)
    onClose()
    history.push(
      "/create/courses/" + response.attributes.drupal_internal__id + "/template"
    )
    window.location.reload()
  }

  return (
    <Dialog
      className={classNames(
        "modal course-share",
        isSubmitting && "isSubmitting"
      )}
      onClose={onClose}
      open={open}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={() => {}}
        validate={() => {}}
      >
        {({ setFieldValue, values }) => {
          let isShareable =
            values.isAgree &&
            values.isCommunity &&
            (!values.needsReplacementCategory || values.category)

          let shareSettings = activeCourse.shareSettings
            ? activeCourse.shareSettings
            : []

          if (shareSettings.includes("community")) {
            isShareable = false
          }

          return (
            <>
              <h2>
                <div className="icon" />
                Share Course with MobileMind Community
              </h2>
              <div className="inner">
                {values.needsReplacementCategory &&
                  activeCourse.field_category && (
                    <div className="banner">
                      <div className="inner warning">
                        This course is categorized under "
                        {activeCourse.field_category.attributes.name}," which is
                        specific to {session.group.label[0].value}. You'll need
                        to select a non-custom category to share this course.
                        Your course will retain the original category.
                      </div>

                      <div style={{ margin: "20px 0" }}>
                        <CategorySelect
                          hideOrgContent={true}
                          allowedLevels={3}
                          label={"Replacement Category"}
                          currentValue={values.category ?? null}
                          categories={categories}
                          onChange={(category) => {
                            setFieldValue("category", category)
                          }}
                        />
                      </div>
                    </div>
                  )}

                {shareSettings.includes("community") && (
                  <div className="banner">
                    <div className="inner">
                      You've already shared this course with the Community.
                    </div>
                  </div>
                )}

                <p>
                  {(!activeCourse.id || activeCourse.field_draft) && (
                    <>Do you want to share this course with the community? </>
                  )}
                  Other organizations will be able to see{" "}
                  {!activeCourse.id || activeCourse.field_draft ? (
                    <>it</>
                  ) : (
                    <>this course</>
                  )}{" "}
                  on the <strong>Community</strong> tab and will be able to
                  publish it to their learners, or create their own course based
                  on this one.
                </p>

                <CourseTooltip placement="bottom-start" />

                <div style={{ marginTop: 40 }} className={"flexRow"}>
                  <FormControlLabel
                    className="checkbox"
                    label={null}
                    control={
                      <Field
                        as={Checkbox}
                        name="credit"
                        checked={values.credit}
                      />
                    }
                  />
                  Credit {session.group.label[0].value}
                  <span className="helperText">
                    If checked, <strong>{session.group.label[0].value}</strong>{" "}
                    will be credited for contributing the course.
                  </span>
                </div>

                <footer>
                  <div className="tos">
                    <div
                      className={classNames(
                        "flexRow",
                        shareSettings.includes("mobilemind") && "disabled"
                      )}
                    >
                      <FormControlLabel
                        className="checkbox"
                        label={null}
                        control={
                          <Field
                            as={Checkbox}
                            name="isAgree"
                            checked={values.isAgree}
                          />
                        }
                      />{" "}
                      Agree to Terms of Service
                      <span className="helperText">
                        I have read and agree to the MobileMind{" "}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://www.mobilemind.io/mobilemind-terms-of-service"
                        >
                          Terms of Service.
                        </a>
                      </span>
                    </div>
                  </div>

                  {!activeCourse.id || activeCourse.field_draft ? (
                    <Button onClick={publish} className="button small cancel">
                      Just Publish
                    </Button>
                  ) : (
                    <Button onClick={onClose} className="button small cancel">
                      Cancel
                    </Button>
                  )}
                  <Button
                    style={
                      !isShareable
                        ? { pointerEvents: "none", opacity: 0.6 }
                        : {}
                    }
                    onClick={() => confirm(values)}
                    className={classNames("button small")}
                  >
                    {!activeCourse.id || activeCourse.field_draft ? (
                      <>Publish and Share</>
                    ) : (
                      <>Share Course</>
                    )}
                  </Button>
                </footer>
              </div>
              {isSubmitting && (
                <Loading
                  message={isSharing ? "Sharing course…" : "Publishing course…"}
                />
              )}
            </>
          )
        }}
      </Formik>
    </Dialog>
  )
}
