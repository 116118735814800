import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"
import Loading from "@mobilemind/common/src/components/Loading"
import { TabsCapsule } from "@mobilemind/common/src/components/tabsCapsule/TabsCapsule"
import yup from "@mobilemind/common/src/utility/yup"
import { Card, Tab } from "@mui/material"
import { Form, Formik } from "formik"
import { useEffect, useState } from "react"
import "react-quill/dist/quill.snow.css"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { savePartnerSettings } from "../../actions/partners"
import Magnify from "../../img/search.svg"
import { getSinglePartner } from "../../store/reducers/marketplace"
import { RootState } from "../../store/types"
import { PartnerMarketplaceItem } from "../marketplace/PartnerMarketplaceItem"
import { PartnerSingleModal } from "../marketplace/PartnerSingleModal"
import "../marketplace/marketplace.scss"
import { MarketplaceItem } from "../marketplace/types"
import { PartnerApprovalForm } from "./PartnerApprovalForm"
import { PartnerMediaForm } from "./PartnerMediaForm"
import { PartnerOverviewForm } from "./PartnerOverviewForm"
import { PartnerCertificatesForm } from "./PartnerCertificatesForm"

export const PartnerSettingsLayout = () => {
  const [currentTab, setCurrentTab] = useState("overview")

  const partnerInfo = useAppSelector((state: RootState) => {
    return state.session.group
  })

  const [initialValues, setInitialValues] = useState<MarketplaceItem | null>(
    null
  )
  const [isPreviewOpen, setPreviewOpen] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isFetched, setIsFetched] = useState(false)

  const dispatch = useAppDispatch()

  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(
        getSinglePartner({ id: partnerInfo.id[0].value })
      )
      setInitialValues(response.payload)
      setIsFetched(true)
    }
    if (!isFetched) {
      fetchData()
    }
  }, [isFetched, partnerInfo, dispatch])

  // @todo - finish validation schema and enforce
  const validationSchema = yup.object({
    name: yup.string().required(),
    partner_image: yup.string().required(),
    partner_description: yup.string().required(),
    partner_topic_list: yup.string(),
    partner_free_or_paid: yup.string(),
    partner_approval_required: yup.boolean(),
    partner_contact: yup.string(),
    field_signature_font: yup.string(),
    field_signature_name: yup.string(),
    field_signature_title: yup.string(),
  })

  function submitData(values: MarketplaceItem) {
    dispatch(savePartnerSettings(values)).then(() => {
      setIsSaving(false)
    })
  }

  return (
    <>
      {isSaving && <Loading fullPage={true} message="Saving settings..." />}
      <div
        className="page partner-settings"
        style={{ ...styles.container, opacity: isSaving ? 0.2 : 1 }}
      >
        {isFetched && initialValues && initialValues.id ? (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={() => {}}
          >
            {({ values }) => (
              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <PartnerSingleModal
                  isPreview={true}
                  partner={values}
                  open={isPreviewOpen}
                  onClose={() => {
                    setPreviewOpen(false)
                  }}
                />
                <div className="partner-marketplace-preview">
                  <PartnerMarketplaceItem
                    onClick={() => {
                      setPreviewOpen(true)
                    }}
                    containerStyles={{ width: 300 }}
                    index={0}
                    partner={values}
                  />

                  <strong
                    className="preview-tooltip"
                    style={{
                      width: 280,
                      display: "flex",
                      justifyContent: "center",
                      margin: 10,
                      marginTop: 15,
                      fontSize: 12,
                    }}
                  >
                    <img
                      alt="Preview"
                      src={Magnify}
                      style={{ width: 20, marginRight: 8 }}
                    />
                    Preview Marketplace Listing
                  </strong>
                </div>

                <Card style={styles.card}>
                  <h2
                    style={{
                      marginBottom: 20,
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      className="icon gear-color"
                      style={styles.headerIcon}
                    />
                    Partner Settings
                  </h2>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <TabsCapsule
                      value={currentTab}
                      onChange={(event, newTab) => setCurrentTab(newTab)}
                    >
                      <Tab value="overview" label="Overview" />
                      <Tab value="media" label="Media" />
                      <Tab value="approval" label="Approval" />
                      <Tab value="certificates" label="Certificates" />
                    </TabsCapsule>
                  </div>
                  <Form style={styles.form}>
                    {currentTab === "overview" && <PartnerOverviewForm />}
                    {currentTab === "media" && <PartnerMediaForm />}
                    {currentTab === "approval" && <PartnerApprovalForm />}
                    {currentTab === "certificates" && (
                      <PartnerCertificatesForm />
                    )}
                    <div style={{ maxWidth: 140, margin: "0 auto" }}>
                      <ButtonLarge
                        onClick={() => {
                          setIsSaving(true)
                          submitData(values)
                        }}
                      >
                        Save Changes
                      </ButtonLarge>
                    </div>
                  </Form>
                </Card>
              </div>
            )}
          </Formik>
        ) : (
          <Loading fullPage={true} message="Getting your settings..." />
        )}
      </div>
    </>
  )
}

const styles = {
  container: {
    paddingTop: 75,
    maxWidth: 1030,
    display: "flex",
    flexDirection: "column" as "column",
    margin: "0 auto",
  },
  headerIcon: {
    width: 30,
    height: 30,
    marginRight: 10,
  },
  form: {
    display: "flex",
    flexDirection: "column" as "column",
    paddingTop: 20,
  },
  card: {
    padding: 20,
    flex: 1,
    borderRadius: 12,
    marginLeft: 20,
    backgroundColor: "white",
  },
}
