import { Dialog } from "@mui/material"
import "../../styles/modal.scss"
import classNames from "classnames"
import QuillPen from "../../img/quill-pen-color.svg"
type ModalProps = {
  field_signature_title: string
  field_signature_name: string
  field_signature_font: string
  onClose: any
  open: boolean
}

function SignatureFontSelectModal(props: ModalProps) {
  const { onClose, open, field_signature_font, field_signature_name } = props

  const availableFonts = [
    "Edu-QLD-Beginner",
    "Playwrite-AR",
    "Mrs-Saint-Delafield",
    "Italianno",
    "Just-Another-Hand",
    "Ms-Madi",
    "Over-The-Rainbow",
    "Vibur",
    "Qwitcher-Grypen",
    "Grape-Nuts",
  ]

  return (
    <Dialog className="modal signatureSelect" onClose={onClose} open={open}>
      <h2
        style={{
          padding: "0 8px",
          margin: "0px 0",
        }}
      >
        <img
          src={QuillPen}
          alt="Quill Pen"
          style={{ width: 25, marginRight: 10 }}
        />
        Signature Style
      </h2>

      <ul
        style={{
          listStyleType: "none",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {availableFonts.map((font, index) => {
          return (
            <li
              style={{
                width: "calc(50% - 40px)",
                display: "flex",
                marginBottom: 10,
                marginRight: 10,
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                padding: 10,
              }}
              className={classNames(
                "signature-select-option",
                field_signature_font === font && "active"
              )}
              key={index}
              onClick={() => {
                onClose(font)
              }}
            >
              <strong
                className={"signature-text " + font}
                style={{ fontSize: 26 }}
              >
                {field_signature_name ? field_signature_name : "Signature Name"}
              </strong>
            </li>
          )
        })}
      </ul>
    </Dialog>
  )
}

export default SignatureFontSelectModal
