import { useState } from "react"
import { Button, Popover, Menu, MenuItem } from "@mui/material"
import { makeStyles } from "@mui/styles"
import classNames from "classnames"
import qs from "qs"
import { isGroupLevel } from "../functions"

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}))

function NavbarSecondary(props) {
  const {
    type,
    iconClass,
    isTemplate,
    publish,
    canPublish,
    openCopyModal,
    openShareModal,
    openDeleteModal,
    exportLink,
    saveDraft,
    activeItem,
    orgRoles,
    isPartner,
    groupRoles,
  } = props

  const [anchorEl, setAnchorEl] = useState(null)
  const [previewAnchorEl, setPreviewAnchorEl] = useState(null)
  const [exportAnchorEl, setExportAnchorEl] = useState(null)
  const [missingFieldsList, setMissingFieldsList] = useState([])

  let isDraft =
    activeItem &&
    activeItem.fetched &&
    (!activeItem.id || activeItem.field_draft)
  let isDrafter
  if (
    orgRoles &&
    !orgRoles.includes("organization-admin") &&
    !orgRoles.includes("organization-creator") &&
    groupRoles &&
    !groupRoles.includes("group-creator") &&
    !groupRoles.includes("group-admin")
  ) {
    if (
      orgRoles.includes("organization-drafter") ||
      (groupRoles && groupRoles.includes("group-drafter"))
    ) {
      isDrafter = true
    }
  }

  function exportReport(type) {
    const { orgRoles } = props

    let newHref = exportLink
    if (exportLink.includes("combined")) {
      newHref =
        process.env.REACT_APP_API_URL +
        "/api/time-spent-leaderboard/export" +
        "?" +
        exportLink.split("?")[1]
    }
    if (type === "event") {
      if (
        (orgRoles && !orgRoles.includes("organization-admin")) ||
        (orgRoles && !orgRoles.includes("organization-reporter"))
      ) {
        newHref = exportLink.replace("combined", "leaderboard-event-time-spent")
      } else {
        newHref = exportLink.replace(
          "combined",
          "group-leaderboard-event-time-spent"
        )
      }
    }

    if (props.eventId) {
      const { filters, session } = props
      let query = {}

      let groupId = props.session.group.id[0].value
      if (isGroupLevel(session)) {
        query.field_subgroup_value = session.subgroup.label[0].value
      }

      newHref =
        process.env.REACT_APP_API_URL +
        "/api/user-events-report/" +
        props.eventId +
        "/" +
        groupId +
        "?"

      if (props.isConference) {
        if (type === "sessions") {
          newHref =
            process.env.REACT_APP_API_URL +
            "/api/participants-all-conference-sessions-export/" +
            props.eventId +
            "?"
        }
      }

      query.sort_by = filters.sortBy

      if (filters.rsvpStatus !== "any") {
        query.field_rsvp_value = filters.rsvpStatus
      }
      if (filters.attended !== "any") {
        query.field_attended_value = filters.attended
      }
      if (filters.group !== "all") {
        query.field_subgroup_value = filters.group
      }

      newHref += qs.stringify(query)
    }
    let windowParams =
      "left=200,top=200,width=767,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
    window.open(newHref, "", windowParams)
  }

  const open = Boolean(anchorEl)
  const missingDraftFields = props.missingDraftFields
    ? props.missingDraftFields
    : []
  const classes = useStyles()

  const openPreview = (type) => {
    setPreviewAnchorEl(null)
    props.openPreview(type)
  }

  const onHover = (event, missingFields) => {
    setMissingFieldsList(missingFields)
    if (missingFields.length) {
      setAnchorEl(event.currentTarget)
    }
    props.onHover(missingFields)
  }

  const onHoverEnd = () => {
    setAnchorEl(null)
    props.onHover([])
  }

  const startSaveDraft = (event) => {
    if (!missingDraftFields.length) {
      saveDraft()
    } else {
      props.onHover(props.missingDraftFields)
    }
  }

  let previewAction = type !== "Announcement" ? setPreviewAnchorEl : openPreview
  const isReports =
    type === "Leaderboards" || type === "Progress Tracking" || type === "Access"
  const isManage = type === "Categories" || type === "Locations"

  return (
    <>
      {((activeItem && activeItem.fetched) || isReports || isManage) && (
        <div
          style={{ padding: "0 20px" }}
          className={classNames(
            "subnav",
            !canPublish && "no-publish",
            isTemplate && "template"
          )}
        >
          <span className={classNames("title")}>
            <span className={iconClass} />
            <>
              {activeItem && !isTemplate && !isManage && !isReports && (
                <>{activeItem.id ? <>Edit </> : <>New </>}</>
              )}
              {isManage && <>Manage </>}
              {!isTemplate && (!activeItem || !activeItem.sendReminders) && (
                <>{type}</>
              )}
              {isTemplate && (
                <>
                  {props.isOwnCommunityCourse ? (
                    <>Edit Community Course</>
                  ) : (
                    <>View Community Course</>
                  )}
                </>
              )}
            </>
          </span>
          {!exportLink &&
            !props.isQuickLinks &&
            !isManage &&
            type !== "Learner Goal" &&
            type !== "Mandated Training" && (
              <>
                <Button
                  onClick={(event) => previewAction(event.currentTarget)}
                  className="button small"
                  aria-owns={previewAnchorEl ? "preview-menu" : undefined}
                >
                  <span className="icon preview" />
                  Preview
                </Button>

                <Menu
                  id="preview-menu"
                  anchorEl={previewAnchorEl}
                  open={Boolean(previewAnchorEl)}
                  onClose={() => setPreviewAnchorEl(null)}
                >
                  {type === "Course" && (
                    <MenuItem
                      onClick={() => {
                        openPreview("card")
                      }}
                    >
                      Course Card
                    </MenuItem>
                  )}
                  {type === "Course" && (
                    <MenuItem
                      onClick={() => {
                        openPreview("extension")
                      }}
                    >
                      Challenge Window
                    </MenuItem>
                  )}
                  {type === "Badge" && (
                    <MenuItem
                      onClick={() => {
                        openPreview("badge")
                      }}
                    >
                      Badge
                    </MenuItem>
                  )}
                  {type === "Badge" && (
                    <MenuItem
                      onClick={() => {
                        openPreview("certificate")
                      }}
                    >
                      Certificate
                    </MenuItem>
                  )}
                  {type === "Learning Path" && (
                    <MenuItem
                      onClick={() => {
                        openPreview("learning-path")
                      }}
                    >
                      Learning Path
                    </MenuItem>
                  )}
                  {type === "Learning Path" && (
                    <MenuItem
                      onClick={() => {
                        openPreview("certificate")
                      }}
                    >
                      Certificate
                    </MenuItem>
                  )}
                </Menu>
              </>
            )}

          {((isDraft && activeItem && activeItem.id && !isDrafter) ||
            props.canDelete) && (
            <Button onClick={openDeleteModal} className="button small">
              <span className="icon trash-white" />
              Delete
            </Button>
          )}

          {((isDraft && !window.location.href.includes("/view")) ||
            !activeItem.id) &&
            !props.isQuickLinks &&
            !isManage &&
            !isReports && (
              <Button
                onMouseEnter={(event) => {
                  onHover(event, missingDraftFields)
                }}
                onMouseLeave={() => {
                  onHoverEnd()
                }}
                onClick={() => {
                  !missingDraftFields.length && startSaveDraft("draft")
                }}
                style={{ opacity: missingDraftFields.length ? 0.5 : 1 }}
                className={classNames("button saveDraftButton small")}
              >
                <span className="icon draft" /> Save Draft
              </Button>
            )}

          {exportLink &&
            !exportLink.includes("combined") &&
            !exportLink.includes("time-spent") &&
            !exportLink.includes("rubric") &&
            !exportLink.includes("mt_compliance") &&
            !props.hideExportLink && (
              <>
                {!props.isConference || isGroupLevel(props.session) ? (
                  <>
                    {!isPartner && (
                      <Button
                        disabled={props.disableExportLink}
                        onClick={() => {
                          exportLink !== "combined" && exportReport()
                        }}
                        className={classNames("button small")}
                        style={
                          exportLink === "combined" || props.disableExportLink
                            ? { opacity: 0.5 }
                            : { opacity: 1 }
                        }
                      >
                        <span className="icon export" /> Export
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    <Button
                      onClick={(event) =>
                        setExportAnchorEl(event.currentTarget)
                      }
                      className="button small"
                      aria-owns={
                        exportAnchorEl ? "conference-export-menu" : undefined
                      }
                    >
                      <span className="icon export" />
                      Export
                    </Button>

                    <Menu
                      id="conference-export-menu"
                      anchorEl={exportAnchorEl}
                      open={Boolean(exportAnchorEl)}
                      onClose={() => setExportAnchorEl(null)}
                    >
                      <MenuItem
                        onClick={() => {
                          exportReport("conference")
                        }}
                      >
                        Conference
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          exportReport("sessions")
                        }}
                      >
                        All Sessions
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </>
            )}

          {exportLink && exportLink.includes("time-spent") && (
            <a
              style={{ pointerEvents: "all", opacity: 1 }}
              href={
                process.env.REACT_APP_API_URL +
                "/api/time-spent-leaderboard/export" +
                "?" +
                exportLink.split("?")[1]
              }
            >
              <Button className={classNames("button small")}>
                <span className="icon export" /> Export Time Spent
              </Button>
            </a>
          )}

          {exportLink &&
            (exportLink.includes("compliance_rubric") ||
              exportLink.includes("mt_compliance")) && (
              <a style={{ pointerEvents: "all", opacity: 1 }} href={exportLink}>
                <Button className={classNames("button small")}>
                  <span className="icon export" /> Export
                </Button>
              </a>
            )}

          {type === "Course" &&
            !isDraft &&
            !isTemplate &&
            props.canShare &&
            !props.hideShare && (
              <Button
                onClick={openShareModal}
                className="button small"
                aria-owns={previewAnchorEl ? "preview-menu" : undefined}
              >
                <span className="icon share" />
                Share
              </Button>
            )}

          {type === "Course" &&
            activeItem &&
            activeItem.id &&
            !activeItem.field_draft &&
            !isTemplate && (
              <Button onClick={openCopyModal} className="button small">
                <span className="icon copy" />
                Copy
              </Button>
            )}

          {!exportLink &&
          !isDrafter &&
          canPublish &&
          (!isTemplate || props.isOwnCommunityCourse) ? (
            <Button onClick={publish} className={classNames("button small")}>
              {activeItem.archived ||
              (props.isQuickLinks && !activeItem.isHidden) ? (
                <>
                  {props.isQuickLinks ? (
                    <>
                      <span className="icon hide" /> Hide
                    </>
                  ) : (
                    <>
                      {props.isOwnCommunityCourse ? (
                        <>
                          <span className="icon trash-white" /> Remove
                        </>
                      ) : (
                        <>
                          <span className="icon archive" /> Archive
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {isManage ? (
                    <>
                      <span className="icon save" /> Save Changes
                    </>
                  ) : (
                    <>
                      {activeItem.id && !activeItem.field_draft ? (
                        <>
                          <span className="icon save" /> Save
                        </>
                      ) : (
                        <>
                          <span className="icon rocket" /> Publish
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </Button>
          ) : (
            <></>
          )}

          {!canPublish &&
            !isDrafter &&
            !exportLink &&
            !isTemplate &&
            !activeItem.field_archive && (
              <Button
                aria-owns={open ? "publish-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={(event) => {
                  onHover(event, props.missingFields)
                }}
                onMouseLeave={() => {
                  onHoverEnd()
                }}
                className="button small"
                style={{ opacity: 0.5 }}
              >
                {isManage ? (
                  <>
                    <span className="icon save" /> Save Changes
                  </>
                ) : (
                  <>
                    <span className="icon rocket" /> Publish
                  </>
                )}
              </Button>
            )}

          {isTemplate && !props.isOwnCommunityCourse && (
            <Button onClick={openCopyModal} className="button small">
              <span className="icon copy" />
              Use This Course
            </Button>
          )}

          {props.missingFields && props.missingFields.length > 0 && (
            <Popover
              id="publish-popover"
              className={classes.popover}
              classes={{
                paper: classes.paper,
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={() => setAnchorEl(null)}
              disableRestoreFocus
            >
              <p>Uh oh, you're missing the following:</p>
              <ul>
                {missingFieldsList.map((field, index) => {
                  return <li key={index}>{field}</li>
                })}
              </ul>
            </Popover>
          )}
        </div>
      )}
    </>
  )
}

export default NavbarSecondary
