/* eslint-disable */
import { useState, useEffect } from "react"

import { useDispatch } from "react-redux"
import Loading from "../../../components/Loading"
import classNames from "classnames"
import SocialShare from "./SocialShare"
import he from "he"

import "../styles/certificate.scss"

import { createLearningPathCertificate } from "../../../actions/user"
import { LearningPath } from "../../../types/explore"
import { BackpackUserData } from "../../../types/backpack"
import { MobileMindUser } from "../../../types/session"
import { LearningPathCertificatePreview } from "../../LearningPathCertificatePreview"

type CertProps = {
  isSharing: boolean
  learningPath: LearningPath
  userData?: BackpackUserData
  isSharePrint?: boolean
  user: MobileMindUser
  session: any
}

function LearningPathCertificate(props: CertProps) {
  const { learningPath, user, userData, isSharing, isSharePrint, session } =
    props

  const [certificateGenerated, setCertificateGenerated] = useState(false)
  const [newCertId, setNewCertId] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (isSharing) {
      if (learningPath.field_user_lp_cert_uuid) {
        setCertificateGenerated(true)
        setNewCertId(learningPath.field_user_lp_cert_uuid)
      } else if (!newCertId) {
        createCertificate()
      }
    }
  }, [learningPath?.field_user_lp_cert_uuid, newCertId, isSharing])

  const createCertificate = async () => {
    const newCert = await dispatch(
      // @ts-ignore
      createLearningPathCertificate(learningPath)
    )

    setTimeout(() => {
      // eslint-disable-next-line
      // @ts-ignore
      setNewCertId(newCert.data.id)
      setCertificateGenerated(true)
    }, 1000)
  }

  const shareUrl =
    process.env.REACT_APP_TEACHER_URL +
    "/certificate-share/learning-path/" +
    newCertId

  let teacherName = ""
  if (userData) {
    teacherName =
      he.decode(userData.field_first_name_value) +
      " " +
      he.decode(userData.field_last_name_value)
  }
  if (user) {
    teacherName =
      user.attributes.field_first_name + " " + user.attributes.field_last_name
  }
  let partnerSignatureInfo
  if (learningPath.partner_signature_name) {
    partnerSignatureInfo = {
      partner_signature_font: learningPath.partner_signature_font,
      partner_signature_name: learningPath.partner_signature_name,
      partner_signature_title: learningPath.partner_signature_title,
    }
  }

  return (
    <div
      id="certificateWrapper"
      className={classNames(
        "certificateWrapper",
        window.location.href.includes("certificate/learning-path") && "print"
      )}
    >
      <LearningPathCertificatePreview
        completedDate={learningPath.field_user_lp_updated ?? ""}
        userData={userData}
        user={user}
        isSharePrint={isSharePrint}
        pathName={he.decode(learningPath.name)}
        imageSrc={learningPath.field_lp_image_uri}
        isOrgLearningPath={Boolean(learningPath.field_organization_target_id)}
        partnerImage={process.env.REACT_APP_API_URL + learningPath.partner_logo}
        partnerSignatureInfo={partnerSignatureInfo}
        pathCategories={
          learningPath.field_ulp_time_spent?.map((cat: any) => {
            return {
              id: cat.field_parent_id,
              imageSrc:
                process.env.REACT_APP_API_URL + cat.field_category_image,
              name: cat.field_category_label,
              timeSpent: Number(cat.field_time_spent),
            }
          }) ?? []
        }
        teacherName={teacherName}
        estimatedTime={Number(learningPath.field_user_lp_total_time_spent)}
        group={session?.group}
        certificateSettings={{
          categories:
            learningPath.field_enabled_certificate_values?.includes(
              "categories"
            ),
          orgLogo:
            learningPath.field_enabled_certificate_values?.includes("org_logo"),
          pdCredit:
            learningPath.field_enabled_certificate_values?.includes(
              "pd_credit"
            ),
          signature:
            learningPath.field_enabled_certificate_values?.includes(
              "signature"
            ),
        }}
      />

      {isSharing && (
        <div className="shareLink">
          {certificateGenerated ? (
            <>
              <strong>Copy the link below to share your certificate!</strong>
              <SocialShare shareUrl={shareUrl} learningPath={learningPath} />
            </>
          ) : (
            <Loading message={"Preparing certificate..."} />
          )}
        </div>
      )}
    </div>
  )
}

export default LearningPathCertificate
