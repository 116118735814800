import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"
import moment from "moment"
import parseDataUrl from "parse-data-url"
import { isArrayIdentical } from "../functions"
import { getRooms } from "../features/events/eventSingle/activeEventSlice"
import { getRoomsForConferenceLocation } from "../features/events/conference/activeConferenceSlice"
import { getOrgSuccessChecklist } from "../store/reducers/session"
import he from "he"

export function saveEventBase(args) {
  const {
    isDraft,
    isCopy,
    calendarEvent,
    isArchive,
    isConference,
    isSession,
    isObservation,
    isQuickCreate,
  } = args

  return async (dispatch, getState) => {
    const { session, locations, activeEvent, activeConference } = getState()

    dispatch(getOrgSuccessChecklist())

    try {
      // Copying setup
      if (isSession && (activeEvent.id || isDraft) && isCopy) {
        let fetchedEvent = await fetchWrapper.get(
          "/api/mobilemind_event_entity/conference_event/" + activeEvent.id
        )
        if (fetchedEvent.ok) {
          fetchedEvent = await fetchedEvent.json()
          fetchedEvent.data.id = "new-" + activeEvent.id
          fetchedEvent.data.attributes.field_draft = true
          fetchedEvent.data.attributes.name =
            "Copy of " + fetchedEvent.data.attributes.name
        }

        if (isSession) {
          dispatch({
            type: "activeConferenceSlice/addSavedEvent",
            payload: {
              event: fetchedEvent.data,
              locations,
              image: activeEvent.eventImage,
            },
          })
          dispatch({
            type: "activeConferenceSlice/updateConferenceField",
            payload: {
              field: "isCopyingEvent",
              value: false,
            },
          })
        }
      }

      let event = isConference ? activeConference : activeEvent

      // Only show the saving/loading indicator if we're not viewing the confirmation modal
      if (event.id) {
        dispatch({
          type: "activeEventSlice/setEventSaving",
          payload: true,
        })
      }

      if (isArchive) {
        dispatch({
          type: "calendarSlice/archiveEvent",
          payload: {
            event,
          },
        })
      }

      let prefix = isCopy ? "Copy of " : ""
      let addOnName = prefix + event.name.substring(0, 25) + " AddOn"
      let pdMinutes = event.pdCredit
        ? Number(event.pdCredit.hours * 60) + Number(event.pdCredit.minutes)
        : 0

      let allRoles = session.groupRoles.concat(session.orgRoles).join(",")

      const isOrgLevel =
        event.isOrgLevel ||
        allRoles.includes("organization-admin") ||
        allRoles.includes("organization-scheduler")
      const groupId =
        session.subgroup &&
        session.subgroup.uuid &&
        session.subgroup.uuid[0].value

      let addOnReference = isQuickCreate && isSession ? activeConference : event

      // Determine the _bundle
      let type = "mobilemind_event_entity--event_base"
      let bundle = "event_base"
      if (isConference) {
        type = "mobilemind_event_entity--conference"
        bundle = "conference"
      } else if (isSession || window.location.pathname.includes("session")) {
        type = "mobilemind_event_entity--conference_event"
        bundle = "conference_event"
      } else if (isObservation) {
        type = "mobilemind_event_entity--observation"
        bundle = "observation"
      }

      if (isConference) {
        if (
          event.originalAwardPD === "conference" &&
          event.awardPD === "sessions"
        ) {
          pdMinutes = 0
        }
      }

      let requestProps = {
        session,
        isOrgLevel,
        isCopy,
        isConference,
        isObservation,
        isSession,
        isQuickCreate,
        activeConference,
        prefix,
        bundle,
        type,
        isDraft,
        pdMinutes,
        isArchive,
        startDate: moment(event.startDate).format(),
        endDate: moment(event.endDate).format(),
        groupId,
        event,
        addOnReference,
        calendarEvent,
        addOnName,
        activeEvent,
      }

      let eventId = event.id

      const { eventBody, createdTags } = await generateEventRequestBody(
        requestProps
      )

      createdTags.forEach((tag) => {
        dispatch({
          type: "tags/addTag",
          payload: tag,
        })
      })

      let response
      // First post or patch the event
      if (eventId && !isCopy) {
        response = await fetchWrapper.patch(
          "/api/mobilemind_event_entity/" + bundle + "/" + eventId,
          session.token,
          JSON.stringify(eventBody)
        )
      } else {
        response = await fetchWrapper.post(
          "/api/mobilemind_event_entity/" + bundle,
          session.token,
          JSON.stringify(eventBody)
        )
      }

      if (response.ok) {
        let data = await response.json()

        if (isObservation && !eventId) {
          // If it's an observation we'll create a user event for the observee
          // If it's a newly created event
          const observationUser = event.attendees[0]

          const body = {
            data: {
              type: "mobile_mind_user_event--observation",
              attributes: {
                name:
                  "User: " +
                  observationUser.uid +
                  " / Observation: " +
                  data.data.attributes.drupal_internal__id,
              },
              relationships: {
                field_event: {
                  data: {
                    id: data.data.id,
                    type: "mobilemind_event_entity--observation",
                  },
                },
                field_user: {
                  data: {
                    id: observationUser.uuid ?? observationUser.id,
                    type: "user--user",
                  },
                },
              },
            },
          }

          await fetchWrapper.post(
            "/api/mobile_mind_user_event/observation",
            session.token,
            JSON.stringify(body)
          )
        }

        return data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function saveEventAddOn(args) {
  return async (dispatch, getState) => {
    const {
      forceParticipantsChanged,
      eventBase,
      eventRef,
      isConference,
      isObservation,
      isCopy,
      isSession,
      isQuickCreate,
    } = args
    const { session, activeEvent, activeConference } = getState()

    let event = isConference ? activeConference : activeEvent
    let addOnReference =
      isConference || (isQuickCreate && isSession) ? activeConference : event

    let prefix = isCopy ? "Copy of " : ""
    let addOnName = prefix + event.name.substring(0, 25) + " AddOn"
    let pdMinutes = event.pdCredit
      ? Number(event.pdCredit.hours * 60) + Number(event.pdCredit.minutes)
      : 0

    let allRoles = session.groupRoles.concat(session.orgRoles).join(",")
    const isOrgLevel =
      event.isOrgLevel ||
      allRoles.includes("organization-admin") ||
      allRoles.includes("organization-scheduler")
    const groupId =
      session.subgroup &&
      session.subgroup.uuid &&
      session.subgroup.uuid[0].value

    let requestProps = {
      forceParticipantsChanged,
      session,
      isOrgLevel,
      addOnReference,
      eventBase,
      eventRef,
      isObservation,
      isCopy,
      pdMinutes,
      groupId,
      event,
      addOnName,
      activeEvent,
    }

    let addOnBody = generateAddOnRequestBody(requestProps)

    // Determine whether to post or patch
    let addOnResponse =
      event.addOnId && !isCopy
        ? await fetchWrapper.patch(
            "/api/mobilemind_event_addon/event_addon_base/" + event.addOnId,
            session.token,
            JSON.stringify(addOnBody)
          )
        : await fetchWrapper.post(
            "/api/mobilemind_event_addon/event_addon_base",
            session.token,
            JSON.stringify(addOnBody)
          )

    if (addOnResponse.ok) {
      let addOn = await addOnResponse.json()
      return addOn
    }
  }
}

export function attachEventAddOn(args) {
  return async (dispatch, getState) => {
    const {
      addOn,
      eventBase,
      eventRef,
      isCopy,
      isConference,
      isObservation,
      isSession,
    } = args
    const { session, locations, activeEvent, activeConference } = getState()

    let event = isConference ? activeConference : activeEvent

    let bundle = isConference ? "conference" : "event_base"
    if (isSession) {
      bundle = "conference_event"
    }
    if (isObservation) {
      bundle = "observation"
    }
    const { eventImage } = eventRef ?? event

    try {
      // Next, we need to stick the addon ID back on the event
      let patchedEvent = {
        data: {
          id: eventBase.data.id,
          type: eventBase.data.type,
          relationships: {
            field_addon: {
              data: {
                type: "mobilemind_event_addon--event_addon_base",
                id: addOn.data.id,
              },
            },
          },
        },
      }

      let response = await fetchWrapper.patch(
        "/api/mobilemind_event_entity/" + bundle + "/" + eventBase.data.id,
        session.token,
        JSON.stringify(patchedEvent)
      )

      if (response.ok) {
        let data = await response.json()

        // Start to get the state in order
        let payload = { id: data.data.id, addOnId: addOn.data.id }
        // Update ids for event and addon
        if (!data.data.attributes.field_draft) {
          // Generate emails or push notifications if we need to
          if (
            (event.sendPublishEmail || event.pushNotifications) &&
            !isSession
          ) {
            await fetchWrapper.get(
              "/api/mobilemind_event/event_publish_notify/" +
                data.data.attributes.drupal_internal__id
            )
          }
        }

        let logId
        if (addOn.data.attributes.field_attendance_method === "join_code") {
          logId = addOn.data.relationships.field_attendance_log.data.id

          let checkInStart = event.checkInStart
            ? moment(
                event.checkInStart.replace('"', "").replace('"', "")
              ).format()
            : moment().format()
          let checkInEnd = event.checkInEnd
            ? moment(
                event.checkInEnd.replace('"', "").replace('"', "")
              ).format()
            : moment().format()

          if (checkInStart === checkInEnd) {
            checkInEnd = event.endDate
          }

          let logBody = {
            data: {
              type: "mm_attendance_log--attendance_log_base",
              id: logId,
              attributes: {
                field_check_in_period: {
                  value: checkInStart,
                  end_value: checkInEnd,
                },
              },
            },
          }

          // We'll also update the attendance log if they set a join code to set the check in date range
          const logURL = "/api/mm_attendance_log/attendance_log_base/" + logId
          fetchWrapper.patch(logURL, session.token, JSON.stringify(logBody))
        }

        if (!isSession) {
          dispatch({
            type: "calendarSlice/updateSavedEvent",
            payload: {
              event: data.data,
              locations,
              image: eventImage,
            },
          })
        }

        // We need to handle adding the event to a conference
        if (isSession) {
          dispatch({
            type: "activeConferenceSlice/addSavedEvent",
            payload: {
              event: data.data,
              locations,
              image: eventImage,
              deleteNew: true,
            },
          })

          let conferenceEvents = activeConference.conferenceEvents.map(
            (event) => {
              return {
                type: "mobilemind_event_entity--conference_event",
                id: event.event_uuid,
              }
            }
          )

          if (!conferenceEvents.find((event) => event.id === data.data.id)) {
            conferenceEvents.push({
              type: "mobilemind_event_entity--conference_event",
              id: data.data.id,
            })

            let updatedConference = {
              data: {
                id: activeConference.id,
                type: "mobilemind_event_entity--conference",
                relationships: {
                  field_event: {
                    data: conferenceEvents,
                  },
                },
              },
            }

            await fetchWrapper.patch(
              "/api/mobilemind_event_entity/conference/" + activeConference.id,
              session.token,
              JSON.stringify(updatedConference)
            )
          }
        }

        dispatch({
          type: isConference
            ? "activeConferenceSlice/addSavedIds"
            : "activeEventSlice/addSavedIds",
          payload,
        })

        // If we've just preemptively saved a draft, get our IDs back to the active event
        // in case we need to delete it after closing the confirmation modal
        if (!event.id) {
          dispatch({
            type: "activeEventSlice/draftSaved",
            payload: {
              event: data.data,
              addOn,
              logId,
            },
          })
        }

        if (eventImage && eventImage.image && !eventImage.image.id) {
          let options = {
            credentials: "include",
            method: "POST",
            headers: new Headers({
              Accept: "application/vnd.api+json",
              "Content-Type": "application/octet-stream",
              "X-CSRF-Token": session.token,
              "Content-Disposition":
                'file; filename="event-image-' +
                he.encode(event.eventImage.filename) +
                '"',
            }),
            body: parseDataUrl(event.eventImage.image).toBuffer(),
          }

          await fetch(
            process.env.REACT_APP_API_URL +
              "/api/mobilemind_event_entity/event_base/" +
              data.data.id +
              "/field_event_image",
            options
          )
        }

        if (eventImage && eventImage.file && isCopy) {
          let imagePatch = {
            data: {
              id: data.data.id,
              type: eventBase.data.type,
              relationships: {
                field_event_image: {
                  data: {
                    type: "file--image",
                    id: eventImage.file.id,
                  },
                },
              },
            },
          }

          await fetchWrapper.patch(
            "/api/mobilemind_event_entity/event_base/" + data.data.id,
            session.token,
            JSON.stringify(imagePatch)
          )

          dispatch({
            type: "activeConferenceSlice/addSavedEvent",
            payload: {
              event: data.data,
              locations,
              image: eventImage,
              deleteNew: true,
            },
          })
        }
        return data
      } else {
        return true
      }
    } catch (err) {
      console.log(err)
    }
  }
}

async function generateEventRequestBody(args) {
  const {
    session,
    isCopy,
    isConference,
    isObservation,
    isSession,
    isQuickCreate,
    startDate,
    endDate,
    activeConference,
    prefix,
    isDraft,
    isArchive,
    type,
    event,
    calendarEvent,
  } = args

  let eventReference = isQuickCreate && isSession ? activeConference : event

  let field_notify

  if (eventReference.sendPublishEmail && !eventReference.pushNotifications) {
    field_notify = "email"
  } else if (
    eventReference.pushNotifications &&
    !eventReference.sendPublishEmail
  ) {
    field_notify = "push"
  } else {
    field_notify = "all"
  }

  const field_enabled_certificate_values = []
  if (eventReference.certificateSettings.pdCredit) {
    field_enabled_certificate_values.push("pd_credit")
  }
  if (eventReference.certificateSettings.orgLogo) {
    field_enabled_certificate_values.push("org_logo")
  }
  if (eventReference.certificateSettings.signature) {
    field_enabled_certificate_values.push("signature")
  }
  let attributes = {
    name: prefix + event.name,
    field_description: event.description.replaceAll("<p><br></p>", ""),
    field_type: event.type,
    field_draft: isDraft ? true : false,
    field_archive: isArchive ? true : false,
    field_editor_lock_time: null,

    field_notify,
    field_meeting_link: {
      uri: event.meetingLink,
      title: "",
      options: [],
    },
    field_event_notes: event.notes,
    field_all_day: event.allDay,
  }

  if (!isObservation) {
    attributes.field_helpful_links = event.helpfulLinks
    attributes.field_contact_info = event.supportContact
    attributes.field_capacity = Number(event.eventMax)
  }
  if (!isObservation && !isSession) {
    attributes.field_enabled_certificate_values =
      field_enabled_certificate_values
  }

  let eventBody = {
    data: {
      type: type,
      attributes,
      relationships: {
        field_current_editor: {
          data: null,
        },
        user_id: {
          data: {
            type: "user--user",
            id: session.user.id,
          },
        },
        field_attachment: {
          data: [],
        },
        field_event_category: {
          data: [],
        },
        field_organization: {
          data: {
            type: "group--organization",
            id: session.group.uuid[0].value,
          },
        },
      },
    },
  }

  let field_event_date_time = [{}]

  if (isConference) {
    eventBody.data.attributes.field_rsvp_to_view_sessions =
      eventReference.requireRSVP
  }

  field_event_date_time[0].value = startDate
  field_event_date_time[0].end_value = endDate

  if (isSession) {
    if (!moment().isDST() && moment(startDate).isDST()) {
      field_event_date_time[0].value = moment(startDate).format()
      field_event_date_time[0].end_value = moment(endDate).format()
    }
  }

  eventBody.data.attributes.field_event_date_time = field_event_date_time

  if (!eventReference.id) {
    eventBody.data.attributes.field_event_date_time = [
      {
        value: startDate,
        end_value: endDate,
      },
    ]
  }

  if (isConference) {
    eventBody.data.attributes.field_award_credit_for_conferenc =
      activeConference.awardPD === "conference" ? true : false
  }

  // Observation rubric
  if (
    isObservation &&
    (eventReference.observationRubricUUID || eventReference.observationRubric)
  ) {
    eventBody.data.relationships.field_rubric = {
      data: {
        type: "mobilemind_rubric--mobilemind_rubric",
        id:
          eventReference.observationRubricUUID ??
          eventReference.observationRubric,
      },
    }
  }
  // Observation Type
  if (
    isObservation &&
    eventReference.observationType &&
    eventReference.observationType !== "coaching"
  ) {
    eventBody.data.relationships.field_observation_type = {
      data: {
        type: "taxonomy_term--observation_type",
        id: eventReference.observationType,
      },
    }
  }

  if (isSession) {
    eventBody.data.attributes.field_hex_color = eventReference.color
    eventBody.data.relationships.field_parent_conference = {
      data: {
        type: "mobilemind_event_entity--conference",
        id: activeConference.id,
      },
    }
  }

  eventBody.data.relationships.field_tags = {
    data: [],
  }

  let i = 0
  let createdTags = []

  while (i < eventReference.tags.length) {
    if (eventReference.tags[i].uuid) {
      eventBody.data.relationships.field_tags.data.push({
        type: "taxonomy_term--tags",
        id: eventReference.tags[i].uuid,
      })
    } else {
      let newTag = {
        data: {
          type: "taxonomy_term--category",
          attributes: {
            name: eventReference.tags[i].text,
          },
          // We'll set the org OR the partner depending on the user
          relationships: {},
        },
      }

      if (session.isPartner) {
        newTag.data.relationships.field_partner = {
          data: {
            type: "group--partner",
            id: session.group.uuid[0].value,
          },
        }
      } else {
        newTag.data.relationships.field_organization = {
          data: {
            type: "group--organization",
            id: session.group.uuid[0].value,
          },
        }
      }

      let tagRequest = await fetchWrapper.post(
        "/api/taxonomy_term/tags",
        session.token,
        JSON.stringify(newTag)
      )
      if (tagRequest.ok) {
        let tagData = await tagRequest.json()

        // Stick the created tag in here so we can add it to the state later
        createdTags.push(tagData.data)

        // Add it to the relationships object
        eventBody.data.relationships.field_tags.data.push({
          type: "taxonomy_term--tags",
          id: tagData.data.id,
        })
      }
    }
    i++
  }
  if (!event.isOrgLevel) {
    // Get group id from conference after inserting from fetch, i guess
    if (isSession && activeConference.groupData) {
      eventBody.data.relationships.field_group = {
        data: {
          type: "group--group",
          id: activeConference.groupData.id,
        },
      }
    }
    if (event.groupData) {
      eventBody.data.relationships.field_group = {
        data: {
          type: "group--group",
          id: event.groupData.id,
        },
      }
    }
    if (
      session.subgroup &&
      !session.orgRoles.join(",").includes("admin") &&
      !session.orgRoles.join(",").includes("scheduler")
    ) {
      eventBody.data.relationships.field_group = {
        data: {
          type: "group--group",
          id: session.subgroup.uuid[0].value,
        },
      }
    }
  }

  // Clear banner image
  if (!event.eventImage.file) {
    eventBody.data.relationships.field_event_image = {
      data: null,
    }
  }

  if (!calendarEvent) {
    if (!isSession && type !== "mobilemind_event_entity--conference_event") {
      if (!event.sendReminders.includes("none")) {
        eventBody.data.attributes.field_event_reminder = event.sendReminders
      } else {
        eventBody.data.attributes.field_event_reminder = []
      }
      if (!event.sendRegistrationReminders.includes("none")) {
        eventBody.data.attributes.field_reminder =
          event.sendRegistrationReminders
      } else {
        eventBody.data.attributes.field_reminder = []
      }
    }

    if (event.registrationWindow !== "unlimited") {
      eventBody.data.attributes.field_registration_start =
        event.registrationStarts
          ? moment(event.registrationStarts).format()
          : null
      eventBody.data.attributes.field_registration_end = event.registrationEnds
        ? moment(event.registrationEnds).format()
        : null
    } else {
      eventBody.data.attributes.field_registration_start = null
      eventBody.data.attributes.field_registration_end = moment(
        eventBody.data.attributes.field_event_date_time[0].value
      ).format()
    }

    if (event.attachments) {
      event.attachments.forEach((file) => {
        if (file.file.includes("/sites")) {
          if (
            file.extension === "pdf" ||
            file.extension === "doc" ||
            file.extension === "csv" ||
            file.extension === "docx" ||
            file.extension === "ppt" ||
            file.extension === "pptx" ||
            file.extension === "xls" ||
            file.extension === "xlsx" ||
            file.extension === "txt"
          ) {
            eventBody.data.relationships.field_attachment.data.push({
              type: "file--document",
              id: file.id,
            })
          }
          if (
            file.extension === "png" ||
            file.extension === "jpg" ||
            file.extension === "jpeg"
          ) {
            eventBody.data.relationships.field_attachment.data.push({
              type: "file--image",
              id: file.id,
            })
          }
        }
      })
    }
  }

  if (event.category && event.category !== "None") {
    eventBody.data.relationships.field_event_category.data = {
      type: "taxonomy_term--event_category",
      id: event.category.id,
    }
  } else {
    eventBody.data.relationships.field_event_category.data = []
  }

  let eventId = event.id
  if (eventId && !isCopy) {
    eventBody.data.id = eventId
  } else {
    eventBody.data.relationships.user_id = {
      data: {
        type: "user--user",
        id: session.user.id,
      },
    }
  }

  if (eventReference.currentLocation) {
    eventBody.data.relationships.field_location = {
      data: {
        type: "mobile_mind_location--location_base",
        id: eventReference.currentLocation.id,
      },
    }
  }
  // If it's a conference with multiple locations, deal with that
  else if (eventReference.locations?.length) {
    eventBody.data.relationships.field_location = {
      data: eventReference.locations.map((location) => {
        return {
          type: "mobile_mind_location--location_base",
          id: location.id,
        }
      }),
    }
  }

  if (event.eventRoom && event.type !== "Virtual Live") {
    let roomId = event.eventRoom.id ? event.eventRoom.id : event.eventRoom
    if (
      roomId &&
      roomId !== "none" &&
      roomId !== "missing" &&
      type !== "mobilemind_event_entity--conference"
    ) {
      eventBody.data.relationships.field_room = {
        data: {
          type: "mobile_mind_room--room_base",
          id: roomId,
        },
      }
    }
  }
  if (
    !event.eventRoom ||
    event.type === "Virtual Live" ||
    type === "mobilemind_event_entity--conference"
  ) {
    eventBody.data.relationships.field_room = {
      data: null,
    }
  }

  return { eventBody, createdTags }
}

function generateAddOnRequestBody(args) {
  const {
    forceParticipantsChanged,
    session,
    isOrgLevel,
    isCopy,
    pdMinutes,
    eventBase,
    isObservation,
    eventRef,
    event,
    addOnReference,
    addOnName,
    activeEvent,
  } = args

  // Start constructing our Addon body
  const { attendeeMethod } = eventRef ?? event
  const eventReference = eventRef ?? event

  let addOnBody = {
    data: {
      type: "mobilemind_event_addon--event_addon_base",
      attributes: {
        name: addOnName,
        field_credit: pdMinutes,
        field_attendance_method: addOnReference.attendanceMethod,
        field_participants_type:
          attendeeMethod !== "unselected" && !isObservation
            ? attendeeMethod
            : null,
        field_participants_changed: false,
      },
      relationships: {
        field_event: {
          data: {
            type: eventBase?.data.type,
            id: eventBase?.data.id,
          },
        },
      },
    },
  }

  // Check on feedback form method and set the right field
  if (
    eventReference.feedbackMethod === "custom" &&
    eventReference.feedbackFormId
  ) {
    addOnBody.data.relationships.field_feedback_form = {
      data: {
        type: "mm_form--event_feedback",
        id: eventReference.feedbackFormId,
      },
    }
    addOnBody.data.attributes.field_feedback_form_url = null
    addOnBody.data.attributes.field_feedback_required_for_cred =
      eventReference.feedbackRequired
    addOnBody.data.attributes.field_anonymous_feedback =
      eventReference.feedbackIsAnonymous
  } else {
    addOnBody.data.attributes.field_feedback_form_url = event.feedbackURL
    addOnBody.data.attributes.field_feedback_required_for_cred = false
    addOnBody.data.relationships.field_feedback_form = { data: null }
    addOnBody.data.attributes.field_anonymous_feedback = false
  }

  // Check to see if participants have changed
  let allParticipants = eventReference.attendees.concat(
    activeEvent.subGroups,
    activeEvent.jobTitles
  )
  const hasSameParticipants = isArrayIdentical(
    activeEvent.originalParticipants,
    allParticipants
  )
  const hasSameAttendeeMethod =
    eventReference.attendeeMethod === activeEvent.originalAttendeeMethod

  if (
    (eventRef?.addOnId && (!hasSameParticipants || !hasSameAttendeeMethod)) ||
    forceParticipantsChanged
  ) {
    addOnBody.data.attributes.field_participants_changed = true
  }

  if (event.difficulty !== "N/A") {
    addOnBody.data.attributes.field_level = event.difficulty
  }

  // Add in prereq courses
  addOnBody.data.relationships.field_prereq_courses = { data: [] }
  eventReference.preRequisiteCourses &&
    eventReference.preRequisiteCourses.forEach((course) => {
      addOnBody.data.relationships.field_prereq_courses.data.push({
        type: "course_entity--course_entity",
        id: course.id,
      })
    })

  // And any replacement courses
  addOnBody.data.relationships.field_replacement_courses = { data: [] }
  eventReference.replacementCourses &&
    eventReference.replacementCourses.forEach((course) => {
      addOnBody.data.relationships.field_replacement_courses.data.push({
        type: "course_entity--course_entity",
        id: course.id,
      })
    })

  // Add in prereq learning path if we have one
  addOnBody.data.relationships.field_prereq_learning_path = { data: [] }
  if (eventReference.relatedLP) {
    addOnBody.data.relationships.field_prereq_learning_path.data = {
      type: "learning_path--learning_path",
      id: eventReference.relatedLP.field_lp_uuid,
    }
  }

  // And a badge if they've specified one
  if (eventReference.badgeAwarded) {
    let badgeId = eventReference.badgeAwarded.id
    if (eventReference.badgeAwarded.uuid) {
      badgeId = eventReference.badgeAwarded.uuid
    }

    addOnBody.data.relationships.field_badge = {
      data: {
        type: "badges_entity--badges_entity",
        id: badgeId,
      },
    }
  }

  addOnBody.data.relationships.field_participants_users = { data: [] }
  addOnBody.data.relationships.field_participants_job_title = { data: [] }
  addOnBody.data.relationships.field_participants_group = { data: [] }

  // Multiple subgroups data
  let subGroupData = []

  if (session.subgroups && session.subgroups.data) {
    subGroupData = session.subgroups.data.map((group) => {
      return {
        id: group.id,
        type: "group--group",
      }
    })
  }

  // Handle All Org or All Group
  if (!isObservation && attendeeMethod !== "custom") {
    // If it's org-wide
    if (attendeeMethod === "all") {
      if (isOrgLevel) {
        addOnBody.data.relationships.field_participants_organization = {
          data: {
            type: "group--organization",
            id: session.group.uuid[0].value,
          },
        }
      }
    }
    // Or if it's all-group, use the group that was our original participants
    if (attendeeMethod === "all-group") {
      addOnBody.data.relationships.field_participants_group.data = subGroupData
    }
  }

  // IF CUSTOM
  // Go through groups, users, and job titles specified
  else {
    addOnBody.data.relationships.field_participants_organization = {
      data: null,
    }

    // If a Group admin selects Job Title(s) then the Participant Group needs to be set on the Addon
    if (!isOrgLevel) {
      if (addOnReference.subGroups.length) {
        addOnBody.data.relationships.field_participants_group.data =
          addOnReference.subGroups.map((group) => {
            return {
              type: "group--group",
              id: group.id,
            }
          })
        // If there are no attendees, that means it's only job titles, so we need to attach the subgroups to the addon
      } else if (!addOnReference.attendees.length) {
        addOnBody.data.relationships.field_participants_group.data =
          session.subgroups.data.map((group) => {
            return {
              type: "group--group",
              id: group.id,
            }
          })
      }
    }

    addOnReference.attendees &&
      addOnReference.attendees.forEach((user) => {
        addOnBody.data.relationships.field_participants_users.data.push({
          id: user.uuid ? user.uuid : user.id,
          type: "user--user",
        })
      })

    addOnReference.jobTitles &&
      addOnReference.jobTitles.forEach((jobTitle) => {
        addOnBody.data.relationships.field_participants_job_title.data.push({
          id: jobTitle.id,
          type: "taxonomy_term--job_titles",
        })
      })

    if (isOrgLevel) {
      if (addOnReference.jobTitles.length && !addOnReference.subGroups.length) {
      } else {
        // We need to attach subgroups to the addon
        const subGroups =
          addOnReference.subGroups &&
          addOnReference.subGroups.filter((group) => group)

        subGroups &&
          subGroups.forEach((group) => {
            addOnBody.data.relationships.field_participants_group.data.push({
              id: group.id,
              type: "group--group",
            })
          })
      }
    }
  }

  // Set the id if we're updating the addon
  if (eventReference.addOnId && !isCopy) {
    addOnBody.data.id = eventReference.addOnId
  }

  return addOnBody
}

export function getRoomsForLocation(location) {
  return async (dispatch, getState) => {
    let query = {
      filter: {
        "field_location.id": location.id,
        archive: {
          group: {
            conjunction: "OR",
          },
        },
        "null-archive": {
          condition: {
            operator: "IS NULL",
            path: "field_archive",
            memberOf: "archive",
          },
        },
        "is-archive": {
          condition: {
            operator: "=",
            path: "field_archive",
            value: false,
            memberOf: "archive",
          },
        },
      },
    }

    let response = await fetchWrapper.get(
      "/api/mobile_mind_room/room_base?" + qs.stringify(query)
    )

    if (response.ok) {
      let data = await response.json()

      dispatch({
        type: "locationsSlice/getRoomsForLocation",
        payload: data.data,
        meta: {
          location,
        },
      })

      return data.data
    }
  }
}

/**
 * Get all locations for an organization
 */
export function getLocations() {
  return async (dispatch, getState) => {
    const { session } = getState()

    let orgId =
      session.group && session.group.uuid && session.group.uuid[0].value

    let i = 0
    let pages = 1

    let allLocations = []

    try {
      // Make sure to account for more than 50 locations
      while (i < pages) {
        let query = {
          filter: {
            "field_organization.id": orgId,
          },
          page: {
            offset: i * 50,
          },
        }

        let response = await fetchWrapper.get(
          "/api/mobile_mind_location/location_base?" + qs.stringify(query)
        )

        if (response.ok) {
          let data = await response.json()
          data.data.forEach((location) => (location.rooms = []))
          pages = Math.ceil(Number(data.meta.count) / 50)
          allLocations = allLocations.concat(data.data)
        }
        i++
      }
      dispatch({
        type: "locationsSlice/getLocations",
        payload: allLocations,
      })
      return allLocations
    } catch (err) {
      console.log(err)
    }
  }
}

export function saveLocation(location, save, added) {
  return async (dispatch, getState) => {
    const { session } = getState()

    let attributes = { ...location.attributes }
    if (!save) {
      attributes.field_archive = true
    }

    let name =
      attributes.name.length >= 40
        ? attributes.name.substring(0, 37) + "..."
        : attributes.name
    attributes.name = name

    let body = {
      data: {
        type: "mobile_mind_location--location_base",
        attributes: attributes,
        relationships: {
          field_organization: {
            data: {
              type: "group--organization",
              id: session.group.uuid[0].value,
            },
          },
        },
      },
    }

    const isOrgLevel =
      session.orgRoles.includes("organization-admin") ||
      session.orgRoles.includes("organization-scheduler")

    const groupId =
      session.subgroup &&
      session.subgroup.uuid &&
      session.subgroup.uuid[0].value

    if (!isOrgLevel) {
      body.data.relationships.field_group = {
        data: {
          type: "group--group",
          id: groupId,
        },
      }
    }

    try {
      let response = await fetchWrapper.post(
        "/api/mobile_mind_location/location_base",
        session.token,
        JSON.stringify(body)
      )

      if (response.ok) {
        let data = await response.json()

        if (save) {
          dispatch({
            type: "locationsSlice/addNewLocation",
            payload: data.data,
          })
        }

        dispatch({
          type: "activeEventSlice/setActiveLocation",
          payload: data.data,
        })
        dispatch({
          type: "activeEventSlice/setNewLocation",
        })

        return data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function updateLocation(location, unarchive, rooms) {
  return async (dispatch, getState) => {
    const { session } = getState()

    let attributes = { ...location.attributes }

    let name =
      attributes.name.length >= 40
        ? attributes.name.substring(0, 37) + "..."
        : attributes.name
    attributes.name = name
    attributes.field_archive = unarchive ? false : true

    let capacity = Number(attributes.field_capacity)
    attributes.field_capacity = capacity

    let body = {
      data: {
        id: location.id,
        type: "mobile_mind_location--location_base",
        attributes: attributes,
      },
    }

    try {
      let response = await fetchWrapper.patch(
        "/api/mobile_mind_location/location_base/" + location.id,
        session.token,
        JSON.stringify(body)
      )

      if (response.ok) {
        let data = await response.json()

        dispatch({
          type: "locationsSlice/addUpdatedLocation",
          payload: data.data,
        })

        let locationRooms = rooms ? rooms : location.rooms

        locationRooms.forEach((currentRoom) => {
          let { attributes } = currentRoom
          // If this room already exists on the server
          const existing = !currentRoom.id.includes("new")

          if (existing) {
            let updateBody = {
              data: {
                id: currentRoom.id,
                type: "mobile_mind_room--room_base",
                attributes: {
                  name: attributes.field_room_number_name,
                  field_room_number_name: attributes.field_room_number_name,
                  field_entry_instructions: attributes.field_entry_instructions,
                  field_capacity: attributes.field_capacity,
                },
              },
            }

            // todo - need to update the "last fetch" version of the thing too
            fetchWrapper.patch(
              "/api/mobile_mind_room/room_base/" + currentRoom.id,
              session.token,
              JSON.stringify(updateBody)
            )
          }
          // Otherwise this is a newly added room, let's post it
          else {
            if (attributes.field_room_number_name) {
              let postBody = {
                data: {
                  type: "mobile_mind_room--room_base",
                  attributes: {
                    name: attributes.field_room_number_name,
                    field_room_number_name: attributes.field_room_number_name,
                    field_entry_instructions:
                      attributes.field_entry_instructions,
                    field_capacity: attributes.field_capacity,
                  },
                  relationships: {
                    field_location: {
                      data: {
                        type: "mobile_mind_location--location_base",
                        id: location.id,
                      },
                    },
                  },
                },
              }

              fetchWrapper.post(
                "/api/mobile_mind_room/room_base/",
                session.token,
                JSON.stringify(postBody)
              )
            }
          }
        })
        return data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function addNewRoom(room, location) {
  return async (dispatch, getState) => {
    const { session } = getState()
    try {
      const { attributes } = room
      if (attributes.field_room_number_name || attributes.name) {
        let postBody = {
          data: {
            type: "mobile_mind_room--room_base",
            attributes: {
              name: attributes.field_room_number_name
                ? attributes.field_room_number_name
                : attributes.name,
              field_room_number_name: attributes.field_room_number_name,
              field_entry_instructions: attributes.field_entry_instructions,
              field_capacity: attributes.field_capacity,
            },
            relationships: {
              field_location: {
                data: {
                  type: "mobile_mind_location--location_base",
                  id: location.id,
                },
              },
            },
          },
        }

        let newRoom = await fetchWrapper.post(
          "/api/mobile_mind_room/room_base/",
          session.token,
          JSON.stringify(postBody)
        )
        if (newRoom.ok) {
          let roomData = await newRoom.json()
          dispatch({
            type: "activeConferenceSlice/addNewRoom",
            payload: roomData.data,
          })
          dispatch({
            type: "activeEventSlice/addNewRoom",
            payload: roomData.data,
          })
        }
      }
    } catch (err) {}
  }
}

export function archiveRoom(room) {
  return async (dispatch, getState) => {
    const { session } = getState()

    dispatch({
      type: "roomsSlice/archiveRoom",
      payload: room,
    })

    let body = {
      data: {
        id: room.id,
        type: "mobile_mind_room--room_base",
        attributes: {
          field_archive: true,
        },
      },
    }

    try {
      await fetchWrapper.patch(
        "/api/mobile_mind_room/room_base/" + room.id,
        session.token,
        JSON.stringify(body)
      )
    } catch (err) {
      console.log(err)
    }
  }
}

export function archiveLocation(location) {
  return async (dispatch, getState) => {
    const { session } = getState()

    dispatch({
      type: "locationsSlice/archiveLocation",
      payload: location,
    })

    let body = {
      data: {
        id: location.id,
        type: "mobile_mind_location--location_base",
        attributes: {
          field_archive: true,
        },
      },
    }

    try {
      await fetchWrapper.patch(
        "/api/mobile_mind_location/location_base/" + location.id,
        session.token,
        JSON.stringify(body)
      )
    } catch (err) {
      console.log(err)
    }
  }
}

export function dropExternalEvent(changed, currentView, droppedEvent) {
  return async (dispatch, getState) => {
    const { session } = getState()
    const eventId = Object.keys(changed)[0]

    let startDate, endDate

    // Preserve the start and end times if we are on the month calendar
    if (currentView === "Month") {
      startDate =
        moment(changed[eventId].startDate).format("YYYY-MM-DDT") +
        moment(droppedEvent.field_start_date_value).format("HH:mm:ss")
      endDate =
        moment(changed[eventId].endDate)
          .subtract(1, "day")
          .format("YYYY-MM-DDT") +
        moment(droppedEvent.field_end_date_value).format("HH:mm:ss")
    } else {
      const isDST = moment(changed[eventId].endDate).isDST()

      startDate = moment(changed[eventId].startDate)
        .add(!isDST ? 1 : 0, "hours")
        .format()

      endDate = moment(changed[eventId].endDate)
        .add(!isDST ? 1 : 0, "hours")
        .format()
    }

    dispatch({
      type: "calendarSlice/dropEvent",
      payload: {
        isExternal: true,
        id: eventId,
        startDate,
        endDate,
      },
    })

    const body = {
      data: {
        id: eventId,
        type: "mm_rec_ext_event--recommended_external_event",
        attributes: {
          field_start_date: moment(startDate).format(),
          field_end_date: moment(endDate).format(),
        },
      },
    }

    try {
      let response = await fetchWrapper.patch(
        "/api/mm_rec_ext_event/recommended_external_event/" + eventId,
        session.token,
        JSON.stringify(body)
      )

      if (response.ok) {
        let data = await response.json()
        return data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function dropEvent(changed, currentView, sessionLogId) {
  return async (dispatch, getState) => {
    const { session, calendar, activeConference } = getState()

    const eventId = Object.keys(changed)[0]
    const newRoomId = changed[eventId].roomId

    let startDate, endDate, logId

    // If we're dragging an event around on the month view, we should preserve the start and end times
    if (currentView === "Month" && !activeConference.id) {
      let editingEvent = calendar.data.find((event) => event.id === eventId)

      logId = editingEvent.attendanceLogId

      let originalStartTime = editingEvent.startDate.split("T")[1]
      let originalEndTime = editingEvent.endDate.split("T")[1]

      startDate =
        moment(changed[eventId].startDate).format("YYYY-MM-DDT") +
        originalStartTime
      endDate =
        moment(changed[eventId].endDate).format("YYYY-MM-DDT") + originalEndTime
    } else {
      const isDST = moment(changed[eventId].endDate).isDST()
      startDate = moment(changed[eventId].startDate)
        .add(!isDST ? 1 : 0, "hours")
        .format()

      endDate = moment(changed[eventId].endDate)
        .add(!isDST ? 1 : 0, "hours")
        .format()
    }

    if (activeConference.id) {
      logId = sessionLogId
      dispatch({
        type: "activeConferenceSlice/dropConferenceEvent",
        payload: {
          id: eventId,
          newRoomId,
          startDate,
          endDate,
        },
      })
    } else {
      dispatch({
        type: "calendarSlice/dropEvent",
        payload: {
          id: eventId,
          startDate: moment(changed[eventId].startDate).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          endDate: moment(changed[eventId].endDate).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
        },
      })
    }

    let body = {
      data: {
        id: eventId,
        type: "mobilemind_event_entity--event_base",
        attributes: {
          field_event_date_time: [
            {
              value: startDate,
              end_value: endDate,
            },
          ],
          field_registration_end: endDate,
        },
        relationships: {},
      },
    }

    // Update the check in period on the attendance log
    if (logId) {
      let logBody = {
        data: {
          type: "mm_attendance_log--attendance_log_base",
          id: logId,
          attributes: {
            field_check_in_period: {
              value: startDate,
              end_value: endDate,
            },
          },
        },
      }

      fetchWrapper.patch(
        "/api/mm_attendance_log/attendance_log_base/" + logId,
        session.token,
        JSON.stringify(logBody)
      )
    }

    if (newRoomId && newRoomId !== "none") {
      body.data.relationships.field_room = {
        data: {
          type: "mobile_mind_room--room_base",
          id: newRoomId,
        },
      }
      body.data.attributes.field_type = "In Person Live"
    } else {
      body.data.attributes.field_type = "Virtual Live"
      body.data.relationships.field_room = null
    }

    let bundle = "event_base"
    if (activeConference.id) {
      bundle = "conference_event"
    }

    try {
      let response = await fetchWrapper.patch(
        "/api/mobilemind_event_entity/" + bundle + "/" + eventId,
        session.token,
        JSON.stringify(body)
      )

      if (response.ok) {
        let data = await response.json()
        return data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

/**
 * Go through any personnel and create entities for those
 * so we can stick those IDs on the event
 */

export function savePersonnel({ event, personnel, isCopy }) {
  return async (dispatch, getState) => {
    const { session } = getState()

    let allRoles = session.orgRoles.concat(session.groupRoles).join(",")
    event.data = event.data ? event.data : event

    // Only allow changes to personnel if they have some sort of role
    if (allRoles.includes("admin") || allRoles.includes("scheduler")) {
      try {
        let response,
          personnelIds = []

        if (personnel && personnel.length) {
          let i = 0
          let personnelBody

          while (i < personnel.length) {
            let personnelItem = personnel[i]

            let uid
            if (personnelItem.uid) {
              uid = personnelItem.uid
            }

            let personnelId =
              personnelItem.uuid ??
              (personnelItem.user && personnelItem.user.id)

            if (personnelItem.relationships) {
              personnelId = personnelItem.relationships.field_user.data.id
            }

            personnelBody = {
              data: {
                type: "mm_event_personnel--mm_event_personnel",
                relationships: {
                  field_user: {
                    data: {
                      type: "user--user",
                      id: personnelId,
                    },
                  },
                  field_event: {
                    data: {
                      type: event.data ? event.data.type : event.type,
                      id: event.data ? event.data.id : event.id,
                    },
                  },
                  field_event_role_term: {
                    data: {
                      type: "taxonomy_term--event_role",
                      id:
                        personnelItem.role ??
                        personnelItem.relationships.field_event_role_term.data
                          .id,
                    },
                  },
                },
              },
            }

            if (personnelItem.existing && !isCopy) {
              personnelBody.data.id = personnelItem.personnelId
              response = await fetchWrapper.patch(
                "/api/mm_event_personnel/mm_event_personnel/" +
                  personnelBody.data.id,
                session.token,
                JSON.stringify(personnelBody)
              )
            } else {
              personnelBody.data.attributes = {
                title:
                  "Event ID: " +
                  event.data.attributes.drupal_internal__id +
                  ", UID: " +
                  uid,
              }
              response = await fetchWrapper.post(
                "/api/mm_event_personnel/mm_event_personnel/",
                session.token,
                JSON.stringify(personnelBody)
              )
            }

            if (response.ok) {
              let personnelData = await response.json()
              personnelIds.push(personnelData.data.id)
            }
            i++
          }

          let url = event.data.type.replace("--", "/")

          // Now let's patch the event
          let eventBody = {
            data: {
              id: event.data.id,
              type: event.data.type,
              relationships: {
                field_personnel: {
                  data: personnelIds.map((id) => {
                    return {
                      type: "mm_event_personnel--mm_event_personnel",
                      id: id,
                    }
                  }),
                },
              },
            },
          }

          await fetchWrapper.patch(
            "/api/" + url + "/" + event.data.id,
            session.token,
            JSON.stringify(eventBody)
          )
        }
        return true
      } catch (err) {
        console.log(err)
      }
    }
  }
}

export function deletePersonnel(personnel) {
  return async (dispatch, getState) => {
    const { session } = getState()
    let id = personnel.personnelId
    try {
      let response = await fetchWrapper.remove(
        "/api/mm_event_personnel/mm_event_personnel/" + id,
        session.token
      )
      if (response.ok) {
        return true
      }
    } catch (err) {
      console.log(err)
    }
  }
}

/**
 * POST attachments to the event base
 */
export function saveEventAttachments(args) {
  const { attachments, event, isConference, isSession } = args

  let bundle = isConference ? "conference" : "event_base"
  if (isSession) {
    bundle = "conference_event"
  }

  return async (dispatch, getState) => {
    const { session } = getState()

    try {
      let i = 0
      let uploadedFiles = []

      while (i < attachments.length) {
        let body = parseDataUrl(attachments[i].file).toBuffer()

        let options = {
          credentials: "include",
          method: "POST",
          headers: new Headers({
            Accept: "application/vnd.api+json",
            "Content-Type": "application/octet-stream",
            "X-CSRF-Token": session.token,
            "Content-Disposition":
              'file; filename="' + he.encode(attachments[i].filename) + '"',
          }),
          body,
        }

        let response = await fetch(
          process.env.REACT_APP_API_URL +
            "/api/mobilemind_event_entity/" +
            bundle +
            "/" +
            event.data.id +
            "/field_attachment",
          options
        )

        if (response.ok) {
          let data = await response.json()
          uploadedFiles.push(data)
        }

        i++
      }

      return uploadedFiles
    } catch (err) {
      console.log(err)
    }
  }
}

export function checkInAttendee(invitee, isConference) {
  return async (dispatch, getState) => {
    const { session, activeEvent, activeConference } = getState()

    let undoCheckIn
    const event = isConference ? activeConference : activeEvent
    if (
      event.checkedInAttendees.find((user) => user.id === invitee.user_uuid)
    ) {
      undoCheckIn = true
    }

    try {
      let body = {
        data: {
          type: "mm_attendance_log--attendance_log_base",
          id: event.attendanceLogId,
          relationships: {
            field_attendees: {
              data: {
                id: invitee.user_uuid,
                type: "user--user",
                action: undoCheckIn ? "remove" : "add",
              },
            },
          },
        },
      }
      let options = {
        credentials: "include",
        method: "PATCH",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": session.token,
        }),
        body: JSON.stringify(body),
      }

      let response = await fetch(
        process.env.REACT_APP_API_URL +
          "/api/field-attendees/" +
          event.attendanceLogId,
        options
      )

      if (response.ok) {
        dispatch({
          type: isConference
            ? "activeConferenceSlice/updatedCheckedInAttendees"
            : "activeEventSlice/updatedCheckedInAttendees",
          payload: {
            id: invitee.user_uuid,
            type: "user--user",
          },
          meta: {
            undoCheckIn,
          },
        })

        if (undoCheckIn) {
          let updatedUserEvent = {
            data: {
              type: "mobile_mind_user_event--user_event_base",
              id: invitee.user_event_uuid,
              attributes: {
                field_credit: null,
                field_attended: false,
              },
            },
          }
          await fetchWrapper.patch(
            "/api/mobile_mind_user_event/user_event_base/" +
              invitee.user_event_uuid,
            session.token,
            JSON.stringify(updatedUserEvent)
          )
        }
        return true
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function generateNewJoinCode(event) {
  return async (dispatch, getState) => {
    const { session } = getState()

    try {
      let body = {
        data: {
          type: "mm_attendance_log--attendance_log_base",
          id: event.attendanceLogId,
          attributes: {
            field_regenerate: true,
          },
        },
      }

      let response = await fetchWrapper.patch(
        "/api/mm_attendance_log/attendance_log_base/" + event.attendanceLogId,
        session.token,
        JSON.stringify(body)
      )

      if (response.ok) {
        let data = await response.json()
        dispatch({
          type: "activeEventSlice/getNewJoinCode",
          payload: data.data,
        })
        dispatch({
          type: "activeConferenceSlice/getNewJoinCode",
          payload: data.data,
        })
        return data.data.attributes.field_join_code
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function createEventMessage(args) {
  return async (dispatch, getState) => {
    const { session } = getState()
    const { event, isConference, isSession } = args

    try {
      let eventType = "mobilemind_event_entity--event_base"
      if (isConference) {
        eventType = "mobilemind_event_entity--conference"
      }
      if (isSession) {
        eventType = "mobilemind_event_entity--conference_event"
      }

      let body = {
        data: {
          type: "mm_event_message--event_message_base",
          attributes: {
            name:
              "Event " +
              event.drupal_internal__id +
              " Message " +
              moment().unix(),
            field_message: event.newMessage,
            field_rsvp_only:
              event.newMessageEmailTarget === "rsvp_only" ? true : false,
          },
          relationships: {
            field_event: {
              data: {
                id: event.id,
                type: eventType,
              },
            },
          },
        },
      }

      let response = await fetchWrapper.post(
        "/api/mm_event_message/event_message_base/",
        session.token,
        JSON.stringify(body)
      )

      if (response.ok) {
        let data = await response.json()

        // Hit this endpoint to send the email
        await fetchWrapper.get(
          "/api/mm_event_message/notify/" +
            data.data.attributes.drupal_internal__id
        )

        if (isConference) {
          dispatch({
            type: "activeConferenceSlice/addSavedMessage",
            payload: data.data,
          })
        } else {
          dispatch({
            type: "activeEventSlice/addSavedMessage",
            payload: data.data,
          })
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function deleteEventMessage(message) {
  return async (dispatch, getState) => {
    const { session } = getState()
    dispatch({
      type: "activeEventSlice/removeMessage",
      payload: message,
    })
    dispatch({
      type: "activeConferenceSlice/removeMessage",
      payload: message,
    })
    try {
      await fetchWrapper.remove(
        "/api/mm_event_message/event_message_base/" + message.id,
        session.token
      )
    } catch (err) {
      console.log(err)
    }
  }
}

export function updateInviteeRSVP({ invitee, rsvp, bundle }) {
  return async (dispatch, getState) => {
    const { session } = getState()
    const payload = { invitee, rsvp }

    bundle === "conference"
      ? dispatch({ type: "activeConferenceSlice/updateInviteeRSVP", payload })
      : dispatch({ type: "activeEventSlice/updateInviteeRSVP", payload })

    const body = {
      data: {
        type: "mobile_mind_user_event--" + bundle,
        id: invitee.user_event_uuid,
        attributes: {
          field_rsvp: rsvp === "None" ? null : rsvp.toLowerCase(),
        },
      },
    }

    try {
      await fetchWrapper.patch(
        "/api/mobile_mind_user_event/" + bundle + "/" + invitee.user_event_uuid,
        session.token,
        JSON.stringify(body)
      )
    } catch (err) {
      console.log(err)
    }
  }
}

export function deleteEvent(event) {
  return async (dispatch, getState) => {
    const { activeEvent } = getState()
    const eventId = event ? event.id : activeEvent.id
    const drupalId = event
      ? event.drupal_internal__id
      : activeEvent.drupal_internal__id

    try {
      let windowParams =
        "left=300,top=350,width=525,height=300,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
      const confirmWindow =
        process.env.REACT_APP_API_URL + "/mobilemind_event/delete/" + drupalId
      window.open(confirmWindow, "", windowParams)

      dispatch({
        type: "calendarSlice/removeDeletedEvent",
        payload: eventId,
      })

      dispatch({
        type: "activeEventSlice/draftDeleted",
      })
    } catch (err) {
      console.log(err)
    }
  }
}

export function updatePDCredit(invitee, credit, bundle) {
  return async (dispatch, getState) => {
    const { session } = getState()

    if (bundle === "conference") {
      dispatch({
        type: "activeConferenceSlice/updateInviteePDCredit",
        payload: { invitee, credit },
      })
    } else {
      dispatch({
        type: "activeEventSlice/updateInviteePDCredit",
        payload: { invitee, credit },
      })
    }

    try {
      let updatedUserEvent = {
        data: {
          type: "mobile_mind_user_event--user_event_base",
          id: invitee.user_event_uuid,
          attributes: {
            field_credit: credit,
          },
        },
      }

      let response = await fetchWrapper.patch(
        "/api/mobile_mind_user_event/user_event_base/" +
          invitee.user_event_uuid,
        session.token,
        JSON.stringify(updatedUserEvent)
      )
      if (response.ok) {
        let data = await response.json()
        return data.data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function unArchiveEvent(event) {
  return async (dispatch, getState) => {
    const { session } = getState()
    const eventId = event.id

    dispatch({
      type: "calendarSlice/unArchive",
      payload: event,
    })
    dispatch({
      type: "activeConferenceSlice/setIsSessionArchived",
      payload: { id: eventId, status: false },
    })

    let body = {
      data: {
        type: "mobilemind_event_entity--event_base",
        id: eventId,
        attributes: {
          field_archive: false,
        },
      },
    }

    try {
      let response = fetchWrapper.patch(
        "/api/mobilemind_event_entity/event_base/" + eventId,
        session.token,
        JSON.stringify(body)
      )

      if (response.ok) {
        let data = await response.json()

        dispatch({
          type: "calendarSlice/unArchive",
          payload: data.data,
        })

        dispatch({
          type: "activeConferenceSlice/setIsSessionArchived",
          payload: { id: eventId, status: false },
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function changeSessionColor(eventId, hex) {
  return async (dispatch, getState) => {
    const { session } = getState()

    dispatch({
      type: "activeConferenceSlice/updateSessionColor",
      payload: {
        eventId,
        hex,
      },
    })

    let body = {
      data: {
        type: "mobilemind_event_entity--conference_event",
        id: eventId,
        attributes: {
          field_hex_color: hex,
        },
      },
    }

    try {
      fetchWrapper.patch(
        "/api/mobilemind_event_entity/conference_event/" + eventId,
        session.token,
        JSON.stringify(body)
      )
    } catch (err) {
      console.log(err)
    }
  }
}

export function updateRoomFromEvent({
  room,
  editingCapacity,
  editingInstructions,
  currentLocation,
  isConference,
}) {
  return async (dispatch, getState) => {
    const { session } = getState()

    try {
      let body = {
        data: {
          id: room.id,
          type: "mobile_mind_room--room_base",
          attributes: {
            name: room.attributes.name,
            field_room_number_name: room.attributes.name,
            field_capacity: editingCapacity,
            field_entry_instructions: editingInstructions,
          },
        },
      }

      let response = await fetchWrapper.patch(
        "/api/mobile_mind_room/room_base/" + room.id,
        session.token,
        JSON.stringify(body)
      )

      if (response.ok) {
        if (isConference) {
          dispatch(
            getRoomsForConferenceLocation({
              id: currentLocation.id,
              refresh: true,
            })
          )
        } else {
          dispatch(getRooms({ id: currentLocation.id, refresh: true }))
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function fetchEventAttendance(eventId) {
  return async () => {
    try {
      let response = await fetchWrapper.get("/api/event_attendance/" + eventId)

      if (response.ok) {
        let data = await response.json()
        return data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function updateConferenceRoomOrder({
  conferenceId,
  selectedLocation,
  currentOrder,
}) {
  return async (dispatch, getState) => {
    const { session } = getState()

    try {
      // Let's get what's already on the conference
      let initialGet = await fetchWrapper.get(
        "/api/mobilemind_event_entity/conference/" +
          conferenceId +
          "?include=field_rooms"
      )
      if (initialGet.ok) {
        const data = await initialGet.json()
        const included = data.included

        const nonLocationRooms = included
          .filter(
            (room) =>
              room.relationships.field_location.data.id !== selectedLocation
          )
          .map((room) => {
            return {
              id: room.id,
              type: "mobile_mind_room--room_base",
            }
          })
        const reOrderedRooms = currentOrder.map((room) => {
          return {
            id: room.id,
            type: "mobile_mind_room--room_base",
          }
        })

        const newRoomsData = reOrderedRooms.concat(nonLocationRooms)

        const body = {
          data: {
            id: conferenceId,
            type: "mobilemind_event_entity--conference",
            relationships: {
              field_rooms: {
                data: newRoomsData,
              },
            },
          },
        }

        let response = await fetchWrapper.patch(
          "/api/mobilemind_event_entity/conference/" + conferenceId,
          session.token,
          JSON.stringify(body)
        )

        if (response.ok) {
          let data = await response.json()
          return data
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
}
