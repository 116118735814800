export type DrupalViewPager = {
  current_page: number
  items_per_page: number
  total_items: number
  total_pages: number
}

export type DrupalViewEmptyRows = {
  content?: "[]"
  headers: {
    "X-Sub-Content-Type": null
    "Content-Type": "application/json"
  }
}

export type PagedResponse<T extends object> = {
  data: T[]
  meta: {
    count: number
  }
}

export type Entity = {
  id: string
  type: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attributes?: { [key: string]: any | undefined }
}

export enum FeatureName {
  OnDemand = "on_demand",
  Events = "events",
  Badges = "badges",
  Genie = "genie",
  Observations = "observations",
}
