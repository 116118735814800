import { Switch, Route } from "react-router-dom"
import { useAppSelector } from "store/hooks"

import EventSingleLayout from "../features/events/eventSingle/EventSingleLayout"
import ManageLocationsLayout from "../features/locations/ManageLocationsLayout"
import ConferenceLiveViewLayout from "../features/events/conference/ConferenceLiveViewLayout"
import LiveViewLayout from "../features/events/eventSingle/LiveViewLayout"
import ConferenceContainer from "../features/events/conference/ConferenceContainer"
import EventsContainer from "../features/events/EventsContainer"
import Unauthorized from "@mobilemind/common/src/components/Unauthorized"
import { FeatureName } from "store/reducers/session"

export const EventsSwitch = () => {
  const session = useAppSelector((state) => state.session)

  const {
    canSchedule,
    isEventPersonnel,
    reviewerTypes,
    orgRoles,
    groupRoles,
    enabledAddOns,
  } = session

  let allRoles = orgRoles
    .concat(groupRoles)
    .filter(
      (role) =>
        role !== "organization-member" &&
        role !== "organization-teacher" &&
        role !== "group-member" &&
        role !== "group-teacher"
    )

  const isObserver =
    orgRoles.includes("organization-observer") ||
    orgRoles.includes("organization-observation_admin") ||
    groupRoles.includes("group-observer") ||
    groupRoles.includes("group-observation_admin")

  const canReviewExternal =
    allRoles.join().includes("admin") ||
    (allRoles.join().includes("reviewer") &&
      reviewerTypes.includes("external_event"))

  const hideEvents =
    !enabledAddOns.includes(FeatureName.Events) &&
    !enabledAddOns.includes(FeatureName.OnDemand) &&
    !enabledAddOns.includes(FeatureName.Observations)

  const canViewEvents = !hideEvents && (canSchedule || isEventPersonnel)
  return (
    <Switch>
      <Route
        exact
        path="/events"
        component={
          (canSchedule ||
            isEventPersonnel ||
            canReviewExternal ||
            isObserver) &&
          !hideEvents
            ? EventsContainer
            : Unauthorized
        }
      />

      <Route
        exact
        path="/events/view/event/:id/"
        component={canViewEvents ? LiveViewLayout : Unauthorized}
      />
      <Route
        exact
        path="/events/view/session/:id/:returnTab?"
        component={canViewEvents ? LiveViewLayout : Unauthorized}
      />
      <Route
        exact
        path="/events/view/conference/:id"
        component={canViewEvents ? ConferenceLiveViewLayout : Unauthorized}
      />
      <Route
        exact
        path="/events/view/observation/:id"
        component={canViewEvents || isObserver ? LiveViewLayout : Unauthorized}
      />

      <Route
        exact
        path="/events/event/:id"
        component={canViewEvents ? EventSingleLayout : Unauthorized}
      />
      <Route
        exact
        path="/events/session/:id"
        component={canViewEvents ? EventSingleLayout : Unauthorized}
      />
      <Route
        exact
        path="/events/conference/:id"
        component={canViewEvents ? ConferenceContainer : Unauthorized}
      />
      <Route
        exact
        path="/events/observation/:id"
        component={
          canViewEvents || isObserver ? EventSingleLayout : Unauthorized
        }
      />

      <Route
        exact
        path="/events/locations"
        component={
          !hideEvents && (canSchedule || isObserver)
            ? ManageLocationsLayout
            : Unauthorized
        }
      />
    </Switch>
  )
}
