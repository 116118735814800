import { useState } from "react"
import { Paper, Typography, Box, Stack } from "@mui/material"
import DeleteButton from "../../../../components/DeleteButton"
import { CourseFormValues } from "features/courseForm/types"
import "../../../../styles/extension.scss"
import { ExtensionPreviewInitial } from "./ExtensionPreviewInitial"
import { ExtensionPreviewMultipleChoice } from "./ExtensionPreviewMultipleChoice"
import { SubmissionType } from "@mobilemind/common/src/types/course"
import { ExtensionPreviewText } from "./ExtensionPreviewText"
import { ExtensionPreviewImage } from "./ExtensionPreviewImage"

type ExtensionPreviewProps = {
  course: CourseFormValues
  onClose: () => void
}

export function ExtensionPreviewContainer({
  course,
  onClose,
}: ExtensionPreviewProps) {
  const [page, setPage] = useState(0)

  return (
    <Paper
      id="extensionPreview"
      sx={(theme) => ({
        position: "fixed",
        right: 20,
        bottom: 20,
        // @todo - add this to the theme somehow
        backgroundColor: "#00587c",
        zIndex: 90,
        width: 530,
      })}
    >
      <Stack
        direction="row"
        spacing={2}
        sx={{ alignItems: "center", padding: 3 }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "4px solid white",
            boxShadow: 1,
            borderRadius: 8,
            width: 35,
            height: 35,
            marginRight: 2,
            backgroundSize: "cover",
            backgroundImage:
              "url(" +
              process.env.REACT_APP_API_URL +
              course.field_category?.image?.attributes.uri.url +
              ")",
          }}
        />

        <Typography
          sx={{
            flex: 1,
            lineHeight: 1,
            fontWeight: "bold",
            fontSize: 18,
            color: "white",
          }}
        >
          {course.name}
        </Typography>
        <DeleteButton onClick={onClose} />
      </Stack>

      {page === 0 && (
        <ExtensionPreviewInitial setPage={setPage} course={course} />
      )}

      {page === 1 && (
        <>
          {(course.submissionType === SubmissionType.MultipleChoice ||
            course.submissionType === SubmissionType.Checkbox) && (
            <ExtensionPreviewMultipleChoice setPage={setPage} course={course} />
          )}

          {(course.submissionType === SubmissionType.Url ||
            course.submissionType === SubmissionType.Text) && (
            <ExtensionPreviewText setPage={setPage} course={course} />
          )}

          {(course.submissionType === SubmissionType.Image ||
            course.submissionType === SubmissionType.File) && (
            <ExtensionPreviewImage setPage={setPage} course={course} />
          )}
        </>
      )}
    </Paper>
  )
}
