import { Card, Dialog } from "@mui/material"
import theme from "@mobilemind/common/src/theme/theme"
import ButtonSmall from "../../components/ButtonSmall"
import { startTour } from "../../store/reducers/productTour"
import { useAppDispatch } from "../../store/hooks"
import { Link } from "react-router-dom"
import { useState, useEffect } from "react"
import HubspotForm from "react-hubspot-form"
import Loading from "@mobilemind/common/src/components/Loading"
import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"

const WidgetSupport = (props: any) => {
  const dispatch = useAppDispatch()
  const [isModalOpen, setModalOpen] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  useEffect(() => {
    window.addEventListener("message", handler)
    return () => {
      window.removeEventListener("message", handler)
    }
  }, [])

  function handler(event: any) {
    if (
      event.data.type === "hsFormCallback" &&
      event.data.eventName === "onFormSubmitted"
    ) {
      setShowSuccess(true)
    }
  }

  return (
    <>
      <Dialog
        onClose={() => {
          setShowSuccess(false)
          setModalOpen(false)
        }}
        id="new-idea-modal"
        className="modal"
        open={isModalOpen}
      >
        <h2>
          <div className="icon lightbulb" />
          Share a New Idea
        </h2>

        <HubspotForm
          portalId={"20989892"}
          formId={"e41082a9-1b0b-43fe-84a1-d5efd551c4a3"}
          onSubmit={handler}
          loading={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: 100,
              }}
            >
              <Loading />
            </div>
          }
        />
        {showSuccess && (
          <footer style={{ justifyContent: "center" }}>
            <ButtonLarge
              onClick={() => {
                setShowSuccess(false)
                setModalOpen(false)
              }}
            >
              Back to Dashboard
            </ButtonLarge>
          </footer>
        )}
      </Dialog>

      <Card className="dashboard-widget widget-support">
        <header style={{ backgroundColor: theme.palette.secondary.main }}>
          Support
        </header>
        <div className="inner" style={{ padding: 0 }}>
          <ul>
            <li>
              <ButtonSmall
                onClick={() => {
                  dispatch(startTour())
                }}
              >
                <span className="icon map" />
                Start Product Tour
              </ButtonSmall>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://meetings.hubspot.com/laura33/mobilemind-coaching-"
              >
                <ButtonSmall>
                  <span className="icon video-call" />
                  Schedule an HQ Session
                </ButtonSmall>
              </a>
            </li>
            <li>
              <ButtonSmall onClick={() => setModalOpen(true)}>
                <span className="icon lightbulb" />
                Share New Idea
              </ButtonSmall>
            </li>

            <li>
              <Link to="/submit-ticket">
                <ButtonSmall>
                  <span className="icon support" />
                  Submit a Ticket
                </ButtonSmall>
              </Link>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://knowledge.mobilemind.io/"
              >
                <ButtonSmall>
                  <span className="icon books" />
                  FAQs
                </ButtonSmall>
              </a>
            </li>
          </ul>
        </div>
      </Card>
    </>
  )
}

export default WidgetSupport
