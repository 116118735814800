import { Component } from "react"
import { connect } from "react-redux"
import {
  saveLearningPath,
  deleteLearningPath,
} from "../../actions/learningPaths"
import NavbarSecondary from "../../components/NavbarSecondary"
import ModalDelete from "../../components/ModalDelete"
import ModalLearningPathPreview from "../../components/ModalLearningPathPreview"
import { getCategories } from "../../store/reducers/categories"
import { isArrayIdentical, isGroupLevel } from "../../functions"
import { updateField } from "./activeLearningPathSlice"
import "../../styles/navbar.scss"
import { getSinglePartner } from "../../store/reducers/marketplace"

const mapStateToProps = ({
  learningPathTypes,
  activeLearningPath,
  categories,
  session,
  marketplace,
}) => {
  return {
    activeLearningPath,
    learningPathTypes,
    categories,
    session,
    marketplace,
  }
}

const mapDispatchToProps = {
  saveLearningPath,
  deleteLearningPath,
  updateField,
  getCategories,
  getSinglePartner,
}

class LearningPathNavbarContainer extends Component {
  state = {
    isDeleteOpen: false,
    isPreviewOpen: false,
    partnerImage: null,
  }

  componentDidMount = async () => {
    !this.props.categories.fetched && this.props.getCategories()

    if (this.props.session.isPartner) {
      const partnerListingResponse = await this.props.getSinglePartner({
        id: this.props.session.group.id[0].value,
      })

      if (partnerListingResponse?.payload?.partner_image) {
        this.setState({
          partnerImage:
            process.env.REACT_APP_API_URL +
            partnerListingResponse.payload.partner_image,
        })
      }
    }
  }

  saveDraft = () => {
    this.props
      .saveLearningPath(this.props.activeLearningPath, true)
      .then(() => {
        this.props.history.push("/create/learning-paths")
      })
  }

  deleteDraft = () => {
    this.props.deleteLearningPath(this.props.activeLearningPath.id).then(() => {
      this.props.history.push("/create/learning-paths")
    })
  }

  publish = () => {
    const { activeLearningPath } = this.props

    this.props.history.push("/create/learning-paths")
    this.props
      .saveLearningPath(this.props.activeLearningPath)
      .then((response) => {
        // If we've added or removed courses to an already published LP
        if (
          !activeLearningPath.field_draft &&
          !isArrayIdentical(
            activeLearningPath.originalCourses,
            activeLearningPath.courses
          )
        ) {
          let windowParams =
            "left=300,top=350,width=525,height=250,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
          const confirmWindow =
            process.env.REACT_APP_API_URL +
            "/user_learning_path/batch-create/" +
            activeLearningPath.drupal_internal__id
          window.open(confirmWindow, "", windowParams)
        }
      })
  }

  highlightFields = (missingFields) => {
    this.props.updateField({ field: "missingFields", value: missingFields })
  }

  render() {
    const { activeLearningPath, learningPathTypes, categories, session } =
      this.props
    let missingFields = []
    let missingDraftFields = []

    !activeLearningPath.name && missingFields.push("Learning Path Name")
    !activeLearningPath.name && missingDraftFields.push("Learning Path Name")
    !activeLearningPath.image && missingFields.push("Learning Path Image")
    !activeLearningPath.body && missingFields.push("Description")
    !activeLearningPath.courses.length &&
      missingFields.push("Include at least 1 course")

    if (
      isGroupLevel(session) &&
      session.subgroups &&
      session.subgroups.data &&
      session.subgroups.data.length > 1
    ) {
      if (!activeLearningPath.subGroups.length) {
        missingFields.push("Make visible to at least 1 group")
      }
    }

    const draftCourses = activeLearningPath.courses.filter(
      (course) => course.attributes.field_draft
    )
    if (draftCourses.length) {
      missingFields.push(
        <>
          Draft courses must be either published or removed from this Learning
          Path.
          <ul style={{ marginTop: 10 }}>
            {draftCourses.map((course) => {
              return <li>{course.attributes.name}</li>
            })}
          </ul>
        </>
      )
    }

    let canSave = activeLearningPath.name
    let canPublish = !missingFields.length

    const props = {
      type: "Learning Path",
      iconClass: "icon learningPath",
      publish: this.publish,
      saveDraft: this.saveDraft,
      onHover: this.highlightFields,
      openDeleteModal: () => {
        this.setState({ isDeleteOpen: true })
      },
      openPreview: (type) => {
        this.setState({ isPreviewOpen: true, previewType: type })
      },
      activeItem: activeLearningPath,
      canSave,
      canPublish,
      missingFields,
      missingDraftFields,
      groupRoles: session.groupRoles,
      orgRoles: session.orgRoles,
    }

    return (
      <>
        <ModalDelete
          type={"learning path"}
          open={this.state.isDeleteOpen}
          path={"/create/learning-paths"}
          deleteItem={this.deleteDraft}
          onClose={() => {
            this.setState({ isDeleteOpen: false })
          }}
        />
        {categories.fetched && (
          <ModalLearningPathPreview
            partnerImage={this.state.partnerImage}
            session={session}
            learningPath={activeLearningPath}
            pathType={learningPathTypes.data.find(
              (type) => type.id === activeLearningPath.type
            )}
            categories={categories}
            open={this.state.isPreviewOpen}
            type={this.state.previewType}
            user={this.props.session.user}
            onClose={() => {
              this.setState({ isPreviewOpen: false })
            }}
          />
        )}
        <NavbarSecondary {...props} />
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LearningPathNavbarContainer)
