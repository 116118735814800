import { FormControl, Grid } from "@mui/material"
import { connect } from "react-redux"
import { updateField } from "./activeEventSlice"
import { updateConferenceField } from "../conference/activeConferenceSlice"
import { useAppDispatch } from "store/hooks"
import EventCertificatePreview from "./EventCertificatePreview"
import { SettingSwitch } from "@mobilemind/common/src/components/SettingSwitch"

const mapStateToProps = ({ session, entityList }, ownProps) => {
  return {
    rubrics: entityList.orgRubrics,
    session,
  }
}

function EventCertificateLayout(props) {
  const event = props.activeEvent
  const eventLabel = props.isConference ? "Conference" : "Event"
  const hasLogo = props.session.group.field_org_logo[0]
  const updateRef = props.isConference ? updateConferenceField : updateField
  const dispatch = useAppDispatch()

  return (
    <div className="event-content event-certificate-layout">
      <h2 style={{ marginTop: 15 }}>{eventLabel} Certificate</h2>

      <Grid container spacing={3}>
        <Grid item lg={6} md={8}>
          <FormControl>
            <SettingSwitch
              checked={event.certificateSettings.pdCredit}
              label="Show PD Credit"
              onChange={(checked) => {
                dispatch(
                  updateRef({
                    field: "certificateSettings",
                    subfield: "pdCredit",
                    value: checked,
                  })
                )
              }}
            />

            <SettingSwitch
              checked={event.certificateSettings.signature}
              label={"Show Signature"}
              helperText={
                <>
                  Org Admins can set Signature Name, Title, and Style in
                  <strong> Organization Settings.</strong>
                </>
              }
              onChange={(checked) => {
                dispatch(
                  updateRef({
                    field: "certificateSettings",
                    subfield: "signature",
                    value: checked,
                  })
                )
              }}
            />

            <SettingSwitch
              disabled={!hasLogo}
              checked={event.certificateSettings.orgLogo}
              label="Show Organization Logo"
              helperText={
                <>
                  If turned on, {props.session.group.label[0].value}'s logo will
                  replace the MobileMind logo. Org Admins can upload a logo in
                  <strong> Organization Settings.</strong>
                </>
              }
              onChange={(checked) => {
                dispatch(
                  updateRef({
                    field: "certificateSettings",
                    subfield: "orgLogo",
                    value: checked,
                  })
                )
              }}
            />
          </FormControl>
        </Grid>
        <Grid item lg={6} md={12}>
          <FormControl>
            <EventCertificatePreview {...props} />
          </FormControl>
        </Grid>
      </Grid>
    </div>
  )
}

export default connect(mapStateToProps)(EventCertificateLayout)
