import moment from "moment"
import sessionColors from "../calendar/sessionColors"

const today = new Date()
let endDate = new Date(today)
endDate.setDate(endDate.getDate() + 0.04)

const initialState = {
  certificateSettings: {
    pdCredit: true,
    orgLogo: false,
    signature: false,
  },
  eventAuthor: {},
  feedbackSummary: {
    sortBy: "name",
    data: [],
    fetched: false,
  },
  supportContact: "",
  color: sessionColors[6],
  allAttendees: [],
  groupData: null,
  id: null,
  drupal_internal__id: null,
  fetched: true,
  currentTab: "details",
  name: "",
  startDate: moment().format(),
  endDate: moment().add(1, "hours").format(),
  allDay: false,
  description: "",
  type: "In Person Live",
  category: null,
  notes: "",
  meetingLink: "",
  registrationWindow: "unlimited",
  registrationStarts: moment().format(),
  registrationEnds: moment().add(1, "hours").format(),
  eventMax: 0,
  sendReminders: ["none"],
  sendRegistrationReminders: ["none"],
  currentLocation: null,
  personnel: [],
  personnelSearch: "",
  difficulty: "N/A",
  pdCredit: {
    hours: 0,
    minutes: 0,
  },
  preRequisiteCourses: [],
  preRequisiteCoursesSearch: "",
  preRequisiteCoursesResults: [],
  parentConferenceId: null,
  replacementCourses: [],
  replacementCoursesSearch: "",
  replacementCoursesResults: [],
  relatedLP: null,
  relatedLPSearch: "",
  relatedLPResults: [],
  badgeAwarded: null,
  isBadgeSaving: false,
  newBadge: {
    image: null,
    filename: null,
    name: "",
  },
  eventImage: {
    file: null,
    filename: null,
  },
  tags: [],
  attendeeMethod: "unselected",
  observationType: null,
  observationRubric: null,
  sortBy: null,
  sortOrder: "DESC",
  subGroups: [],
  jobTitles: [],
  attendees: [],
  isSaving: false,
  attachments: [],
  feedbackMethod: "custom",
  feedbackURL: "",
  feedbackFormId: null,
  feedbackRequired: false,
  feedbackIsAnonymous: false,
  attendanceMethod: "join_code",
  joinCode: "",
  inviteeSearch: "",
  invitees: [],
  messages: {
    fetched: false,
    data: [],
  },
  newMessage: "",
  newMessageEmailTarget: "rsvp_only",
  checkInStart: moment().format(),
  checkInEnd: moment().add(1, "hour").format(),
  originalParticipants: [],
  originalAttendeeMethod: "unselected",
  helpfulLinks: "",
  pushNotifications: false,
  learnerSearch: "",
  allOrgUsers: [],
  orgUsers: [],
  subGroupSearch: "",
  sendPublishEmail: false,
  missingFields: [],
  eventRoom: "none",
  isNew: true,
  isAttendeeModalOpen: false,
  filterRSVP: "any",
  filterCheckedIn: "any",
  filterGroup: "all",
  inviteesCurrentPage: 0,
  totalInviteePages: 0,
  isJoinCodeModalOpen: false,
  observationRubricUUID: null,
  observationUser: null,
  fullRubric: null,
  observationUserRubric: {
    data: null,
    fetched: false,
  },
}

export default initialState
