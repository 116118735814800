import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"
import { RootState } from "store/types"
import { MarketplaceItem } from "../../features/marketplace/types"

export const getSinglePartner = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("marketplace/getSinglePartner", async (args, thunkAPI) => {
  const partnerUUID =
    args.partnerID ?? thunkAPI.getState().session.group.uuid[0].value
  const query = {
    include: "field_partner_image,field_partner_prev",
  }
  const response = await fetchWrapper.get(
    "/api/group/partner/" + partnerUUID + "?" + qs.stringify(query)
  )

  if (response.ok) {
    const data = await response.json()
    const { attributes } = data.data

    const singlePartner: MarketplaceItem = {
      name: attributes.label,
      partner_description: attributes.field_partner_desc?.value ?? "",
      partner_topic_list: attributes.field_topics.join(","),
      partner_image: "",
      partner_free_or_paid: attributes.field_free_or_paid,
      partner_approval_required: attributes.field_partner_approval_required,
      partner_contact: attributes.field_partner_contact?.value,
      partner_preview: [],
      partner_promo_video: attributes.field_promo_video,
      added_to_org: false,
      uuid: data.data.id,
      id: attributes.drupal_internal__id,
      partner_status: attributes.status,
      field_signature_font: attributes.field_signature_font,
      field_signature_name: attributes.field_signature_name,
      field_signature_title: attributes.field_signature_title,
    }

    if (data.included) {
      const previewImageRelationships =
        data.data.relationships.field_partner_prev?.data
      const partnerImageRelationship =
        data.data.relationships.field_partner_image?.data

      if (partnerImageRelationship) {
        const partnerImage = data.included.find((included: any) => {
          return included.id === partnerImageRelationship.id
        })
        singlePartner.partner_image = partnerImage
          ? partnerImage.attributes.uri.url
          : ""
      }

      if (previewImageRelationships) {
        const previewImages = data.included.filter((included: any) =>
          previewImageRelationships.find(
            (preview: any) => preview.id === included.id
          )
        )
        singlePartner.partner_preview = previewImages.map((image: any) => {
          return {
            uuid: image.id,
            uri: image.attributes.uri.url,
          }
        })
      }
    }

    return singlePartner
  }
})

export const getSinglePartnerImage = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("marketplace/getSinglePartnerImage", async (args, thunkAPI) => {
  const partnerUUID =
    args.partnerID ?? thunkAPI.getState().session.group.uuid[0].value
  const query = {
    include: "field_partner_image",
  }
  const response = await fetchWrapper.get(
    "/api/group/partner/" + partnerUUID + "?" + qs.stringify(query)
  )

  if (response.ok) {
    const data = await response.json()

    return data.included && data.included[0]
  }
})

export const getMarketplaceItems = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("marketplace/getMarketplaceItems", async (args) => {
  const { search, pricing, status, added } = args

  /**
   * Creates an endpoint for partners on the partner marketplace.
   * * /api/marketplace
   * * * &search= [str] (string of the partner label or topics set in the partner)
   * * * &status= [str] (active or underconstruction)
   * * * &added= [int] (1 to show only added partners, 0 to show partner's not added)
   * * * &paid= [int] (1 to show paid, 0 to show free)
   * * * &page[offset]= [int]
   */
  const query = {
    search,
    paid: pricing,
    added,
    status,
  }

  const response = await fetchWrapper.get(
    "/api/marketplace?" + qs.stringify(query)
  )

  if (response.ok) {
    const data = await response.json()
    return data.marketplace_data
  }
  return []
})

type InitialState = {
  data: MarketplaceItem[]
  fetched: boolean
}

const initialState: InitialState = {
  data: [],
  fetched: true,
}

export const marketplace = createSlice({
  name: "marketplace",
  initialState,
  reducers: {
    addPartnerToOrg: (state, action) => {
      state.data.forEach((partner) => {
        if (partner.id === action.payload.id) {
          partner.added_to_org = true
        }
      })
    },
    removePartnerFromOrg: (state, action) => {
      state.data.forEach((partner) => {
        if (partner.id === action.payload.id) {
          partner.added_to_org = false
        }
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMarketplaceItems.pending, (state, action) => {
      state.data = []
      state.fetched = false
    })
    builder.addCase(getMarketplaceItems.fulfilled, (state, action) => {
      state.data = action.payload
      state.fetched = true
    })
  },
})

export const { addPartnerToOrg, removePartnerFromOrg } = marketplace.actions

export default marketplace.reducer
