import React, { useState, useLayoutEffect } from "react"
import { Box } from "@mui/material"
import ButtonSmall from "@mobilemind/common/src/components/ButtonSmall"

const useTruncatedElement = ({
  ref,
}: {
  ref: React.RefObject<HTMLElement>
}) => {
  const [isTruncated, setIsTruncated] = useState(false)
  const [isReadingMore, setIsReadingMore] = useState(false)

  useLayoutEffect(() => {
    const { offsetHeight, scrollHeight } = ref.current || {}

    if (offsetHeight && scrollHeight && offsetHeight < scrollHeight) {
      setIsTruncated(true)
    } else {
      setIsTruncated(false)
    }
  }, [ref])

  return {
    isTruncated,
    isReadingMore,
    setIsReadingMore,
  }
}

export function DescriptionWithReadMore(props: { description: string }) {
  const { description } = props
  const ref = React.useRef(null)
  const { isTruncated, isReadingMore, setIsReadingMore } = useTruncatedElement({
    ref,
  })
  return (
    <div>
      <p
        ref={ref}
        style={{
          margin: 5,
          overflow: "hidden",
          overflowWrap: "break-word",
        }}
        className={`read-more-description ${!isReadingMore && "read-more"}`}
      >
        {description}
      </p>

      {isTruncated && !isReadingMore && (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <ButtonSmall onClick={() => setIsReadingMore(true)}>
            Read More
          </ButtonSmall>
        </Box>
      )}
    </div>
  )
}
