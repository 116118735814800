import { Link } from "react-router-dom"

import { Dialog, Button } from "@mui/material"
import "../styles/modal.scss"

function ModalArchiveEvent(props) {
  const { isConference, onClose, open, archive, path } = props

  const handleClose = () => {
    onClose()
  }

  const confirm = () => {
    archive()
  }

  return (
    <Dialog className="modal" onClose={handleClose} open={open}>
      <p>
        Are you sure you want to archive this{" "}
        {isConference ? <>conference</> : <>event</>} and remove it from the
        calendar in the Learn app?
      </p>
      <footer>
        <Button onClick={handleClose} className="button small cancel">
          Cancel
        </Button>
        <Link to={path}>
          <Button onClick={confirm} className="button small">
            Yes, Archive {isConference ? <>conference</> : <>event</>}
          </Button>
        </Link>
      </footer>
    </Dialog>
  )
}

export default ModalArchiveEvent
