import { Link } from "react-router-dom"
import classNames from "classnames"

import { Dialog, Button } from "@mui/material"
import "../styles/modal.scss"

function ModalCopy(props) {
  const { onClose, isTemplate, open, type, copy, path } = props

  const handleClose = () => {
    onClose()
  }

  const confirm = () => {
    onClose()
    copy(true)
  }

  return (
    <Dialog className="modal" onClose={handleClose} open={open}>
      {isTemplate ? (
        <p>
          Are you sure you want to create a new {type} based on this community
          course? The new course will be created as a <strong>Draft</strong>{" "}
          until you choose to publish it.
        </p>
      ) : (
        <p>
          Are you sure you want to create an exact copy of this {type}? The new
          copy will be created as a <strong>Draft</strong> until you choose to
          publish it.
        </p>
      )}

      <div className={classNames("copy-animation", isTemplate && "green")}>
        {type === "event" ? (
          <>
            <span className="course-card icon calendar blue original"></span>
            <span className="course-card icon calendar blue copy"></span>
          </>
        ) : (
          <>
            <span className="course-card original"></span>
            <span className="course-card copy"></span>
          </>
        )}
      </div>

      <footer style={{ marginTop: 0 }}>
        <Button onClick={handleClose} className="button small cancel">
          Cancel
        </Button>
        <Link to={path}>
          <Button onClick={confirm} className="button small">
            {isTemplate ? <>Yes, Create Course</> : <>Yes, Copy {type}</>}
          </Button>
        </Link>
      </footer>
    </Dialog>
  )
}

export default ModalCopy
