import { Button } from "@mui/material"
import { MouseEvent, useEffect, useState } from "react"
import { Link } from "react-router-dom"

import { useAppDispatch, useAppSelector } from "../../store/hooks"

import moment from "moment"
import { deleteEvent, unArchiveEvent } from "../../actions/events"
import ModalDelete from "../../components/ModalDelete"
import {
  fetchArchivedEvents,
  setShowArchive,
} from "../../store/reducers/calendar"

import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"
import classNames from "classnames"

function ArchivedEventsContainer() {
  const calendar = useAppSelector((state) => state.calendar)
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)

  const dispatch = useAppDispatch()

  useEffect(() => {
    !calendar.archived.fetched && dispatch(fetchArchivedEvents({}))
  })

  function unArchive(event: any) {
    dispatch(unArchiveEvent(event))
    dispatch(setShowArchive(false))
  }

  function confirmDelete(event?: MouseEvent) {
    dispatch(deleteEvent(event))
    setDeleteModalOpen(false)
  }

  return (
    <div className="page archived-events">
      <h2>Archived Events</h2>
      <div className="calendarList archivedList">
        <ModalDelete
          type={"event"}
          open={isDeleteModalOpen}
          path={"/events"}
          deleteItem={confirmDelete}
          onClose={() => {
            setDeleteModalOpen(false)
          }}
        />
        <ul>
          {calendar.archived.data.map((event: any, index: any) => {
            const editUrl = event.isConference
              ? "/events/view/conference/" + event.drupal_internal__id
              : "/events/event/" + event.drupal_internal__id

            return (
              <li key={index} style={{ animationDelay: index * 0.1 + "s" }}>
                <div className="flexRow">
                  <div style={{ flex: 1, padding: 0 }} className="flexRow">
                    <div className="dateTime">
                      <div className="weekMonth">
                        {moment(event.startDate).format("ddd")}
                        <br />
                        {moment(event.startDate).format("MMM")}
                      </div>
                      <span className="date">
                        {moment(event.startDate).format("D")}
                      </span>
                    </div>

                    <span
                      className={classNames(
                        "chip",
                        event.isConference && "conference"
                      )}
                    />

                    <div className="title">
                      <header>{event.title}</header>
                    </div>
                  </div>
                  {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    <Link style={{ marginRight: 35 }} to={editUrl}>
                      <Button className="button small">
                        <span className="icon edit"></span>
                        Edit
                      </Button>
                    </Link>
                  }
                  <Button
                    style={{ marginRight: 35 }}
                    onClick={() => {
                      unArchive(event)
                    }}
                    className="button small"
                  >
                    <span className="icon unarchive"></span>
                    Unarchive
                  </Button>

                  <ButtonLarge
                    onClick={() => {
                      confirmDelete(event)
                    }}
                    className="button small delete"
                  >
                    <span className="icon trash-white"></span>
                    Delete
                  </ButtonLarge>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default ArchivedEventsContainer
