import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import moment from "moment"
import he from "he"
import type { RootState } from "../types"
import qs from "qs"
import debounceThunk from "@mobilemind/common/src/functions/debounceThunk"
import { saveExternalTimeBlocks } from "@mobilemind/common/src/actions/externalPD"
import parseDataUrl, { DataUrl } from "parse-data-url"
import { convertMinutesToHoursMinutes } from "@mobilemind/common/src/functions"
import { UTCToLocalTime } from "@mobilemind/common/src/functions"
import { ExternalEvent } from "../../features/events/externalPD/RecommendedEventModal"
import { calculateTimeBlocksTime } from "../../features/events/externalPD/ExternalEventStep2"
import { CategoryTaxonomy } from "@mobilemind/common/src/types/taxonomy/category"

// This handles time blocks and attachments
function convertRequestData(events: any) {
  events.forEach((event: any) => {
    event.id = event.uuid
    event.field_total_time_value = Number(event.field_total_time_value)

    type CategoryData = {
      uuid: string
      category_name: string
      category_uuid: string
      category_time_spent: string
    }

    // Handle category "Time Blocks," which is our Paragraph with category and time info
    if (event.category_data) {
      event.timeBlocks = event.category_data.map((block: CategoryData) => {
        let totalMinutes = Number(block.category_time_spent)
        return {
          id: block.uuid,
          hours: convertMinutesToHoursMinutes(totalMinutes).hours,
          minutes: convertMinutesToHoursMinutes(totalMinutes).minutes,
          categoryId: block.category_uuid,
        }
      })
    }

    // Handle attachments
    if (event.attachments_url) {
      event.attachments = event.attachments_url.map((url: string) => {
        return {
          published: true,
          extension: url.split(".")[url.split(".").length - 1],
          filename: decodeURI(url.split("/")[url.split("/").length - 1]),
          file: url,
        }
      })
    }

    // Handle reviewer information
    if (event.reviewer_data && event.reviewer_data[0]) {
      event.reviewer = event.reviewer_data[0]
    }
  })
  return events
}

export const getSingleExternalRequest = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("externalPD/getSingleExternalRequest", async (args, thunkAPI) => {
  const { event_id, uid } = args

  let response = await fetchWrapper.get(
    "/api/single_ext_usr_event/" + event_id + "?uid=" + uid
  )

  if (response.ok) {
    const data = await response.json()
    return data.data
  }
})

export const fetchExternalRequests = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("externalPD/fetchExternalRequests", async (args, thunkAPI) => {
  let data: any = undefined
  const { session, externalPD } = thunkAPI.getState()
  const { status } = args
  const isGroupLevel =
    (session.isGroupAdmin ||
      session.groupRoles.join().includes("scheduler") ||
      session.groupRoles.join().includes("reviewer")) &&
    !session.orgRoles.join().includes("admin") &&
    !session.orgRoles.join().includes("scheduler") &&
    !session.orgRoles.join().includes("reviewer")

  const activeTab =
    status === "pending" ? externalPD.pending : externalPD.reviewed

  const { filters } = activeTab

  let sortOrder = filters.sort === "total_time" ? "DESC" : "ASC"
  let sortBy

  if (filters.sort.includes("changed")) {
    sortOrder = filters.sort.includes("newest") ? "DESC" : "ASC"
    sortBy = "changed"
  } else {
    sortBy = filters.sort
  }

  let appliedStatus = status === "pending" ? "pending" : "not_pending"
  appliedStatus = filters.status

  type Query = {
    group?: string | number
    status: string
    search: string
    sort_by: string
    sort_order: string
    category: null | number[]
    page: {
      offset: number
    }
  }

  const groupLevelQueryItems = session.subgroups?.data
    .map((group: any) => group.drupal_internal__id)
    .join()

  const query: Query = {
    status: appliedStatus,
    search: filters.search,
    sort_by: sortBy,
    sort_order: sortOrder,
    category: null,
    page: {
      offset: activeTab.currentPage * 25,
    },
  }

  if (filters.subgroup !== "all" || isGroupLevel) {
    query.group = isGroupLevel ? groupLevelQueryItems : filters.subgroup
  }

  if (filters.category?.attributes) {
    query.category = [filters.category.attributes?.drupal_internal__tid]
  }

  if (filters.category?.attributes) {
    query.category = [filters.category.attributes?.drupal_internal__tid]
  }

  let response = await fetchWrapper.get(
    "/api/mm_ext_usr_event/hq/" +
      session.group.id[0].value +
      "?" +
      qs.stringify(query)
  )

  if (response.ok) {
    data = await response.json()
    const convertedData: any[] = convertRequestData(
      data.external_user_event_data
    )
    convertedData.forEach((event: any) => {
      event.editedTimeBlocks = event.timeBlocks
      return event
    })

    return {
      status: args.status,
      data: convertedData,
      total: data.total_records,
    }
  }
})

const debouncedGetRequests = debounceThunk(fetchExternalRequests, 750)

export const increaseRequestPage = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("externalPD/increaseRequestPage", async (args, thunkAPI) => {
  thunkAPI.dispatch(debouncedGetRequests(args))
  return args
})

export const approveDeclinePreApprovalStep = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("externalPD/approveDeclinePreApprovalStep", async (args, thunkAPI) => {
  let data: any = undefined
  const { session } = thunkAPI.getState()
  const { approvalStep, comments, isApprove } = args
  const { uuid } = approvalStep

  const body: any = {
    data: {
      id: uuid,
      type: "mm_usr_appr--mm_usr_appr",
      attributes: {
        field_status: isApprove ? "approved" : "denied",
        field_decision_date: moment().format(),
        field_notes: comments,
      },
      relationships: {
        field_approver: {
          data: {
            type: "user--user",
            id: session.user.id,
          },
        },
      },
    },
  }

  let response = await fetchWrapper.patch(
    "/api/mm_usr_appr/mm_usr_appr/" + uuid,
    session.token,
    JSON.stringify(body)
  )

  if (response.ok) {
    data = await response.json()

    await thunkAPI.dispatch(fetchExternalRequests({ status: "pending" }))
    return data.data
  }
})

export const approveExternalRequest = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("externalPD/approveExternalRequest", async (args, thunkAPI) => {
  let data: any = undefined
  const { session } = thunkAPI.getState()
  const { event, timeBlocks, comments, reviewerAttachment } = args
  let totalTime: number = 0

  if (reviewerAttachment?.file) {
    // @ts-ignore
    const dataUrl: DataUrl = parseDataUrl(reviewerAttachment.base64)

    let options = {
      credentials: "include",
      method: "POST",
      headers: new Headers({
        Accept: "application/vnd.api+json",
        "Content-Type": "application/octet-stream",
        "X-CSRF-Token": session.token,
        "Content-Disposition":
          'file; filename="' + he.encode(reviewerAttachment.file.name) + '"',
      }),
      body: dataUrl.toBuffer(),
    }
    await fetch(
      process.env.REACT_APP_API_URL +
        "/api/mm_ext_usr_event/mm_ext_usr_event/" +
        event.uuid +
        "/field_reviewer_attachments",
      // @ts-ignore
      options
    )
  }

  timeBlocks.forEach(
    (block: {
      categoryId: string
      hours: number
      id: string
      minutes: number
    }) => {
      let totalMinutes = block.minutes + block.hours * 60
      totalTime += totalMinutes
    }
  )

  let body: any = {
    data: {
      id: event.uuid,
      type: "mm_ext_usr_event--mm_ext_usr_event",
      attributes: {
        field_status: "accepted",
        field_reviewed_date: moment().format("YYYY-MM-DD"),
        field_rev_comment: comments,
        field_total_time: totalTime,
      },
      relationships: {
        field_reviewer: {
          data: {
            type: "user--user",
            id: session.user.id,
          },
        },
      },
    },
  }

  let response = await fetchWrapper.patch(
    "/api/mm_ext_usr_event/mm_ext_usr_event/" + event.id,
    session.token,
    JSON.stringify(body)
  )

  if (response.ok) {
    data = await response.json()
    await thunkAPI.dispatch(
      saveExternalTimeBlocks({ event: data.data, timeBlocks })
    )
    await thunkAPI.dispatch(fetchExternalRequests({ status: "reviewed" }))
    return data.data
  }
})

export const declineExternalRequest = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("externalPD/declineExternalRequest", async (args, thunkAPI) => {
  let data: any = undefined
  const { session } = thunkAPI.getState()
  const { event, comments, reviewerAttachment } = args

  if (reviewerAttachment?.file) {
    // @ts-ignore
    const dataUrl: DataUrl = parseDataUrl(reviewerAttachment.base64)

    let options = {
      credentials: "include",
      method: "POST",
      headers: new Headers({
        Accept: "application/vnd.api+json",
        "Content-Type": "application/octet-stream",
        "X-CSRF-Token": session.token,
        "Content-Disposition":
          'file; filename="' + he.encode(reviewerAttachment.file.name) + '"',
      }),
      body: dataUrl.toBuffer(),
    }
    await fetch(
      process.env.REACT_APP_API_URL +
        "/api/mm_ext_usr_event/mm_ext_usr_event/" +
        event.uuid +
        "/field_reviewer_attachments",
      // @ts-ignore
      options
    )
  }

  let body: any = {
    data: {
      id: event.uuid,
      type: "mm_ext_usr_event--mm_ext_usr_event",
      attributes: {
        field_status: "declined",
        field_reviewed_date: moment().format("YYYY-MM-DD"),
        field_rev_comment: comments,
      },
      relationships: {
        field_reviewer: {
          data: {
            type: "user--user",
            id: session.user.id,
          },
        },
      },
    },
  }

  let response = await fetchWrapper.patch(
    "/api/mm_ext_usr_event/mm_ext_usr_event/" + event.id,
    session.token,
    JSON.stringify(body)
  )
  await thunkAPI.dispatch(fetchExternalRequests({ status: "reviewed" }))

  if (response.ok) {
    data = await response.json()
    return data
  }
})

export const reopenExternalRequest = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("externalPD/reopenExternalRequest", async (args, thunkAPI) => {
  let data: any = undefined
  const { session } = thunkAPI.getState()
  const { event } = args

  let body: any = {
    data: {
      id: event.uuid,
      type: "mm_ext_usr_event--mm_ext_usr_event",
      attributes: {
        field_status: "pending",
      },
    },
  }

  let response = await fetchWrapper.patch(
    "/api/mm_ext_usr_event/mm_ext_usr_event/" + event.id,
    session.token,
    JSON.stringify(body)
  )

  if (response.ok) {
    data = await response.json()
    return data
  }
})

export const saveRecommendedEvent = createAsyncThunk<
  unknown,
  ExternalEvent,
  { state: RootState }
>("externalPD/saveRecommendedEvent", async (currentEvent, thunkAPI) => {
  const { session, jobTitles } = thunkAPI.getState()

  const totalTime = calculateTimeBlocksTime(currentEvent.timeBlocks)
  const body: any = {
    data: {
      type: "mm_rec_ext_event--recommended_external_event",
      attributes: {
        title: currentEvent.title,
        field_location: {
          // @todo - Handle this with a form item and store it in the damn state
          langcode: null,
          country_code: "US",
          administrative_area: "GA",
          locality: "Atlanta",
          postal_code: "30312",
          address_line1: "351 Woodward Ave",
          address_line2: "",
        },
        field_description: currentEvent.description.replaceAll(
          "<p><br></p>",
          ""
        ),

        field_total_time: totalTime,
      },
      relationships: {
        field_owner_org: {
          data: {
            type: "group--organization",
            id: session.group.uuid[0].value,
            meta: {
              drupal_internal__target_id: session.group.id[0].value,
            },
          },
        },
      },
    },
  }

  if (!currentEvent.isDateless) {
    body.data.attributes.field_start_date =
      moment(currentEvent.startDate).format("YYYY-MM-DDT") +
      moment(currentEvent.startDate).utc().format().split("T")[1]
    body.data.attributes.field_end_date =
      moment(currentEvent.endDate).format("YYYY-MM-DDT") +
      moment(currentEvent.endDate).utc().format().split("T")[1]
  }

  // If it's the whole org / group involved, set that and clear out the other fields
  if (currentEvent.participantMethod === "all") {
    // If we are at the group level only
    if (
      (session.isGroupAdmin ||
        session.groupRoles.includes("group-scheduler")) &&
      !session.orgRoles.join().includes("admin") &&
      !session.orgRoles.join().includes("scheduler")
    ) {
      // Set the subgroup owners
      body.data.relationships.field_owner_group = {
        data: {
          type: "group--group",
          id: session.subgroup.uuid[0].value,
          meta: {
            drupal_internal__target_id: session.subgroup.id[0].value,
          },
        },
      }
      // And the participants
      body.data.relationships.field_pcps_group = {
        data: {
          type: "group--group",
          id: session.subgroup.uuid[0].value,
          meta: {
            drupal_internal__target_id: session.subgroup.id[0].value,
          },
        },
      }
    }

    // Otherwise set participants at org/group-wide
    else {
      body.data.relationships.field_pcps_org = {
        data: {
          type: "group--organization",
          id: session.group.uuid[0].value,
          meta: {
            drupal_internal__target_id: session.group.id[0].value,
          },
        },
      }
      body.data.relationships.field_pcps_job_title = {
        data: [],
      }
      body.data.relationships.field_pcps_group = {
        data: [],
      }
    }
  }

  // Custom participants list
  // Otherwise go through job title and group arrays and set those fields
  else {
    body.data.relationships.field_pcps_job_title = {
      data: currentEvent.jobTitles.map((jobTitle: any) => {
        const activeJobTitle: any = jobTitles.data.find((item: any) => {
          return (
            (jobTitle.attributes &&
              item.attributes.drupal_internal__tid ===
                jobTitle.attributes.drupal_internal__tid) ||
            item.attributes.drupal_internal__tid === jobTitle.entity_id
          )
        })

        return {
          type: "taxonomy_term--job_titles",
          id: activeJobTitle.id,
          meta: {
            drupal_internal__target_id: jobTitle.entity_id,
          },
        }
      }),
    }

    body.data.relationships.field_pcps_group = {
      data: currentEvent.subGroups.map((group: any) => {
        const activeGroup: any = session.subGroups.data.find(
          (item: any) => item.entity_id === group.entity_id
        )
        return {
          type: "group--group",
          id: activeGroup.id,
          meta: {
            drupal_internal__target_id: group.entity_id,
          },
        }
      }),
    }
  }

  let response
  if (currentEvent.id) {
    body.data.id = currentEvent.id
    response = await fetchWrapper.patch(
      "/api/mm_rec_ext_event/recommended_external_event/" + currentEvent.id,
      session.token,
      JSON.stringify(body)
    )
  } else {
    response = await fetchWrapper.post(
      "/api/mm_rec_ext_event/recommended_external_event",
      session.token,
      JSON.stringify(body)
    )
  }

  let data
  if (response.ok) {
    data = await response.json()
    await thunkAPI.dispatch(
      saveExternalTimeBlocks({
        event: data.data,
        isRecommended: true,
        timeBlocks: currentEvent.timeBlocks,
      })
    )
  }

  if (
    currentEvent.image &&
    process.env.REACT_APP_API_URL &&
    currentEvent.imageFilename &&
    !currentEvent.image.includes(process.env.REACT_APP_API_URL)
  ) {
    const dataUrl = parseDataUrl(currentEvent.image)
    await fetch(
      process.env.REACT_APP_API_URL +
        "/api/mm_rec_ext_event/recommended_external_event/" +
        data.data.id +
        "/field_image",
      {
        credentials: "include",
        method: "POST",
        headers: new Headers({
          Accept: "application/vnd.api+json",
          "Content-Type": "application/octet-stream",
          "X-CSRF-Token": session.token,
          "Content-Disposition":
            'file; filename="' + he.encode(currentEvent.imageFilename) + '"',
        }),
        body: dataUrl ? dataUrl.toBuffer() : undefined,
      }
    )
  }
  thunkAPI.dispatch(fetchRecommendedEvents({}))
  return data.data
})

export const archiveRecommendedEvent = createAsyncThunk<
  ExternalEvent,
  any,
  { state: RootState }
>("externalPD/archiveRecommendedEvent", async (args, thunkAPI) => {
  const { session } = thunkAPI.getState()

  let body: any = {
    data: {
      type: "mm_rec_ext_event--recommended_external_event",
      id: args.id,
      attributes: {
        field_archive: true,
      },
    },
  }

  const response = await fetchWrapper.patch(
    "/api/mm_rec_ext_event/recommended_external_event/" + args.id,
    session.token,
    JSON.stringify(body)
  )

  if (response.ok) {
    const data = await response.json()
    return data.data
  }
})

export const unArchiveRecommendedEvent = createAsyncThunk<
  ExternalEvent,
  any,
  { state: RootState }
>("externalPD/unArchiveRecommendedEvent", async (args, thunkAPI) => {
  const { session } = thunkAPI.getState()

  let body: any = {
    data: {
      type: "mm_rec_ext_event--recommended_external_event",
      id: args.id,
      attributes: {
        field_archive: false,
      },
    },
  }

  const response = await fetchWrapper.patch(
    "/api/mm_rec_ext_event/recommended_external_event/" + args.id,
    session.token,
    JSON.stringify(body)
  )

  if (response.ok) {
    const data = await response.json()
    return data.data
  }
})

export const fetchRecommendedEvents = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("externalPD/fetchRecommendedEvents", async (args, thunkAPI) => {
  const { session, externalPD } = thunkAPI.getState()
  const filters: any = externalPD.recommended.filters
  const isGroupLevel =
    (session.isGroupAdmin || session.groupRoles.join().includes("scheduler")) &&
    !session.orgRoles.join().includes("admin") &&
    !session.orgRoles.join().includes("scheduler")

  const query: {
    start?: string
    end?: string
    group?: number
    title?: string
    archive?: string
    draft?: string
    category?: string
    sort_by?: string
    sort_order?: string
    page: {
      offset: number
    }
  } = {
    title: filters.search,
    sort_order: "DESC",
    page: {
      offset: externalPD.recommended.currentPage * 50,
    },
  }

  if (isGroupLevel) {
    query.group = session.subgroup.id[0].value
  }

  if (filters.status === "true") {
    query.archive = filters.status
  }
  if (filters.sort === "start_date") {
    query.sort_order = "DESC"
  } else if (filters.sort === "title") {
    query.sort_order = "ASC"
  }

  let response = await fetchWrapper.get(
    "/api/mm_rec_ext_event/hq/" +
      session.group.id[0].value +
      "?" +
      qs.stringify(query)
  )

  if (response.ok) {
    let requestData = await response.json()
    let data

    const keys = Object.keys(requestData.rec_ext_event_data)
    data = keys.map((key) => requestData.rec_ext_event_data[key])

    data.forEach((event: any) => {
      if (event.field_start_date_value && event.field_end_date_value) {
        event.field_start_date_value = UTCToLocalTime(
          event.field_start_date_value + "-04:00",
          session.user.attributes.timezone,
          "yyyy-MM-dd'T'HH:mm:ss"
        )
        event.field_end_date_value = UTCToLocalTime(
          event.field_end_date_value + "-04:00",
          session.user.attributes.timezone,
          "yyyy-MM-dd'T'HH:mm:ss"
        )
      }
    })

    return { data, totalPages: Math.ceil(requestData.total_records / 50) }
  }
})

export const increaseRecommendedPage = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("externalPD/increaseRecommendedPage", async (args, thunkAPI) => {
  thunkAPI.dispatch(fetchRecommendedEvents(args))
  return args
})

export const fetchRecommendedEventOverview = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("externalPD/fetchRecommendedEventOverview", async (args, thunkAPI) => {
  const { id } = args
  let response = await fetchWrapper.get(
    "/api/recommended-external-event-view/" + id
  )
  let rating = await fetchWrapper.get("/api/external-event-rating/" + id)

  if (response.ok && rating.ok) {
    let data = await response.json()
    let ratingData = await rating.json()

    data.rating = Number(ratingData.rows[0].field_rating).toFixed(1)
    data.data = data.rows.content ? [] : data.rows

    return data
  }
})

const debouncedFetchRecommended = debounceThunk(fetchRecommendedEvents, 750)
export const updateRecommendedFilters = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("externalPD/updateRecommendedFilters", async (args, thunkAPI) => {
  thunkAPI.dispatch(debouncedFetchRecommended(args))
  return args
})

const debouncedFetchRequests = debounceThunk(fetchExternalRequests, 750)
export const updateFilters = createAsyncThunk<any, any, { state: RootState }>(
  "externalPD/updateFilters",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedFetchRequests(args))
    return args
  }
)

type InitialState = {
  pending: {
    data: any[]
    fetched: boolean
    isFetchingMore: boolean
    total: number
    currentPage: number
    totalPages: number
    filters: {
      search: string
      status: string
      subgroup: string | number
      category: CategoryTaxonomy | null
      sort: string
    }
  }
  reviewed: {
    data: any[]
    fetched: boolean
    isFetchingMore: boolean
    total: number
    currentPage: number
    totalPages: number
    filters: {
      status: string
      subgroup: string | number
      search: string
      category: CategoryTaxonomy | null
      sort: string
    }
  }
  recommended: {
    data: any[]
    fetched: boolean
    total: number
    isFetchingMore: boolean
    currentPage: number
    totalPages: number
    filters: {
      search: string
      sort: string
      status: string
    }
  }
  currentAttachment: {
    extension: string
    file: string
    filename: string
    published: boolean
  } | null
  currentEvent: {
    id: string | null
    drupal_internal__id: string | null
    archived: boolean
    isOpen: boolean
    title: string
    description: string
    isDateless: boolean
    startDate: string
    endDate: string
    comments: string
    isSaving: boolean
    image: string | null
    imageFilename: string | null
    participantMethod: string
    jobTitles: any[]
    subGroups: any[]
    timeBlocks: {
      id: string | null
      hours: number
      minutes: number
      categoryId: number | null
    }[]
    overview: {
      fetched: boolean
      avgRating: number
      data: any[]
    }
  }
}

const initialState: InitialState = {
  pending: {
    data: [],
    fetched: false,
    isFetchingMore: false,
    total: 0,
    currentPage: 0,
    totalPages: 1,
    filters: {
      search: "",
      status: "all",
      subgroup: "all",
      category: null,
      sort: "changed-newest",
    },
  },
  reviewed: {
    data: [],
    fetched: false,
    isFetchingMore: false,
    total: 0,
    currentPage: 0,
    totalPages: 1,
    filters: {
      status: "not_pending",
      search: "",
      subgroup: "all",
      category: null,
      sort: "changed-newest",
    },
  },
  recommended: {
    data: [],
    fetched: false,
    isFetchingMore: false,
    total: 0,
    currentPage: 0,
    totalPages: 1,
    filters: {
      search: "",
      sort: "start_date",
      status: "false",
    },
  },
  currentAttachment: null,
  currentEvent: {
    archived: false,
    id: null,
    drupal_internal__id: null,
    isOpen: false,
    title: "",
    description: "",
    isDateless: false,
    startDate: moment().format(),
    endDate: moment().add(1, "hours").format(),
    comments: "",
    isSaving: false,
    image: null,
    imageFilename: null,
    participantMethod: "all",
    jobTitles: [],
    subGroups: [],
    timeBlocks: [
      {
        id: null,
        hours: 0,
        minutes: 0,
        categoryId: null,
      },
    ],
    overview: {
      fetched: false,
      avgRating: 0,
      data: [],
    },
  },
}

export const externalPDSlice = createSlice({
  name: "externalPD",
  initialState,
  reducers: {
    openEvent: (state, action) => {
      const event = action.payload
      let participantMethod =
        event.pcps_group_ids || event.pcps_job_title_ids ? "custom" : "all"

      state.currentEvent = {
        id: event.uuid,
        drupal_internal__id: event.id,
        archived: event.field_archive_value === "1" ? true : false,
        isOpen: true,
        title: event.title,
        image: event.image,
        imageFilename: null,
        isDateless: !event.field_start_date_value,
        description: event.description__value
          ? he.decode(event.description__value.replaceAll("<[^>]*>", ""))
          : "",
        startDate: moment(event.field_start_date_value).format(),
        endDate: moment(event.field_end_date_value).format(),
        comments: event.field_learner_comment_value,
        isSaving: false,
        participantMethod,

        jobTitles: event.pcps_job_title_ids
          ? event.pcps_job_title_ids.split(",").map((id: string) => {
              return {
                entity_id: Number(id),
              }
            })
          : [],

        subGroups: event.pcps_group_ids
          ? event.pcps_group_ids.split(",").map((id: string) => {
              return {
                entity_id: Number(id),
              }
            })
          : [],

        timeBlocks: event.category_data.map((block: any) => {
          let totalMinutes = Number(block.category_time_spent)
          return {
            id: block.uuid,
            hours: convertMinutesToHoursMinutes(totalMinutes).hours,
            minutes: convertMinutesToHoursMinutes(totalMinutes).minutes,
            categoryId: block.category_uuid,
          }
        }),

        overview: {
          fetched: false,
          avgRating: 0,
          data: [],
        },
      }
    },
    startNewExternalEvent: (state, action) => {
      state.currentEvent = initialState.currentEvent
    },
    toggleExternalEventModal: (state, action) => {
      state.currentEvent.isOpen = action.payload
      if (!action.payload) {
        state.currentEvent = initialState.currentEvent
      }
    },
    updateRequestTimeBlock: (state, action) => {
      const { eventId, timeBlock } = action.payload
      const { index, value } = timeBlock

      let targetEvent = state.pending.data.find(
        (event: any) => event.id === eventId
      )
      targetEvent.timeBlocks[index] = value
    },
    deleteRequestTimeBlock: (state, action) => {
      const { eventId, timeBlock } = action.payload

      let targetEvent = state.pending.data.find(
        (event: any) => event.id === eventId
      )
      targetEvent.timeBlocks = targetEvent.timeBlocks.filter(
        (block: any) => block.id !== timeBlock.id
      )
    },
    setCurrentAttachment: (state, action) => {
      state.currentAttachment = action.payload
    },
    addRequestTimeBlock: (state, action) => {
      const { eventId, newBlock } = action.payload

      let targetEvent = state.pending.data.find(
        (event: any) => event.id === eventId
      )
      targetEvent.timeBlocks.push(newBlock)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(increaseRequestPage.pending, (state, action) => {
      const { status } = action.meta.arg
      if (status === "pending") {
        state.pending.currentPage++
        state.pending.isFetchingMore = true
      }
    })

    // Fetch requests
    builder.addCase(fetchExternalRequests.fulfilled, (state, action) => {
      const { status, data, total } = action.payload

      if (status === "pending") {
        state.pending.fetched = true
        state.pending.isFetchingMore = false
        state.pending.data = state.pending.currentPage
          ? state.pending.data.concat(data)
          : data
        state.pending.total = total
        state.pending.totalPages = Math.ceil(total / 25)
      }
      if (status === "reviewed") {
        state.reviewed.fetched = true
        state.reviewed.isFetchingMore = false
        state.reviewed.data = state.reviewed.currentPage
          ? state.reviewed.data.concat(data)
          : data
        state.reviewed.total = total
        state.reviewed.totalPages = Math.ceil(total / 25)
      }
    })

    builder.addCase(increaseRecommendedPage.pending, (state) => {
      state.recommended.currentPage++
      state.recommended.isFetchingMore = true
    })

    // Fetch recommended events
    builder.addCase(fetchRecommendedEvents.pending, (state, action) => {
      const { field, value } = action.meta.arg

      state.recommended.fetched = false
      state.recommended.isFetchingMore = false
      state.recommended.filters[
        field as keyof typeof state.recommended.filters
      ] = value
      if (field && value) {
        state.recommended.currentPage = 0
        state.recommended.data = []
      }
    })

    builder.addCase(fetchRecommendedEvents.fulfilled, (state, action) => {
      state.recommended.fetched = true
      state.recommended.data = state.recommended.data.concat(
        action.payload.data
      )
      state.recommended.totalPages = action.payload.totalPages
    })

    // Archive an event and remove it from data
    builder.addCase(archiveRecommendedEvent.pending, (state, action) => {
      state.recommended.data = state.recommended.data.filter(
        (event: any) => event.uuid !== action.meta.arg.id
      )
    })

    // Archive an event and remove it from data
    builder.addCase(unArchiveRecommendedEvent.pending, (state, action) => {
      state.recommended.data = state.recommended.data.filter(
        (event: any) => event.uuid !== action.meta.arg.id
      )
      state.currentEvent = initialState.currentEvent
    })

    // Save a recommended event
    builder.addCase(saveRecommendedEvent.pending, (state, action) => {
      state.currentEvent.isSaving = true
    })
    builder.addCase(saveRecommendedEvent.fulfilled, (state, action) => {
      state.currentEvent.isSaving = false
    })

    // Get a recommended event's overview report
    builder.addCase(
      fetchRecommendedEventOverview.fulfilled,
      (state, action) => {
        state.currentEvent.overview.fetched = true
        state.currentEvent.overview.data = action.payload.data
        state.currentEvent.overview.avgRating = action.payload.rating
      }
    )

    // Update filters
    builder.addCase(updateRecommendedFilters.pending, (state, action) => {
      const { field, value } = action.meta.arg
      state.recommended.filters[
        field as keyof typeof state.recommended.filters
      ] = value
    })

    builder.addCase(updateFilters.pending, (state, action) => {
      const { status, field, value } = action.meta.arg
      if (status === "pending") {
        state.pending.currentPage = 0
        state.pending.fetched = false
        state.pending.filters[field as keyof typeof state.pending.filters] =
          value
      }
      if (status === "reviewed") {
        state.reviewed.currentPage = 0
        state.reviewed.fetched = false
        state.reviewed.filters[field as keyof typeof state.pending.filters] =
          value
      }
    })

    // Approve / Decline
    builder.addCase(approveExternalRequest.pending, (state, action) => {
      let targetEvent: any = state.pending.data.find(
        (event: any) => event.id === action.meta.arg.event.id
      )
      targetEvent.field_status_value = "accepted"
      state.pending.data = state.pending.data.filter(
        (event: any) => event.id !== action.meta.arg.event.id
      )
      state.reviewed.data.push(targetEvent)
    })

    builder.addCase(declineExternalRequest.pending, (state, action) => {
      let targetEvent: any = state.pending.data.find(
        (event: any) => event.id === action.meta.arg.event.id
      )
      targetEvent.field_status_value = "declined"
      targetEvent.field_rev_comment_value = action.meta.arg.comments
      targetEvent.field_reviewed_date_value = moment().format(
        "YYYY-MM-DDTHH:mm:ss"
      )
      state.pending.data = state.pending.data.filter(
        (event: any) => event.id !== action.meta.arg.event.id
      )
      state.reviewed.data.push(targetEvent)
    })

    // Reopen and stick back into Pending
    builder.addCase(reopenExternalRequest.pending, (state, action) => {
      let targetEvent: any = state.reviewed.data.find(
        (event: any) => event.id === action.meta.arg.event.id
      )
      targetEvent.field_status_value = "pending"
      state.reviewed.data = state.reviewed.data.filter(
        (event: any) => event.id !== action.meta.arg.event.id
      )
      state.pending.data.push(targetEvent)
    })
  },
})

export const {
  openEvent,
  startNewExternalEvent,
  toggleExternalEventModal,
  updateRequestTimeBlock,
  setCurrentAttachment,
  deleteRequestTimeBlock,
  addRequestTimeBlock,
} = externalPDSlice.actions

export default externalPDSlice.reducer
