import { Component } from "react"
import { connect } from "react-redux"
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import Autocomplete from "@mui/material/Autocomplete"
import _ from "lodash"
import ImageUpload from "../../components/ImageUpload"
import CourseSelect from "../courses/CourseSelect"
import CategoryIcon from "../../components/CategoryIcon"
import { generateVideoEmbedURL } from "@mobilemind/common/src/functions/index"
import LearningPathNavbarContainer from "./LearningPathNavbarContainer"
import { fetchLearningPathTypes } from "../learningPathTypes/learningPathTypesSlice"
import { convertEstimatedTime } from "@mobilemind/common/src/functions/index"
import DeleteButton from "../../components/DeleteButton"
import classNames from "classnames"
import { getSubGroups, getJobTitles } from "../../store/reducers/session"
import Padlock from "../../img/padlock.svg"

import { isGroupLevel } from "../../functions"

import Loading from "@mobilemind/common/src/components/Loading"

import {
  fetchActiveLearningPath,
  updateField,
  removeImage,
  setFilename,
  setImage,
  addCourse,
  removeCourse,
  startNewLearningPath,
  dropCourse,
  updateLPSearch,
} from "./activeLearningPathSlice"

import "../../styles/create.scss"
import ErrorBoundary from "../../components/ErrorBoundary"

import { withRouter } from "react-router-dom"
import { SettingSwitch } from "@mobilemind/common/src/components/SettingSwitch"

const mapStateToProps = (
  { session, categories, learningPaths, learningPathTypes, activeLearningPath },
  ownProps
) => {
  return {
    learningPathId: ownProps.match.params.id,
    session,
    subGroups: session.subGroups,
    learningPaths,
    learningPath: activeLearningPath,
    learningPathTypes,
    categories,
  }
}

const mapDispatchToProps = {
  getSubGroups,
  getJobTitles,
  fetchActiveLearningPath,
  fetchLearningPathTypes,
  updateField,
  removeImage,
  setFilename,
  setImage,
  addCourse,
  removeCourse,
  startNewLearningPath,
  updateLPSearch,
  dropCourse,
}

class CreateLearningPathLayout extends Component {
  componentDidMount() {
    const { session, learningPathId, learningPathTypes } = this.props
    const { subGroups, jobTitles } = session

    learningPathId !== "new" &&
      this.props.fetchActiveLearningPath(learningPathId)
    learningPathId === "new" && this.props.startNewLearningPath()

    !learningPathTypes.fetched && this.props.fetchLearningPathTypes()

    if (!subGroups.fetched) {
      this.props.getSubGroups(session.group.uuid[0].value)
    }
    if (!jobTitles.fetched) {
      this.props.getJobTitles()
    }
  }
  render() {
    const {
      session,
      learningPathId,
      learningPath,
      categories,
      learningPathTypes,
      learningPaths,
    } = this.props
    let { subGroups, jobTitles } = session

    let videoEmbed

    if (learningPath.videoLink) {
      videoEmbed = generateVideoEmbedURL(learningPath.videoLink)
    }
    let filteredLearningPaths
    if (learningPathId !== "new") {
      filteredLearningPaths = [
        ...learningPath.relatedLPResults.filter(
          (path) => path.field_lp_id !== Number(learningPathId)
        ),
      ]
    } else {
      filteredLearningPaths = [...learningPath.relatedLPResults]
    }

    let fullPrereqPath
    if (learningPath.prerequisitePath !== "none") {
      if (typeof learningPath.prerequisitePath === "string") {
        fullPrereqPath = learningPaths.data.find(
          (path) => path.id === learningPath.prerequisitePath
        )
      }
    }

    if (!learningPath.fetched) {
      return <Loading fullPage={true} message={"Getting learning path..."} />
    }

    if (session.subgroups && session.subgroups.data && isGroupLevel(session)) {
      subGroups = session.subgroups
    } else {
      subGroups = session.subGroups
    }

    // Single group member
    let isSingleGroupMember =
      isGroupLevel(session) && subGroups.data.length === 1

    let ownerValue
    if (isGroupLevel(session)) {
      ownerValue = learningPath.subGroupOwner
        ? learningPath.subGroupOwner
        : subGroups.data[0] && subGroups.data[0].id
    } else {
      ownerValue = learningPath.subGroupOwner
        ? learningPath.subGroupOwner
        : "org"
      let canFindGroup = subGroups.data.find(
        (group) => group.id === learningPath.subGroupOwner
      )

      if (!canFindGroup && ownerValue !== "org") {
        let byEntityId = subGroups.data.find(
          (group) => group.id === learningPath.subGroupOwner
        )
        if (byEntityId) {
          ownerValue = byEntityId.id
        }
      }
    }

    return (
      <ErrorBoundary>
        <div className="page create learningPath">
          <LearningPathNavbarContainer history={this.props.history} />

          <div className="flexRow">
            <div className="column left">
              <div
                className={classNames(
                  "required",
                  learningPath.missingFields.includes("Learning Path Name") &&
                    "active"
                )}
              >
                <TextField
                  variant="standard"
                  label="Learning Path Name"
                  required
                  value={learningPath.name ? learningPath.name : ""}
                  inputProps={{ maxLength: 50 }}
                  helperText={"Maximum length is 50 characters."}
                  onChange={(event) =>
                    this.props.updateField({
                      field: "name",
                      value: event.target.value,
                    })
                  }
                />
              </div>
              <div
                className={classNames(
                  "required",
                  learningPath.missingFields.includes("Learning Path Image") &&
                    "active"
                )}
              >
                <ImageUpload
                  helperText={
                    "For best results, we recommend an image sized at least 350x150."
                  }
                  shape={"rectangle"}
                  activeImage={learningPath.image}
                  setImage={setImage}
                  setFilename={setFilename}
                  removeImage={removeImage}
                />
              </div>
              {!learningPath.field_draft && (
                <FormControlLabel
                  className="checkbox"
                  control={
                    <Checkbox
                      checked={learningPath.archived}
                      onChange={(event) => {
                        this.props.updateField({
                          field: "archived",
                          value: event.target.checked,
                        })
                      }}
                      name="archived"
                    />
                  }
                  label="Archived"
                />
              )}

              {learningPathTypes.data.length > 0 && (
                <FormControl variant="standard">
                  <InputLabel required id="label-type-select">
                    Type
                  </InputLabel>
                  <Select
                    variant="standard"
                    labelId="label-type-select"
                    id="type-select"
                    value={
                      learningPath.type
                        ? learningPath.type
                        : learningPathTypes.data[0].id
                    }
                    onChange={(event) =>
                      this.props.updateField({
                        field: "type",
                        value: event.target.value,
                      })
                    }
                  >
                    {_.orderBy(learningPathTypes.data, [
                      (type) => type.attributes.name.toUpperCase(),
                    ]).map((type) => {
                      return (
                        <MenuItem key={type.id} value={type.id}>
                          {type.attributes.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              )}

              <div
                className={classNames(
                  "required",
                  learningPath.missingFields.includes("Learning Path Image") &&
                    "active"
                )}
              >
                <TextField
                  variant="standard"
                  label="Description"
                  required
                  helperText="Briefly describe what the teacher will learn."
                  multiline
                  value={
                    learningPath.body
                      ? learningPath.body.replace(/(<([^>]+)>)/gi, "")
                      : ""
                  }
                  onChange={(event) =>
                    this.props.updateField({
                      field: "body",
                      value: event.target.value,
                    })
                  }
                />
              </div>

              <TextField
                variant="standard"
                label="Intro Video (optional)"
                helperText={
                  "Enter the URL for a video to include. YouTube, Vimeo, Google Drive, and ScreenPal videos are currently supported. Note: Copy and paste the URL to view the video, do not use a share button or embed code."
                }
                value={learningPath.videoLink ? learningPath.videoLink : ""}
                onChange={(event) =>
                  this.props.updateField({
                    field: "videoLink",
                    value: event.target.value,
                  })
                }
              />

              {learningPath.videoLink && (
                <iframe
                  title="Course Video"
                  id="ytplayer"
                  type="text/html"
                  width="300"
                  height="168"
                  src={videoEmbed}
                  frameBorder="0"
                ></iframe>
              )}

              <FormControl variant="standard" sx={{ marginBottom: 0 }}>
                <Autocomplete
                  id="prereq-learningPath-select"
                  noOptionsText={
                    !learningPath.relatedLPSFetched
                      ? "Getting learning paths..."
                      : ""
                  }
                  loading={!learningPath.relatedLPSFetched}
                  options={filteredLearningPaths}
                  value={
                    fullPrereqPath
                      ? fullPrereqPath
                      : learningPath.prerequisitePath
                  }
                  getOptionLabel={(learningPath) => {
                    return learningPath.name ?? "None"
                  }}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label="Prerequisite Learning Path"
                      helperText={
                        "A Learning Path that must be completed first before unlocking this path."
                      }
                      value={learningPath.relatedLPSearch}
                      onChange={(event) => {
                        this.props.updateLPSearch(event.target.value)
                      }}
                    />
                  )}
                  onChange={(event, newValue) =>
                    this.props.updateField({
                      field: "prerequisitePath",
                      value: newValue,
                    })
                  }
                />
              </FormControl>

              {subGroups.data.length > 0 && (
                <>
                  <h3 style={{ marginTop: -15 }}>Learning Path Owner</h3>

                  <FormControl
                    variant="standard"
                    className={classNames(
                      "inputSelect subGroupSelect",
                      (isSingleGroupMember || !isGroupLevel(session)) &&
                        "locked"
                    )}
                  >
                    <Select
                      variant="standard"
                      labelId="label-subgroup-owner-select"
                      id="subgroup-owner-select"
                      value={ownerValue}
                      onChange={(event) =>
                        this.props.updateField({
                          field: "subGroupOwner",
                          value: event.target.value,
                        })
                      }
                    >
                      {this.props.session.orgRoles.includes(
                        "organization-admin"
                      ) && (
                        <MenuItem key={0} value={"org"}>
                          Org - {this.props.session.group.label[0].value}
                        </MenuItem>
                      )}
                      {subGroups.data.map((group) => {
                        return (
                          <MenuItem key={group.id} value={group.id}>
                            {group.attributes.label}
                          </MenuItem>
                        )
                      })}
                    </Select>

                    <p
                      style={{
                        fontSize: "0.75rem",
                        color: "rgba(0, 0, 0, 0.54)",
                        margin: "10px 0 0",
                        lineHeight: "1.4",
                      }}
                    >
                      {ownerValue === "org" ? (
                        <>
                          Organization Admins and Creators will be able to edit
                          this learning path.
                        </>
                      ) : (
                        <>
                          Group Admins and Creators in{" "}
                          {isSingleGroupMember ? (
                            <>your group</>
                          ) : (
                            <>this group</>
                          )}{" "}
                          will be able to edit this learning path. Organization
                          Admins and Creators will also be able to edit.
                        </>
                      )}
                    </p>
                  </FormControl>
                </>
              )}

              {subGroups.data.length > 0 && (
                <>
                  <div
                    className={classNames(
                      "required",
                      learningPath.missingFields.includes(
                        "Make visible to at least 1 group"
                      ) && "active"
                    )}
                  >
                    <h3>Group Visibility</h3>

                    <FormControl
                      variant="standard"
                      className={classNames(
                        "subGroupSelect",
                        isSingleGroupMember && "locked"
                      )}
                    >
                      <Autocomplete
                        multiple
                        id="subgroup-select"
                        options={
                          subGroups.data.length > 0 ? subGroups.data : []
                        }
                        value={
                          isSingleGroupMember
                            ? subGroups.data
                            : learningPath.subGroups
                        }
                        getOptionLabel={(option) => option.attributes.label}
                        onChange={(event, values) =>
                          this.props.updateField({
                            field: "subGroups",
                            value: values,
                          })
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={
                              learningPath.subGroups.length
                                ? "Add Groups"
                                : "All Groups"
                            }
                            variant="standard"
                          />
                        )}
                      />
                    </FormControl>
                    <p
                      style={{
                        fontSize: "0.75rem",
                        color: "rgba(0, 0, 0, 0.54)",
                        margin: isSingleGroupMember ? "-20px 0 0" : "-10px 0 0",
                        lineHeight: "1.4",
                      }}
                    >
                      Learners in{" "}
                      {isSingleGroupMember ? (
                        <>your group</>
                      ) : (
                        <>these groups</>
                      )}{" "}
                      will be able to view the learning path in the Learn app.
                    </p>
                  </div>
                </>
              )}

              {jobTitles.data.length > 0 && (
                <>
                  <h3>Job Title Visibility</h3>

                  <FormControl
                    variant="standard"
                    className={classNames("inputSelect subGroupSelect")}
                  >
                    <Autocomplete
                      multiple
                      id="subgroup-select"
                      options={jobTitles.data.length > 0 ? jobTitles.data : []}
                      value={learningPath.jobTitles}
                      getOptionLabel={(option) => option.attributes.name}
                      onChange={(event, values) =>
                        this.props.updateField({
                          field: "jobTitles",
                          value: values,
                        })
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={
                            learningPath.jobTitles.length
                              ? "Add Job Titles"
                              : "All Job Titles"
                          }
                          variant="standard"
                        />
                      )}
                    />
                  </FormControl>
                </>
              )}

              <div
                className={classNames(
                  "required",
                  learningPath.missingFields.includes(
                    "Include at least 1 course"
                  ) && "active"
                )}
              >
                <h3>Learning Path Courses</h3>

                {learningPath.courses.length > 1 && (
                  <SettingSwitch
                    label="Lock Last Course"
                    checked={learningPath.lastCourseLocked}
                    helperText={
                      <span>
                        If turned on, learners will be required to complete all
                        other courses before starting the last course,{" "}
                        <strong>
                          {
                            learningPath.courses[
                              learningPath.courses.length - 1
                            ].attributes.name
                          }
                          .
                        </strong>
                      </span>
                    }
                    onChange={(checked) => {
                      this.props.updateField({
                        field: "lastCourseLocked",
                        value: checked,
                      })
                    }}
                  />
                )}

                <DragDropContext
                  onDragEnd={(droppedItem) => {
                    this.props.dropCourse(droppedItem)
                  }}
                >
                  <Droppable droppableId={"course-list"}>
                    {(provided) => (
                      <ul
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="courseList"
                      >
                        {learningPath.courses.length > 0 &&
                          learningPath.courses.map((course, index) => {
                            const isLastCourse =
                              learningPath.lastCourseLocked &&
                              index === learningPath.courses.length - 1

                            let category
                            if (!course.category) {
                              category = categories.data.find(
                                (cat) =>
                                  course.relationships.field_category.data &&
                                  cat.id ===
                                    course.relationships.field_category.data.id
                              )
                            } else {
                              category = course.category
                            }
                            return (
                              <Draggable
                                key={course.id}
                                draggableId={course.id}
                                index={index}
                              >
                                {(provided) => (
                                  <li
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className={classNames(
                                      isLastCourse && "lastCourse"
                                    )}
                                  >
                                    <span className="icon hamburger" />
                                    {category && (
                                      <CategoryIcon category={category} />
                                    )}
                                    <span className="name">
                                      {course.attributes.name}
                                    </span>
                                    {isLastCourse && (
                                      <img
                                        src={Padlock}
                                        alt="Padlock"
                                        style={{
                                          opacity: 0.7,
                                          width: 15,
                                          height: 15,
                                          marginRight: 5,
                                          marginLeft: 5,
                                        }}
                                      />
                                    )}

                                    <DeleteButton
                                      onClick={() =>
                                        this.props.removeCourse(course)
                                      }
                                    />
                                  </li>
                                )}
                              </Draggable>
                            )
                          })}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>

                {learningPath.estimatedTime > 0 && (
                  <>
                    <h3>Estimated Time</h3>
                    <span className="icon time" />
                    <span className="count">
                      {convertEstimatedTime(learningPath.estimatedTime)}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="column right">
              <CourseSelect
                coursesAdded={learningPath.courses}
                addCourse={addCourse}
                removeCourse={removeCourse}
              />
            </div>
          </div>
        </div>
      </ErrorBoundary>
    )
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateLearningPathLayout)
)
