import { connect } from "react-redux"
import NavbarSecondary from "../../components/NavbarSecondary"
import {
  generateMTComplianceExportURL,
  generateReportExportURL,
  generateRubricComplianceExportURL,
} from "../../functions"

import "../../styles/navbar.scss"

const mapStateToProps = ({ compliance, session }) => {
  return {
    compliance,
    session,
  }
}

function ComplianceNavbarContainer(props) {
  const { session, compliance } = props
  const { filters } = compliance
  const { orgRoles, subgroup, subGroups, isPartner, group } = session

  const baseUrl =
    orgRoles.includes("organization-admin") ||
    orgRoles.includes("organization-reporter")
      ? "compliance-"
      : "group-compliance-"

  const subGroupId = subgroup && subgroup.id[0].value

  const exportInfo = {
    reportType: baseUrl + filters.contentType,
    orgId:
      orgRoles.includes("organization-admin") ||
      orgRoles.includes("organization-reporter")
        ? group.id[0].value
        : subGroupId,
    subGroup: subGroups.data.find(
      (group) => group.attributes.label === filters.group
    ),
    filters,
  }

  const isOrgLevel =
    orgRoles.includes("organization-admin") ||
    orgRoles.includes("organization-reporter") ||
    orgRoles.includes("organization-observation_admin")

  let exportLink
  if (filters.contentType === "rubric") {
    exportLink = generateRubricComplianceExportURL({
      session,
      isOrgLevel,
      compliance,
    })
  } else if (filters.contentType === "mandated-training") {
    exportLink = generateMTComplianceExportURL({
      session,
      isOrgLevel,
      compliance,
    })
  } else {
    exportLink = generateReportExportURL(exportInfo)
  }

  let hideExportLink = filters.contentType === "knowbe4"

  if (filters.contentType === "rubric") {
    if (!filters.rubric || !compliance.data.length) {
      hideExportLink = true
    }
  }

  const navbarProps = {
    type: "Progress Tracking",
    iconClass: "icon compliance",
    onHover: () => {},
    activeItem: {},
    exportLink,

    isPartner: isPartner,
    isConference:
      filters.activeEvent &&
      filters.activeEvent.field_award_credit_for_conferenc,
    eventId: filters.activeEvent && filters.activeEvent.id,
    filters,
    hideExportLink,
    session: session,
  }

  return <NavbarSecondary {...navbarProps} />
}

export default connect(mapStateToProps)(ComplianceNavbarContainer)
