import { Component } from "react"
import { connect } from "react-redux"

import {
  saveMandatedTraining,
  deleteMandatedTraining,
} from "../../actions/mandatedTraining"
import { startNewMT } from "./activeMandatedTrainingSlice"
import NavbarSecondary from "../../components/NavbarSecondary"
import ModalDelete from "../../components/ModalDelete"

//import ModalLearningPathPreview from "../../components/ModalLearningPathPreview"

import { getCategories } from "../../store/reducers/categories"
import { isArrayIdentical, isGroupLevel } from "../../functions"
import { updateField } from "./activeMandatedTrainingSlice"

import "../../styles/navbar.scss"
import Loading from "@mobilemind/common/src/components/Loading"

const mapStateToProps = ({ activeMandatedTraining, categories, session }) => {
  return {
    categories,
    activeTraining: activeMandatedTraining,
    session,
  }
}

const mapDispatchToProps = {
  saveMandatedTraining,
  updateField,
  deleteMandatedTraining,
  startNewMT,
  getCategories,
}

class MandatedTrainingNavbarContainer extends Component {
  state = {
    isDeleteOpen: false,
    isPreviewOpen: false,
    isSaving: false,
  }

  componentDidMount = () => {
    !this.props.categories.fetched && this.props.getCategories()
  }

  saveDraft = async () => {
    this.setState({ isSaving: true })
    await this.props.saveMandatedTraining(this.props.activeTraining, true)

    this.props.startNewMT()
    this.props.history.push("/create/mandated-training")

    this.setState({ isSaving: false })
  }

  deleteDraft = () => {
    this.props.deleteMandatedTraining({ uuid: this.props.activeTraining.id })
  }

  publish = async () => {
    const { activeTraining } = this.props
    this.setState({ isSaving: true })

    const hasSameCourses =
      !activeTraining.originalCourses.length ||
      isArrayIdentical(activeTraining.originalCourses, activeTraining.courses)

    const priorCreditSwitchedToFalse =
      activeTraining.originalNoPriorCredit &&
      !activeTraining.field_no_prior_credit

    await this.props.saveMandatedTraining(
      activeTraining,
      false,
      hasSameCourses,
      priorCreditSwitchedToFalse
    )

    this.setState({ isSaving: false })
    this.props.history.push("/create/mandated-training")
  }

  highlightFields = (missingFields) => {
    this.props.updateField({ field: "missingFields", value: missingFields })
  }

  render() {
    const { activeTraining, session } = this.props

    let missingFields = []
    let missingDraftFields = []

    !activeTraining.name && missingFields.push("Name")
    !activeTraining.name && missingDraftFields.push("Name")
    !activeTraining.image && missingFields.push("Image")
    !activeTraining.body && missingFields.push("Description")
    !activeTraining.courses.length &&
      missingFields.push("Include at least 1 course")

    if (
      isGroupLevel(session) &&
      session.subgroups &&
      session.subgroups.data &&
      session.subgroups.data.length > 1
    ) {
      if (!activeTraining.subGroups.length) {
        missingFields.push("Make visible to at least 1 group")
      }
    }

    const draftCourses = activeTraining.courses.filter(
      (course) => course.attributes.field_draft
    )
    if (draftCourses.length) {
      missingFields.push(
        <>
          Draft courses must be either published or removed from this training.
          <ul style={{ marginTop: 10 }}>
            {draftCourses.map((course) => {
              return <li>{course.attributes.name}</li>
            })}
          </ul>
        </>
      )
    }

    let canSave = activeTraining.name
    let canPublish = !missingFields.length

    const props = {
      type: "Mandated Training",
      iconClass: "icon mandated white",
      publish: this.publish,
      saveDraft: this.saveDraft,
      onHover: this.highlightFields,
      openDeleteModal: () => {
        this.setState({ isDeleteOpen: true })
      },
      activeItem: activeTraining,
      canSave,
      canPublish,
      missingFields,
      missingDraftFields,
      groupRoles: session.groupRoles,
      orgRoles: session.orgRoles,
    }

    return (
      <>
        {this.state.isSaving && (
          <Loading message={"Saving mandated training..."} fullPage={true} />
        )}
        <ModalDelete
          type={"mandated training"}
          open={this.state.isDeleteOpen}
          path={"/create/mandated-training"}
          deleteItem={this.deleteDraft}
          onClose={() => {
            this.setState({ isDeleteOpen: false })
          }}
        />
        <NavbarSecondary {...props} />
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MandatedTrainingNavbarContainer)
