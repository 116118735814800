export enum SkillLevel {
  Basic = "Basic",
  Intermediate = "Intermediate",
  Advanced = "Advanced",
}

export enum SubmissionType {
  MultipleChoice = "Multiple Choice",
  Text = "Text",
  Url = "URL",
  Image = "Image",
  File = "File",
  Checkbox = "Checkbox",
  Quiz = "Quiz",
}
