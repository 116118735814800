import { SubmissionType } from "@mobilemind/common/src/types/course"
import { Button, Menu, MenuItem, Stack } from "@mui/material"
import { SecondaryNavbar } from "components/SecondaryNavbar"
import { useFormikContext } from "formik"
import { isEmpty } from "lodash"
import { MouseEvent, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { selectUserCanShare } from "store/selectors"
import { deleteCourse, saveCourse } from "../../actions/courses"
import ModalCopy from "../../components/ModalCopy"
import ModalDelete from "../../components/ModalDelete"
import { ReactComponent as CommunityIcon } from "../../img/community-white.svg"
import { ReactComponent as CopyIcon } from "../../img/copy.svg"
import { ReactComponent as ArchiveIcon } from "../../img/i-archive.svg"
import { ReactComponent as DraftIcon } from "../../img/i-draft.svg"
import { ReactComponent as TrashIcon } from "../../img/i-trash-white.svg"
import { ReactComponent as LaptopIcon } from "../../img/laptop-white.svg"
import { ReactComponent as PreviewIcon } from "../../img/preview.svg"
import { ReactComponent as PublishIcon } from "../../img/publish.svg"
import { ReactComponent as ShareIcon } from "../../img/share.svg"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { CourseSubmitButton } from "./components/CourseSubmitButton"
import { CourseCardPreview } from "./components/coursePreviews/CourseCardPreview"
import { ExtensionPreviewContainer } from "./components/coursePreviews/ExtensionPreviewContainer"
import { MobileAppPreviewModal } from "./components/coursePreviews/MobileAppPreviewModal"
import { CourseFormValues } from "./types"

type CourseFormNavbarProps = {
  openShareDialog: () => void
}

export const CourseFormNavbar = (props: CourseFormNavbarProps) => {
  const { openShareDialog } = props
  const history = useHistory()
  const dispatch = useAppDispatch()

  const { values, errors, setSubmitting } = useFormikContext<CourseFormValues>()
  const { id: courseId } = useParams<{
    id: string
  }>()

  const userCanShare = useAppSelector(selectUserCanShare)
  const session = useAppSelector((state) => state.session)

  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const [previewType, setPreviewType] = useState<string | null>(null)
  const [isCopyOpen, setIsCopyOpen] = useState(false)

  const [previewAnchorEl, setPreviewAnchorEl] =
    useState<HTMLButtonElement | null>(null)

  const canViewInLearn =
    !values.isTemplate && !values.field_draft && values.id && !values.archived

  const saveDraft = async () => {
    setSubmitting(true)
    await dispatch(
      saveCourse({
        activeCourse: values,
        isDraft: true,
        readyToPublish: isEmpty(errors),
      })
    )
    setSubmitting(false)
    history.push("/create/courses")
  }

  const deleteDraft = async () => {
    if (!isDeleteOpen) {
      setIsDeleteOpen(true)
    } else {
      if (values.id) {
        await dispatch(deleteCourse(values.id))
        history.push("/create/courses")
      }
    }
  }

  const setArchiveCourse = async (isArchived: boolean) => {
    setSubmitting(true)
    await dispatch(
      saveCourse({
        activeCourse: values,
        isArchived: isArchived,
      })
    )
    setSubmitting(false)
    history.push("/create/courses")
  }

  const hideShare =
    values.shareSettings && values.shareSettings.includes("community")
  const allRoles = session.orgRoles.concat(session.groupRoles).join(" ")
  const isDrafter = allRoles.includes("drafter")

  return (
    <>
      <ModalCopy
        type={"course"}
        open={isCopyOpen}
        path={"/create/courses"}
        isTemplate={values.isTemplate}
        copy={() => {
          dispatch(
            saveCourse({
              activeCourse: values,
              isDraft: true,
              isCopy: true,
              fromTemplate: values.isTemplate,
            })
          )
        }}
        onClose={() => {
          setIsCopyOpen(false)
        }}
      />

      <ModalDelete
        type={"course"}
        open={isDeleteOpen}
        deleteItem={deleteDraft}
        onClose={() => {
          setIsDeleteOpen(false)
        }}
      />

      <CourseCardPreview
        course={values}
        open={previewType === "card"}
        onClose={() => {
          setPreviewType(null)
        }}
      />

      <MobileAppPreviewModal
        course={values}
        open={previewType === "mobile"}
        onClose={() => {
          setPreviewType(null)
        }}
      />

      {previewType === "extension" && (
        <ExtensionPreviewContainer
          onClose={() => setPreviewType(null)}
          course={values}
        />
      )}

      <SecondaryNavbar
        isTemplate={values.isTemplate}
        icon={
          values.isTemplate ? (
            <CommunityIcon width={25} height={25} />
          ) : (
            <LaptopIcon width={25} height={25} />
          )
        }
        title={
          values.isTemplate
            ? "Edit Community Course"
            : values.id
            ? "Edit Course"
            : "New Course"
        }
      >
        <Stack direction="row" spacing={4}>
          {!values.isTemplate && (
            <Button
              size="small"
              startIcon={<PreviewIcon width={20} height={20} />}
              onClick={(event: MouseEvent<HTMLButtonElement>) =>
                setPreviewAnchorEl(event.currentTarget)
              }
            >
              Preview
            </Button>
          )}

          {!values.field_draft &&
            values.id &&
            !values.isTemplate &&
            !values.archived && (
              <Button
                size="small"
                startIcon={<ArchiveIcon width={20} height={20} />}
                onClick={() => {
                  setArchiveCourse(true)
                }}
              >
                Archive
              </Button>
            )}

          {!values.field_draft && values.id && !values.isTemplate && (
            <Button
              size="small"
              startIcon={<CopyIcon width={20} height={20} />}
              onClick={() => setIsCopyOpen(true)}
            >
              Copy
            </Button>
          )}

          {userCanShare &&
            !hideShare &&
            values.id &&
            !values.field_draft &&
            !values.isTemplate && (
              <Button
                size="small"
                startIcon={<ShareIcon width={20} height={20} />}
                onClick={openShareDialog}
              >
                Share
              </Button>
            )}

          {values.field_draft && values.id && (
            <Button
              size="small"
              startIcon={<TrashIcon width={20} height={20} />}
              onClick={deleteDraft}
            >
              Delete
            </Button>
          )}

          {(values.field_draft || !values.id) && (
            <Button
              size="small"
              startIcon={<DraftIcon width={20} height={20} />}
              onClick={saveDraft}
            >
              Save Draft
            </Button>
          )}

          {!values.archived ? (
            <>{!isDrafter && <CourseSubmitButton />}</>
          ) : (
            <Button
              size="small"
              startIcon={<PublishIcon width={20} height={20} />}
              onClick={() => {
                setArchiveCourse(false)
              }}
            >
              Unarchive & Publish
            </Button>
          )}

          <Menu
            id="preview-menu"
            anchorEl={previewAnchorEl}
            open={Boolean(previewAnchorEl)}
            onClose={() => setPreviewAnchorEl(null)}
          >
            <MenuItem
              onClick={() => {
                setPreviewAnchorEl(null)
                setPreviewType("card")
              }}
            >
              Course Card
            </MenuItem>
            <MenuItem
              onClick={() => {
                setPreviewAnchorEl(null)
                setPreviewType("mobile")
              }}
            >
              Mobile App
            </MenuItem>
            <MenuItem
              disabled={values.submissionType === SubmissionType.Quiz}
              onClick={() => {
                setPreviewAnchorEl(null)
                setPreviewType("extension")
              }}
            >
              Challenge Window
            </MenuItem>
            <MenuItem disabled={!canViewInLearn}>
              <a
                style={{ color: "black" }}
                href={process.env.REACT_APP_TEACHER_URL + `/course/${courseId}`}
              >
                View in Learn
              </a>
            </MenuItem>
          </Menu>
        </Stack>
      </SecondaryNavbar>
    </>
  )
}
