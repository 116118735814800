import { useEffect, useState } from "react"
import theme from "../../theme/theme"
import { useDispatch } from "react-redux"

import he from "he"
import classNames from "classnames"
import "./styles/backpack.scss"

import TimeCapsule from "../../components/TimeCapsule"
import Loading from "../../components/Loading"

import moment from "moment"

import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"
// SlideItem type
import { SlideItem } from "./ContentRow"

import { UserEventsLearner } from "../../types/events"
import BackpackProps from "../../types/backpack"
import CertificateMenu from "./certificate/CertificateMenu"

import { Categories } from "../../types/taxonomy/category"
import ButtonSmall from "../ButtonSmall"
import List from "../../icons/List"

interface RowProps {
  data: UserEventsLearner[]
  backpack: BackpackProps
  categories?: Categories
  goTo?: (location: string) => void
  increaseEventsPage: (arg: number) => void
  setEventsCarousel: (arg: number) => void
  // eslint-disable-next-line
  session: any
  userId?: number
  setModalConferenceId?: (id: string) => void
}

const EventsRow = (props: RowProps) => {
  const {
    setEventsCarousel,
    increaseEventsPage,
    goTo,
    userId,
    backpack,
    categories,
    data,
    setModalConferenceId,
  } = props

  const [chunkSize, setChunkSize] = useState(5)
  const dispatch = useDispatch()

  function onResize() {
    if (window.innerWidth < 1600) {
      setChunkSize(3)
    } else {
      setChunkSize(5)
    }
  }

  useEffect(() => {
    onResize()
    window.addEventListener("resize", onResize)
    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [])

  const chunkedData = []

  for (let i = 0; i < data.length; i += chunkSize) {
    const chunk = data.slice(i, i + chunkSize)
    chunkedData.push(chunk)
  }

  function nextSlideChanged(event: SlideItem) {
    dispatch(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setEventsCarousel(event.item > backpack.events.activeIndex ? 1 : -1)
    )
  }

  function nextSlide(event: SlideItem) {
    if (event.itemsInSlide - 2 === event.item) {
      if (backpack.events.currentPage < backpack.events.totalPages - 1) {
        dispatch(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          increaseEventsPage(event.item)
        )
      }
    }
  }

  const items = chunkedData.map((chunk: UserEventsLearner[], index: number) => {
    return (
      <div key={index} className="chunk">
        {chunk.map((event: UserEventsLearner, index: number) => {
          const totalMinutes = Number(event.field_credit.replace("min", ""))
          const hours =
            totalMinutes >= 60
              ? Math.floor(totalMinutes / 60).toLocaleString()
              : 0
          const minutes = totalMinutes % 60

          type styleProps = {
            backgroundColor: string
            backgroundImage?: string
            backgroundSize?: string
          }

          const style: styleProps = {
            backgroundColor: theme.palette.primary.main,
          }

          if (event.field_event_image) {
            style.backgroundImage =
              "url(" +
              process.env.REACT_APP_API_URL +
              event.field_event_image +
              ")"
            style.backgroundSize = "cover"
          }

          const path = event.field_award_credit_for_conferenc
            ? "/events/conference/" + event.id_1
            : "/events/event/" + event.id_1

          return (
            <div key={index} className="item event-item">
              <div
                onClick={() => {
                  if (goTo) {
                    goTo(path)
                  }
                }}
              >
                <div className="event-background" style={style} />
                <div className="capsule-wrapper">
                  {(Number(hours) > 0 || minutes > 0) &&
                    !event.field_award_credit_for_conferenc && (
                      <TimeCapsule hours={Number(hours)} minutes={minutes} />
                    )}
                </div>
                <div style={{ marginTop: 15, textAlign: "center" }}>
                  <span style={{ fontSize: 12, opacity: 0.6 }}>
                    {moment(event.field_event_date_time_value).format(
                      "MMMM Do, YYYY"
                    )}
                  </span>
                  <strong
                    style={{
                      display: "block",
                      fontSize: 14,
                      lineHeight: 1.2,
                    }}
                  >
                    {he.decode(event.name)}
                  </strong>
                </div>
                <footer
                  className="flexRow"
                  style={{ justifyContent: "center", marginTop: 10 }}
                ></footer>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CertificateMenu
                  userId={userId}
                  userData={props.backpack.userData}
                  categories={categories}
                  user={props.session.user}
                  session={props.session}
                  label={""}
                  event={event}
                />
                {event.field_award_credit_for_conferenc && (
                  <ButtonSmall
                    onClick={() => {
                      if (setModalConferenceId) {
                        setModalConferenceId(event.uuid)
                      }
                    }}
                  >
                    <List />
                  </ButtonSmall>
                )}
              </div>
            </div>
          )
        })}
      </div>
    )
  })

  return (
    <div
      className={classNames(
        "inner inner-carousel",
        !backpack.events.activeIndex && "disablePrev",
        chunkedData.length - 1 === backpack.events.activeIndex && "disableNext"
      )}
    >
      {backpack.events.isFetching && <Loading />}

      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <AliceCarousel
          infinite={true}
          activeIndex={backpack.events.activeIndex}
          disableDotsControls={chunkedData.length === 1}
          paddingRight={25}
          paddingLeft={25}
          autoWidth={true}
          items={items}
          onSlideChange={(event) => nextSlide(event)}
          onSlideChanged={(event) => nextSlideChanged(event)}
        />
      }
    </div>
  )
}

export default EventsRow
