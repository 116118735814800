import { createAction } from "@reduxjs/toolkit"
import moment from "moment"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"

export const setUserBadges = createAction(
  "activeUserSlice/setUserBadges",
  (data, fetched) => {
    return {
      payload: data.data,
      meta: {
        included: data.included,
        fetched,
      },
    }
  }
)

export function setProductTourStarted() {
  return async (dispatch, getState) => {
    let body = JSON.stringify({
      data: {
        id: getState().session.user.id,
        type: "user--user",
        attributes: {
          field_hq_product_tour_started: new moment().format(),
        },
      },
    })

    try {
      let response = await fetchWrapper.patch(
        "/api/user/user/" + getState().session.user.id,
        getState().session.token,
        body
      )
      if (response.ok) {
        let data = await response.json()

        dispatch({
          type: "session/setProductTourStarted",
          payload: new moment().format(),
        })

        return data.data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function setHQAnnouncementsFetched() {
  return async (dispatch, getState) => {
    let body = JSON.stringify({
      data: {
        id: getState().session.user.id,
        type: "user--user",
        attributes: {
          field_hq_announcements_last_fetc: new moment().format(),
        },
      },
    })

    try {
      let response = await fetchWrapper.patch(
        "/api/user/user/" + getState().session.user.id,
        getState().session.token,
        body
      )
      if (response.ok) {
        let data = await response.json()

        dispatch({
          type: "session/setHQAnnouncementsFetched",
          payload: new moment().format(),
        })

        return data.data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function setContentLock({ type, id, clear }) {
  return async (dispatch, getState) => {
    const { session } = getState()

    if (id) {
      let body = {
        data: {
          id,
          type,
          attributes: {
            field_editor_lock_time: !clear ? moment().format() : null,
          },
          relationships: {
            field_current_editor: {
              data: !clear
                ? {
                    id: session.user.id,
                    type: "user--user",
                  }
                : null,
            },
          },
        },
      }

      try {
        await fetchWrapper.patch(
          "/api/" + type.replace("--", "/") + "/" + id,
          getState().session.token,
          JSON.stringify(body)
        )
      } catch (err) {
        console.log(err)
      }
    }
  }
}

export function deleteReviewerGroupMembership(id) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: "reviewSlice/deleteReviewerGroupMembership",
        payload: id,
      })

      await fetchWrapper.remove(
        "/api/group_content/rev_group-group_membership/" + id,
        getState().session.token
      )
    } catch (err) {
      console.log(err)
    }
  }
}

export function createReviewerGroupMembership({ user, currentReviewerGroup }) {
  return async (dispatch, getState) => {
    try {
      let body = JSON.stringify({
        data: {
          type: "group_content--rev_group-group_membership",
          attributes: {
            label: user.field_first_name + " " + user.field_last_name,
          },
          relationships: {
            gid: {
              data: {
                type: "group--rev_group",
                id: currentReviewerGroup.id,
              },
            },
            entity_id: {
              data: {
                type: "user--user",
                id: user.uuid,
              },
            },
          },
        },
      })

      let response = await fetchWrapper.post(
        "/api/group_content/rev_group-group_membership",
        getState().session.token,
        body
      )

      if (response.ok) {
        let data = await response.json()

        dispatch({
          type: "reviewSlice/addReviewerGroupMembership",
          payload: data.data,
          meta: {
            user,
          },
        })

        return data.data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function createReviewerGroup(groupName) {
  return async (dispatch, getState) => {
    const { session } = getState()

    let body = JSON.stringify({
      data: {
        type: "group--rev_group",
        attributes: {
          label: groupName,
        },
      },
    })

    try {
      let response = await fetchWrapper.post(
        "/api/group/rev_group",
        session.token,
        body
      )

      if (response.ok) {
        let createdGroup = await response.json()

        body = JSON.stringify({
          data: {
            type: "group_content--organization-subgroup-rev_group",
            attributes: {
              plugin_id: "subgroup:rev_group",
            },
            relationships: {
              gid: {
                data: {
                  type: "group--organization",
                  id: session.group.uuid[0].value,
                },
              },
              entity_id: {
                data: {
                  type: "group--rev_group",
                  id: createdGroup.data.id,
                  meta: {
                    drupal_internal__target_id:
                      createdGroup.data.attributes.drupal_internal__id,
                  },
                },
              },
            },
          },
        })

        let groupResponse = await fetchWrapper.post(
          "/api/group_content/organization-subgroup-rev_group",
          session.token,
          body
        )

        if (groupResponse.ok) {
          dispatch({
            type: "reviewSlice/createReviewerGroup",
            payload: createdGroup.data,
          })

          return createdGroup.data
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function updateReviewerGroupName({ groupName, entity_id }) {
  return async (dispatch, getState) => {
    const { session } = getState()
    let body = JSON.stringify({
      data: {
        id: entity_id,
        type: "group_content--organization-subgroup-rev_group",
        attributes: {
          label: groupName,
        },
      },
    })

    try {
      let response = await fetchWrapper.patch(
        "/api/group/rev_group/" + entity_id,
        session.token,
        body
      )
      if (response.ok) {
        let data = await response.json()

        dispatch({
          type: "reviewSlice/updateReviewerGroupName",
          payload: groupName,
          meta: {
            id: entity_id,
          },
        })

        return data.data
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function saveOrganizationSettings({ activeOrg }) {
  return async (dispatch, getState) => {
    const { session } = getState()

    const orgId = activeOrg.uuid

    const body = {
      data: {
        type: "group--organization",
        attributes: {
          label: activeOrg.label,
          field_status: activeOrg.org_status ? "Active" : "Inactive",
          field_hq: activeOrg.org_hq,
          field_external_pd_access: activeOrg.org_external_pd_access,
          field_org_hide_mm_content: activeOrg.org_hide_mm_content,
          field_max_org_admins: activeOrg.max_total_premium,
          field_max_teacher: activeOrg.max_total_members,
          field_organization_address: {
            address_line1: activeOrg.org_address.address_line1 ?? "",
            address_line2: activeOrg.org_address.address_line2,
            administrative_area:
              activeOrg.org_address.state && activeOrg.org_address.state.length
                ? activeOrg.org_address.state
                : "GA",
            country_code: "US",
            langcode: null,
            locality:
              activeOrg.org_address.city && activeOrg.org_address.city.length
                ? activeOrg.org_address.city
                : "",
            postal_code:
              activeOrg.org_address.zip && activeOrg.org_address.zip.length
                ? activeOrg.org_address.zip
                : "",
          },
        },
      },
    }

    if (activeOrg.customer_rep.uuid) {
      body.data.relationships = {
        field_customer_success_rep: {
          data: {
            type: "user--user",
            id: activeOrg.customer_rep.uuid,
          },
        },
      }
    }
    try {
      if (activeOrg.uuid) {
        body.data.id = orgId
        await fetchWrapper.patch(
          "/api/group/organization/" + orgId,
          session.token,
          JSON.stringify(body)
        )
      } else {
        await fetchWrapper.post(
          "/api/group/organization/",
          session.token,
          JSON.stringify(body)
        )
      }
    } catch (err) {
      console.log(err)
    }
  }
}
