import { useEffect, useState } from "react"
import { useAppSelector } from "store/hooks"

import moment from "moment"
import Tour from "reactour"

import { Button, Dialog } from "@mui/material"
import { setProductTourStarted } from "../../actions"

import { endTour } from "../../store/reducers/productTour"

import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"
import classNames from "classnames"
import ErrorBoundary from "../../components/ErrorBoundary"

import { useAppDispatch } from "../../store/hooks"
import { setCategoryModalOpen } from "../../store/reducers/categories"

function ProductTour() {
  const productTour = useAppSelector((state) => state.productTour)
  const session = useAppSelector((state) => state.session)

  const [isTourOpen, setTourOpen] = useState(false)
  const [isDialogOpen, setDialogOpen] = useState(false)

  const dispatch = useAppDispatch()

  useEffect(() => {
    // If they have never done the product tour
    if (
      !session.user.attributes.field_hq_product_tour_started ||
      moment(session.user.attributes.field_hq_product_tour_started).isBefore(
        moment("02/28/23")
      )
    ) {
      // And we're not currently in it
      if (!productTour.isStarted) {
        // Show the modal
        setDialogOpen(true)
      }
    }
    // If we have started it manually, show the tour
    if (productTour.isStarted) {
      setTourOpen(true)
    }
  }, [
    dispatch,
    productTour.isStarted,
    session.user.attributes.field_hq_product_tour_started,
  ])

  function startTour() {
    setTourOpen(true)
    setDialogOpen(false)
  }

  function closeTour() {
    dispatch(setProductTourStarted())
    dispatch(setCategoryModalOpen(false))
    dispatch(endTour())
    setTourOpen(false)
    setDialogOpen(false)
  }

  let groupLabel: string = ""
  if (session.isSiteAdmin) {
    groupLabel = "MobileMind"
  } else if (session.group) {
    groupLabel = session.group.label[0].value
  } else if (session.user.attributes.field_subgroup) {
    groupLabel = session.user.attributes.field_subgroup
  }

  let totalSteps: number = 0

  if (session.isOrgAdmin || session.isGroupAdmin) {
    totalSteps = 10
    if (session.isOrgAdmin) {
      totalSteps = 11
    }
  } else {
    totalSteps = 5

    if (session.canReport) {
      totalSteps++
    }
    if (session.canCreate) {
      totalSteps++
    }
    if (session.canSchedule) {
      totalSteps++
    }
  }

  function TourFooter(props: any) {
    return (
      <footer className="flexEnd">
        <Button
          onClick={() => props.goTo(props.step - 2)}
          className={classNames("button small", props.step === 1 && "disabled")}
        >
          Back
        </Button>
        <div className="step">
          {props.step} / {totalSteps}
        </div>
        {props.isFinished ? (
          <Button onClick={props.close} className="button small">
            Finish
          </Button>
        ) : (
          <Button
            onClick={() => {
              if (props.step === 5 && session.isOrgAdmin) {
                dispatch(setCategoryModalOpen(true))
              } else {
                dispatch(setCategoryModalOpen(false))
              }
              props.goTo(props.step)
            }}
            className="button small"
          >
            Next
          </Button>
        )}
      </footer>
    )
  }

  const tourSteps = []
  if (session.canViewBackpack) {
    tourSteps.push({
      selector: "#learnerSearch",
      content: (props: any) => (
        <div>
          To look up a specific learner and view all of their PD achievements,
          the <strong>Learner Search</strong> allows you to search for someone
          and view their <strong>Backpack.</strong>
          <TourFooter {...props} />
        </div>
      ),
      style: { marginLeft: 10 },
    })

    tourSteps.push({
      selector: "#quickActions",
      content: (props: any) => (
        <div>
          You can use <strong>Quick Actions</strong> to pin frequently used
          areas of the app to your dashboard. <br />
          Give it a try!
          <TourFooter {...props} />
        </div>
      ),
      style: { marginLeft: 10 },
    })
    tourSteps.push({
      selector: "#tour-widgets",
      content: (props: any) => (
        <div>
          Your <strong>Dashboard Widgets</strong> help you see pending action
          items, tips, and more in a convenient layout.
          <TourFooter {...props} />
        </div>
      ),
      style: { marginLeft: 10 },
    })
  }

  if (session.canReport) {
    tourSteps.push({
      selector: "#tour-reports",
      content: (props: any) => (
        <div>
          The <strong>Reports</strong> tab lets you check up on{" "}
          <strong>Leaderboards</strong>, <strong>Progress Tracking</strong>, and{" "}
          <strong>Access</strong> data across all learners inside {groupLabel}.
          <TourFooter {...props} />
        </div>
      ),
      style: { left: -10 },
    })
  }

  if (session.canCreate) {
    tourSteps.push({
      selector: "#tour-content",
      content: (props: any) => (
        <div>
          Use the <strong>Content</strong> tab to create and manage {groupLabel}
          's{" "}
          <strong>
            Courses, Badges, Learning Paths, Goals, and Announcements
          </strong>
          .<br />
          <br />
          {session.canReview && (
            <>
              {" "}
              You can also <strong>Review Challenges</strong> here
            </>
          )}
          {session.isOrgAdmin ? (
            <>
              , as well as manage your <strong>PD Categories</strong>.
            </>
          ) : (
            <>.</>
          )}
          <TourFooter {...props} />
        </div>
      ),
      style: { left: -10 },
    })
  }

  if (session.isOrgAdmin) {
    tourSteps.push({
      selector: ".modal.manageCategories",
      content: (props: any) => (
        <div>
          Access your <strong>PD Categories</strong> from the Content Menu,
          Quick Actions, or while creating a course, event, or conference.
          <br />
          <br />
          You can organize up to 3 nested levels of categories, control their
          visibility, and select a category images and colors.
          <TourFooter {...props} />
        </div>
      ),
      style: { left: -10 },
    })
  }
  if (session.canSchedule) {
    tourSteps.push({
      selector: "#tour-events",
      content: (props: any) => (
        <div>
          With <strong>MobileMind Events</strong> you can schedule and manage
          in-person <strong>Events</strong>, <strong>Conferences</strong>,{" "}
          <strong>Virtual Training</strong>, and <strong>External PD</strong>.
          <br />
          <br />
          You can also manage {groupLabel} <strong>Locations</strong> for use
          with all PD events.
          <TourFooter {...props} />
        </div>
      ),
      style: { left: -10 },
    })
  }
  if (session.isOrgAdmin) {
    tourSteps.push({
      selector: "#tour-account",
      content: (props: any) => (
        <div>
          Use the <strong>Account</strong> tab to manage {groupLabel} users, as
          well as access <strong>MobileMind Support</strong>.
          <TourFooter {...props} />
        </div>
      ),
      style: { left: -10 },
    })
  }

  let extraSteps = [
    {
      selector: ".navbar .profilePicture",
      content: (props: any) => (
        <div>
          Use the <strong>Profile Menu</strong> to update your personal
          information and picture.
          <TourFooter {...props} />
        </div>
      ),
      style: { marginLeft: -10 },
    },
    {
      selector: "#tour-learner",
      content: (props: any) => (
        <div>
          Visit the <strong>Learn</strong> app and see what {groupLabel}{" "}
          learners see when they login.
          <TourFooter {...props} />
        </div>
      ),
      style: { margin: 10 },
    },
    {
      selector: "#tour-mm-logo",
      content: (props: any) => {
        props.isFinished = true
        return (
          <div>
            You can click the <strong>MobileMind HQ</strong> logo at any time to
            go back to this page.
            <TourFooter {...props} />
          </div>
        )
      },
      style: { marginLeft: 10 },
    },
  ]

  tourSteps.push(...extraSteps)

  const newFeatures = session.user.attributes.field_hq_product_tour_started

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <ErrorBoundary>
      <Dialog open={isDialogOpen} className="product-tour-modal">
        <div className="dialog">
          {newFeatures ? (
            <h2>We've been busy!</h2>
          ) : (
            <h2>Let's show you around.</h2>
          )}
          <div className="flexRow">
            <div className="thumbnail">
              {newFeatures ? (
                <div className="icon new-features" />
              ) : (
                <div className="icon map" />
              )}
            </div>

            {!newFeatures ? (
              <p>
                To help you get the most out of <strong>MobileMind HQ</strong>,
                we’ve put together a short tour of the app.
                <br />
                <br />
                If you want to skip the tour, you can start it later at any time
                from the Profile menu.
              </p>
            ) : (
              <p>
                We've made some awesome new changes around here. Take a look at
                what's new in <strong>MobileMind HQ.</strong>
              </p>
            )}
          </div>
          <footer>
            <Button onClick={closeTour} className="button secondary large">
              Skip for Now
            </Button>
            <ButtonLarge onClick={startTour}>Start Tour</ButtonLarge>
          </footer>
        </div>
      </Dialog>
      <Tour
        steps={tourSteps}
        isOpen={isTourOpen}
        startAt={0}
        showNavigationNumber={true}
        showNavigation={false}
        showNumber={false}
        maskClassName="tour-mask"
        showButtons={false}
        prevButton={<Button className="button small">Back</Button>}
        nextButton={<Button className="button small">Next</Button>}
        maskSpace={0}
        onRequestClose={closeTour}
      />
    </ErrorBoundary>
  )
}

export default ProductTour
