import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"
import debounceThunk from "@mobilemind/common/src/functions/debounceThunk"

export const fetchActiveLearningPath = createAsyncThunk(
  "activeLearningPathSlice/fetchActiveLearningPath",
  async (learningPathId, thunkAPI) => {
    let query = {
      filter: {
        drupal_internal__id: Number(learningPathId),
      },
      include:
        "field_image,field_prerequisite_learning_path,field_type,field_courses,field_subgroup,field_job_title,field_teacher",
    }
    let response = await fetchWrapper.get(
      "/api/learning_path/learning_path?" + qs.stringify(query)
    )
    let data = await response.json()

    if (data.data[0]) {
      let activePath = data.data[0]
      activePath.courses =
        activePath &&
        data.included.filter(
          (included) => included.type === "course_entity--course_entity"
        )
      activePath.image =
        activePath &&
        data.included.filter((included) => included.type === "file--image")
      activePath.jobTitles =
        activePath &&
        data.included.filter(
          (included) => included.type === "taxonomy_term--job_titles"
        )
      activePath.users =
        activePath &&
        data.included.filter((included) => included.type === "user--user")

      let subGroups =
        data.included &&
        data.included.filter((included) => included.type === "group--group")

      let preReqLP =
        data.included &&
        data.included.filter(
          (included) => included.type === "learning_path--learning_path"
        )

      let subGroupIds =
        activePath.relationships.field_subgroup.data &&
        activePath.relationships.field_subgroup.data.map((group) => group.id)

      if (!subGroups || !subGroups.length) {
        let groupLevelSubGroups = thunkAPI.getState().session.subgroups
        activePath.subGroups =
          groupLevelSubGroups &&
          groupLevelSubGroups.data &&
          groupLevelSubGroups.data.filter((group) =>
            subGroupIds.includes(group.id)
          )
      } else {
        activePath.subGroups = subGroups
      }
      activePath.preReqLP = preReqLP
      return activePath
    } else {
      return false
    }
  }
)

const getLearningPaths = createAsyncThunk(
  "activeLearningPathSlice/getLearningPaths",
  async (search) => {
    let response = await fetchWrapper.get(
      "/api/learning_path/explore?search=" + search
    )
    if (response.ok) {
      let data = await response.json()
      return data
    }
  }
)

const debouncedGetLearningPaths = debounceThunk(getLearningPaths, 750)
export const updateLPSearch = createAsyncThunk(
  "activeLearningPathSlice/updateLPSearch",
  async (search, thunkAPI) => {
    thunkAPI.dispatch(debouncedGetLearningPaths(search))
    return search
  }
)

export const activeLearningPathSlice = createSlice({
  name: "activeLearningPathSlice",
  initialState: {
    prerequisitePath: { attributes: { name: "None" } },
    unauthorized: false,
    fetched: false,
    name: "",
    courses: [],
    image: null,
    archived: false,
    videoLink: "",
    subGroups: [],
    jobTitles: [],
    users: [],
    estimatedTime: 0,
    body: "",
    originalCourses: [],
    drupal_internal__id: null,
    subGroupOwner: null,
    missingFields: [],
    relatedLPSFetched: false,
    relatedLPSearch: "",
    relatedLPResults: [],
    lastCourseLocked: false,
    certificateSettings: {
      signature: true,
      orgLogo: true,
      pdCredit: true,
      categories: true,
    },
  },
  reducers: {
    startNewLearningPath: (state) => {
      state.id = null
      state.name = ""
      state.prerequisitePath = { attributes: { name: "None" } }
      state.image = null
      state.archived = false
      state.type = null
      state.body = ""
      state.videoLink = ""
      state.field_draft = true
      state.courses = []
      state.estimatedTime = 0
      state.subGroups = []
      state.fetched = true
      state.unauthorized = false
      state.jobTitles = []
      state.users = []
      state.originalCourses = []
      state.drupal_internal__id = null
      state.subGroupOwner = null
      state.missingFields = []
      state.relatedLPSFetched = false
      state.relatedLPSearch = ""
      state.relatedLPResults = []
      state.lastCourseLocked = false
      state.certificateSettings = {
        signature: true,
        orgLogo: true,
        pdCredit: true,
        categories: true,
      }
    },
    updateCertificateSettings: (state, action) => {
      const { field, value } = action.payload
      state.certificateSettings[field] = value
    },
    updateField: (state, action) => {
      // Prevent duplicates due to AutoComplete weirdness
      if (action.payload.field === "jobTitles") {
        state[action.payload.field] = action.payload.value.filter(
          (v, i, a) => a.findIndex((t) => t.id === v.id) === i
        )
      } else if (action.payload.field === "subGroups") {
        state[action.payload.field] = action.payload.value.filter(
          (v, i, a) =>
            a.findIndex((t) => t.attributes.label === v.attributes.label) === i
        )
      } else {
        state[action.payload.field] = action.payload.value
      }
    },
    setFilename: (state, action) => {
      state.filename = action.payload
    },
    setImage: (state, action) => {
      state.image = action.payload
    },
    removeImage: (state) => {
      state.image = null
    },
    dropCourse: (state, action) => {
      let prevIndex = action.payload.source.index
      let targetIndex = action.payload.destination.index

      if (targetIndex >= state.courses.length) {
        let course = targetIndex - state.courses.length + 1
        while (course--) {
          state.courses.push(undefined)
        }
      }

      state.courses.splice(
        targetIndex,
        0,
        state.courses.splice(prevIndex, 1)[0]
      )
    },
    addCourse: (state, action) => {
      state.courses.push(action.payload)
      state.estimatedTime = state.courses
        .map((course) => course.attributes.field_course_estimated_time)
        .reduce((a, b) => a + b, 0)
    },
    removeCourse: (state, action) => {
      state.courses = state.courses.filter(
        (course) => course.id !== action.payload.id
      )
      state.estimatedTime = state.courses
        .map((course) => course.attributes.field_course_estimated_time)
        .reduce((a, b) => a + b, 0)
    },
  },
  extraReducers: {
    [getLearningPaths.pending]: (state, action) => {
      state.relatedLPSFetched = false
      state.relatedLPSearch = action.meta.arg
      state.relatedLPResults = []
    },
    [getLearningPaths.fulfilled]: (state, action) => {
      state.relatedLPSFetched = true
      state.relatedLPResults = action.payload.lp_data
    },

    [fetchActiveLearningPath.pending]: (state, action) => {
      state.fetched = false
    },
    [fetchActiveLearningPath.fulfilled]: (state, action) => {
      if (action.payload) {
        state.unauthorized = false
        state.id = action.payload.id
        state.drupal_internal__id =
          action.payload.attributes.drupal_internal__id

        state.lastCourseLocked =
          action.payload.attributes.field_sequence === "final_course"
            ? true
            : false

        state.certificateSettings = {
          pdCredit:
            action.payload.attributes.field_enabled_certificate_values.includes(
              "pd_credit"
            ),
          signature:
            action.payload.attributes.field_enabled_certificate_values.includes(
              "signature"
            ),
          orgLogo:
            action.payload.attributes.field_enabled_certificate_values.includes(
              "org_logo"
            ),
          categories:
            action.payload.attributes.field_enabled_certificate_values.includes(
              "categories"
            ),
        }

        state.subGroupOwner =
          action.payload.relationships.field_subgroup_owner.data &&
          action.payload.relationships.field_subgroup_owner.data.id

        state.name = action.payload.attributes.name
        state.image = action.payload.image && action.payload.image[0]
        state.archived = action.payload.attributes.field_learning_path_archive
        state.type = action.payload.relationships.field_type.data.id
        state.body = action.payload.attributes.field_description
        state.videoLink = action.payload.attributes.field_learning_path_video

        if (action.payload.preReqLP && action.payload.preReqLP[0]) {
          state.prerequisitePath = {
            name: action.payload.preReqLP[0].attributes.name,
            field_lp_uuid: action.payload.preReqLP[0].id,
            field_lp_id:
              action.payload.preReqLP[0].attributes.drupal_internal__id,
          }
        }

        state.courses = action.payload.courses
        state.subGroups = action.payload.subGroups
          ? action.payload.subGroups
          : []
        state.jobTitles = action.payload.jobTitles
          ? action.payload.jobTitles
          : []

        state.originalCourses = state.courses

        state.estimatedTime = action.payload.courses
          .map((course) => course.attributes.field_course_estimated_time)
          .reduce((a, b) => a + b, 0)
        state.field_draft = action.payload.attributes.field_draft
        state.users = action.payload.users ? action.payload.users : []
        state.fetched = true
      } else {
        state.unauthorized = true
      }
    },
  },
})

export const {
  startNewLearningPath,
  updateCertificateSettings,
  updateField,
  removeImage,
  setImage,
  setFilename,
  addCourse,
  dropCourse,
  removeCourse,
} = activeLearningPathSlice.actions

export default activeLearningPathSlice.reducer
